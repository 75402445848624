<div class="encyclopedia-view bg-universe">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <div class="layout-bloc full-width is-warning margin-top-1">
      L'encyclopédie est en cours de développement,
      certaines sections peuvent être manquantes ou n'ont pas encore été relues.
    </div>

    <div class="encyclopedia-layout margin-top-1">
      <div class="encyclopedia-left">
        <tree-menu [hasSearch]="true" [currentCode]="currentCode" [menu]="encycloMenu"
          (outChangeCode)="moveToSection($event)">
        </tree-menu>
      </div>
      <div class="encyclopedia-right">
        <head-section [previousSection]="previousSection" [currentSection]="currentSection" [nextSection]="nextSection"
          (outChange)="moveToSection($event)">
        </head-section>

        <encyclo-divine-view *ngIf="currentCode === 'EncycloDivineView'"></encyclo-divine-view>
        <encyclo-societe-eveil-view *ngIf="currentCode === 'EncycloSocieteEveilView'"></encyclo-societe-eveil-view>
        <encyclo-coalition-view *ngIf="currentCode === 'EncycloCoalitionView'"></encyclo-coalition-view>
        <encyclo-journaux-view *ngIf="currentCode === 'EncycloJournauxView'"></encyclo-journaux-view>

        <encyclo-factions-view *ngIf="currentCode === 'EncycloFactionsOffView'" [isOff]="true"></encyclo-factions-view>
        <encyclo-factions-view *ngIf="currentCode === 'EncycloFactionsSecView'" [isOff]="false"></encyclo-factions-view>

        <encyclo-faradel-land-view *ngIf="currentCode === 'EncycloFaradelLandView'"></encyclo-faradel-land-view>
        <encyclo-jirakan-land-view *ngIf="currentCode === 'EncycloJirakanLandView'"></encyclo-jirakan-land-view>
        <encyclo-dakaros-land-view *ngIf="currentCode === 'EncycloDakarosLandView'"></encyclo-dakaros-land-view>
        <encyclo-ether-land-view *ngIf="currentCode === 'EncycloEtherLandView'"></encyclo-ether-land-view>
        <encyclo-atlantica-land-view *ngIf="currentCode === 'EncycloAtlanticaLandView'"></encyclo-atlantica-land-view>
        <encyclo-somni-land-view *ngIf="currentCode === 'EncycloSomniLandView'"></encyclo-somni-land-view>
        <encyclo-no-land-view *ngIf="currentCode === 'EncycloNoLandView'"></encyclo-no-land-view>

        <ng-container *ngFor="let bestiary of allBestiaries; let i = index;">
          <encyclo-bestiary-view *ngIf="currentCode === 'EncycloBestiary' + i + 'View'" [indexBestiary]="i"
            [allCreatures]="creatures">
          </encyclo-bestiary-view>
        </ng-container>

        <footer-section [previousSection]="previousSection" [nextSection]="nextSection"
          (outChange)="moveToSection($event)">
        </footer-section>
      </div>
    </div>
  </div>
</div>