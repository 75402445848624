<div class="roleplay-view">
  <div class="roleplay-section bg-roleplay bg-block glyphe-hover">
    <div class="title-container">
      <h2>1. La Carte du Monde,<br />et ses habitants</h2>
      <p>
        Avant de créer son personnage, ou même de lire les règles du jeu de rôles...
        découvrez l'univers du Monde d'Arlénor : la religion dominante, la magie omni-présente, les différents peuples
        y vivant et les conflits y règnant...
      </p>
      <div>
        <arrow-button linkName="Voir le Monde" linkPage="/univers"></arrow-button>
      </div>
    </div>
    <div class="glyphe-container">
      <glyphe icon="map"></glyphe>
    </div>
  </div>
  <div little-separator></div>
  <div class="roleplay-section bg-roleplay-reverse bg-block bg-black inversed-section glyphe-hover">
    <div class="glyphe-container">
      <glyphe class="little" icon="book"></glyphe>
    </div>
    <div class="title-container">
      <h2>2. Les règles du jeu</h2>
      <p>
        Trouvez ici tous les éléments permettant de comprendre la création de personnage en profondeur ainsi que le
        système de jeu et des propositions de pouvoirs.
      </p>
      <div>
        <arrow-button linkName="Comprendre le système" linkPage="/jeu-de-roles/systeme">
        </arrow-button>
      </div>
      <div class="margin-top-1">
        <arrow-button linkName="Voir les cristaux évolutifs" linkPage="/jeu-de-roles/cristaux">
        </arrow-button>
      </div>
    </div>
  </div>
  <div little-separator></div>
  <div class="roleplay-section bg-roleplay bg-block glyphe-hover">
    <div class="title-container">
      <h2>3. La création de personnage</h2>
      <p>
        Créez votre personnage, des caractéristiques aux compétences, en passant par leurs pouvoirs...
        Accédez dès maintenant à la naissance de votre personnage !
      </p>
      <div>
        <arrow-button linkName="Passer à la création !" linkPage="/jeu-de-roles/personnage">
        </arrow-button>
      </div>
      <div class="margin-top-1">
        <button type="button" mat-stroked-button color="primary" (click)="download(false)">
          Télécharger la fiche vierge (imprimable)
        </button>
      </div>
      <div class="margin-top-1">
        <button type="button" mat-stroked-button color="primary" (click)="download(true)">
          Télécharger la fiche vierge (colorée)
        </button>
      </div>
    </div>
    <div class="glyphe-container">
      <glyphe icon="perso"></glyphe>
    </div>
  </div>
  <div little-separator></div>
  <div class="roleplay-section bg-roleplay-reverse bg-block bg-black inversed-section">
    <div class="glyphe-container">
      <div class="glyphe-view">
        <img class="glyphe-logo" src="assets/images_alpha/fvtt.png" alt="Logo Foundry VTT">
      </div>
    </div>
    <div class="title-container">
      <h2>Foundry Virtual Tabletop</h2>
      <p>
        Trouvez ici le système de jeu du Monde d'Arlénor, pour y jouer sur Foundry VTT.
        <!--br><span class="text-red">(Le système n'est actuellement pas à jour)</span-->
      </p>
      <div>
        <arrow-button linkName="Aller sur Foundry VTT" linkUrl="https://foundryvtt.com/packages/arlenor">
        </arrow-button>
      </div>
      <div *ngIf="isAdmin" class="margin-top-1">
        <button type="button" mat-stroked-button color="primary" (click)="downloadFoundryDB()">
          Télécharger les données de FoundryVTT
        </button>
      </div>
    </div>
  </div>
</div>