<div class="encyclo-no-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Forêt d'Imerys">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
    <mat-tab label="L'Ile de Lumeck">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
  </mat-tab-group>
</div>