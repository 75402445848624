<creation-form formTitle="Choix divins" [isModified]="isModified" [isInvalid]="!form.valid" (outCancel)="cancelForm()"
  (outSubmit)="submitForm()">
  <div class="creation-column">
    <img class="creation-img-perso layout-bloc" src="assets/images_alpha/religion/divinities.png"
      alt="Illustration des symboles divins">
    <form [formGroup]="form" class="semi-width margin-top-1">
      <div class="layout-bloc is-warning text-left">
        Cette partie ne concernent QUE les personnages de la campagne de JDR / l'Aventure
        qui savent comment remplir cette section (pour le bien être scénaristique encore).
      </div>
      <mat-form-field class="margin-top-1">
        <mat-label>Divinité</mat-label>
        <mat-select formControlName="codeDivinity">
          <mat-option *ngFor="let divinity of allDivinities" [value]="divinity.code">
            {{ divinity.libNum }}{{ divinity.isInversed ? "i" : "" }} - {{ divinity.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="currentDivinity" class="layout-bloc margin-top-1">
        <img class="creation-img-divinity" [src]="currentDivinity.image" [alt]="currentDivinity.name">
        <h3 class="margin-top-1">
          {{ currentDivinity.libNum }}<span class="text-lower">{{ currentDivinity.isInversed ? "i" : "" }}</span>
          - {{ currentDivinity.name }}, {{ currentDivinity.title }}
        </h3>
        <div class="margin-top-1">
          Pouvoir divin : <b>{{ currentDivinity.powerName }}</b><br>
          <i>{{ currentDivinity.power }}</i>
        </div>
      </div>
      <div *ngIf="!currentDivinity" class="layout-bloc margin-top-1">
        Aucune divinité sélectionnée.
      </div>
      <div class="layout-row layout-col-mob margin-top-1">
        <mat-form-field>
          <mat-label>Palier divin</mat-label>
          <mat-select formControlName="divinityPoints">
            <mat-option *ngFor="let point of points" [value]="point" [disabled]="!currentDivinity">
              {{ getPalier(point) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Morts subies</mat-label>
          <mat-select formControlName="deathPoints">
            <mat-option *ngFor="let point of points" [value]="point" [disabled]="!currentDivinity">
              {{ point }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</creation-form>