<div *ngIf="!isAdmin && !isMaster" class="layout-bloc is-info margin-top-1">
    Données réservées aux Maîtres du Jeu.
</div>

<ng-container *ngIf="isAdmin || isMaster">
    <div class="margin-top-1">
        <divinities-table></divinities-table>
    </div>

    <div class="layout-bloc margin-top-1" [innerHtml]="encycloDivineText"></div>
</ng-container>