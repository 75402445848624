import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CaractModal } from '../../../../components/modals/caract/caract.modal';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { CaractDescriptionEnum, CaractNomEnum, DifficultyEnum } from '../../../../models/ModelEnum';
import { StoreService } from '../../../../services/store.service';

interface CaractsElement {
  libelle: string;
  code: string;
  key: string;
  isNullable: boolean;
}

@Component({
  selector: 'creature-caracts-view',
  templateUrl: './creature-caracts.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureCaractsView implements OnInit {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public data: CaractsElement[] = [
    {
      libelle: CaractNomEnum.Force.Libelle,
      code: CaractNomEnum.Force.Code,
      key: CaractNomEnum.Force.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Habilete.Libelle,
      code: CaractNomEnum.Habilete.Code,
      key: CaractNomEnum.Habilete.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Intellect.Libelle,
      code: CaractNomEnum.Intellect.Code,
      key: CaractNomEnum.Intellect.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Tenacite.Libelle,
      code: CaractNomEnum.Tenacite.Code,
      key: CaractNomEnum.Tenacite.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Charisme.Libelle,
      code: CaractNomEnum.Charisme.Code,
      key: CaractNomEnum.Charisme.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Magie.Libelle,
      code: CaractNomEnum.Magie.Code,
      key: CaractNomEnum.Magie.Code.toLowerCase(),
      isNullable: true,
    },
  ];
  public dataSource = new MatTableDataSource<CaractsElement>(this.data);

  public caractDescriptionEnum = CaractDescriptionEnum;
  public totalCaracts: number;
  public maxCaract: number;
  public difficultyColor: string;
  public difficultyLibelle: string;
  public isModified: boolean;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      for: new FormControl(1),
      hab: new FormControl(1),
      int: new FormControl(1),
      cha: new FormControl(1),
      ten: new FormControl(1),
      mag: new FormControl(0)
    });
  }

  public ngOnInit() {
    this.maxCaract = 5;

    // Init
    this.form.controls.for.setValue(this._storeService.creature.caractFor);
    this.form.controls.hab.setValue(this._storeService.creature.caractHab);
    this.form.controls.int.setValue(this._storeService.creature.caractInt);
    this.form.controls.cha.setValue(this._storeService.creature.caractCha);
    this.form.controls.ten.setValue(this._storeService.creature.caractTen);
    this.form.controls.mag.setValue(this._storeService.creature.caractMag);
    this._setDifficulty();

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
        this._setDifficulty();
      });
  }

  public getInitiative() {
    return parseInt(this.form.controls.hab.value) + parseInt(this.form.controls.int.value);
  }

  public getPointsDeVie() {
    let points = 4;
    if (parseInt(this.form.controls.ten.value) === 1) points--;
    else if (parseInt(this.form.controls.ten.value) > 3) points++;
    return points;
  }

  public openCaractPopup(caract: string) {
    const dialogRef = this.dialog.open(CaractModal, {
      data: caract,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit();
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.caractFor = parseInt(this.form.controls.for.value);
    newCreature.caractHab = parseInt(this.form.controls.hab.value);
    newCreature.caractInt = parseInt(this.form.controls.int.value);
    newCreature.caractTen = parseInt(this.form.controls.ten.value);
    newCreature.caractCha = parseInt(this.form.controls.cha.value);
    newCreature.caractMag = parseInt(this.form.controls.mag.value);
    this._storeService.changeCreatureCaracts(newCreature);
  }

  private _setDifficulty() {
    const stats = this.form.controls.for.value
      + this.form.controls.hab.value
      + this.form.controls.int.value
      + this.form.controls.cha.value
      + this.form.controls.ten.value
      + this.form.controls.mag.value;

    if (Math.round(stats / 6) >= 5) this.difficultyColor = 'status-violet';
    else if (Math.round(stats / 6) >= 4) this.difficultyColor = 'status-red';
    else if (Math.round(stats / 6) >= 3) this.difficultyColor = 'status-yellow';
    else if (Math.round(stats / 6) >= 2) this.difficultyColor = 'status-green';
    else this.difficultyColor = 'status-ocean';

    if (Math.round(stats / 6) >= 5) this.difficultyLibelle = DifficultyEnum.Legendaire.Libelle;
    else if (Math.round(stats / 6) >= 4) this.difficultyLibelle = DifficultyEnum.Epique.Libelle;
    else if (Math.round(stats / 6) >= 3) this.difficultyLibelle = DifficultyEnum.Complexe.Libelle;
    else if (Math.round(stats / 6) >= 2) this.difficultyLibelle = DifficultyEnum.Standard.Libelle;
    else this.difficultyLibelle = DifficultyEnum.Simple.Libelle;
  }
}
