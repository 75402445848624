<creation-form formTitle="Choix des compétences" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <div class="creation-tables">
      <skills-selection-table [level]="character.level" [idsSkills]="idsSkills" [skills]="allSkills"
        (outAdd)="addSkill($event)" (outRemove)="removeSkill($event)"></skills-selection-table>
    </div>

    <div class="layout-row layout-col-mob margin-top-1">
      <div *ngIf="idsSkills.length !== this.character.level.maxSkills" class="layout-bloc semi-width is-warning">
        Compétences restantes à sélectionner :
        {{ character.level.maxSkills - idsSkills.length }}
      </div>
      <div *ngIf="idsSkills.length === this.character.level.maxSkills" class="layout-bloc semi-width">
        Toutes les compétences ont été sélectionnées.
      </div>
    </div>
  </div>
</creation-form>