import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { InformationModal } from '../../../../components/modals/information/information.modal';
import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorPower } from '../../../../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../../../../models/arlenor/ArlenorSkill';
import { StoreService } from '../../../../services/store.service';
import downloads_characters from '../../../../utils/downloads_characters';
import random from '../../../../utils/random';
import supabase_api from '../../../../utils/supabase_api';

@Component({
  selector: 'character-final-view',
  templateUrl: './character-final.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterFinalView implements OnInit {

  @Output() public outPrevious = new EventEmitter();

  public character: ArlenorCharacter;
  public isSaved = false;
  public isSavedBDD = false;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.character = this._storeService.character;
  }

  public getDetails() {
    let libelle = this.character.race?.name;
    libelle += ' - Niv ' + this.character.numLevel;
    if (this.character.speciality01) libelle += ' - ' + this.character.speciality01.name;
    if (this.character.speciality02) libelle += '/' + this.character.speciality02.name;
    return libelle;
  }

  public reset() {
    this._storeService.resetCharacter();
    this.outPrevious.emit(false);
  }

  public restart() {
    this._storeService.restartCharacter();
    this.outPrevious.emit(true);
  }

  public async download(isColored: boolean) {
    downloads_characters.downloadPDF(isColored, this.character, this.allSkills, this.allPowers);
  }

  public openSavePopup() {
    const character = this.character;
    character.initTime();
    character.isBDD = false;
    this._storeService.saveLocalCharacter(character);
    this.isSaved = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde du personnage`,
        content: `Votre personnage
        <b>` + character.name + ` - Niv ` + character.numLevel + `</b>
        est désormais sauvegardé sur votre navigateur.
        <br><br>
        Note : En effaçant l'historique de votre navigateur, vos personnages sauvegardés seront effacés.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public async openSavePopupBDD() {
    const character = this.character;
    character.initTime();
    character.isBDD = true;
    character.guid = random.generateID(20);
    await supabase_api.postCharacter(character);
    this.isSavedBDD = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde du personnage`,
        content: `Votre personnage
        <b>` + character.name + ` - Niv ` + character.numLevel + `</b>
        est désormais sauvegardé en base de données.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }
}
