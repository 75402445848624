<h3>Compétence : {{ skill.name }}</h3>
<mat-dialog-content class="mat-typography">
  <p>
    <i>
      <span title="Type de compétence">{{ skill.type.Libelle }}</span>
      &nbsp;•&nbsp;
      <i class="icon-dice" title="Caractéristiques concernées"></i>&nbsp;
      <span title="Caractéristiques concernées">{{ skill.nameCaracts }}</span>
    </i>
    <br>
    {{ skill.description ? skill.description : "Aucune description disponible" }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>