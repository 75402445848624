<table mat-table [dataSource]="dataSource" *ngFor="let dataSource of dataSources; let isFirst = first;"
  [class.margin-top-1]="!isFirst">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="col-50"></th>
    <td mat-cell *matCellDef="let element" class="col-50">
      <b>{{ element.libNum }}{{ element.isInversed ? "i" : "" }} - {{ element.name }},
        {{ element.title }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="team">
    <th mat-header-cell *matHeaderCellDef class="col-20">Équipe</th>
    <td mat-cell *matCellDef="let element" class="col-20">
      <span *ngIf="element.isAlly === true" class="text-green">Allié</span>
      <span *ngIf="element.isAlly === false" class="text-red">Ennemi</span>
      <span *ngIf="element.isAlly === null">-</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="situation">
    <th mat-header-cell *matHeaderCellDef class="col-30">Situation</th>
    <td mat-cell *matCellDef="let element" class="col-30">
      {{ element.situation }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef=" let row; columns: displayedColumns;"></tr>
</table>