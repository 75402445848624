import jsPDF from 'jspdf';

import { ArlenorCharacter } from '../models/arlenor/ArlenorCharacter';
import { ArlenorPower, PowerRanksEnum } from '../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../models/arlenor/ArlenorSkill';
import { ArlenorSpecialities } from '../models/data/ListSpecialities';

const fontSize = 10; // en px
const fontSizeMin = 8; // en px

// Le PDF a4: [595.28, 841.89] en pt [446.46, 631.4175] en px
// L'image est en [1400, 1980] en px
const conv = (val: number) => {
  return val/(1400/446.46);
};

const convFont = (val: number, isMin = false) => {
  return (val+(isMin ? fontSizeMin : fontSize))/(1400/446.46);
};

const downloadPDF = async(isColored: boolean, character: ArlenorCharacter, allSkills: ArlenorSkill[], allPowers: ArlenorPower[]): Promise<void> => {
  const doc = new jsPDF('p', 'px', [conv(1400), conv(1980)], true);
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const backgroundRecto = (isColored) ? 'assets/files/FichePerso_Recto_Colo.jpeg' : 'assets/files/FichePerso_Recto.jpeg';
  doc.addImage(backgroundRecto, 'JPEG', 0, 0, width, height);
  doc.setFontSize(fontSize);
  if (isColored) doc.setTextColor(255, 255, 255);

  // --- AVATAR ET IDENTITE
  if (character.avatar) doc.addImage(character.profil, 'JPEG', conv(35), conv(35), conv(310), conv(310));
  const specialities = ArlenorSpecialities.getListSpecialities();
  doc.text('' + character.name, conv(700), convFont(255), { align: 'center' });
  doc.text('' + character.numLevel, conv(605), convFont(330), { align: 'center' });
  let libSpe = '';
  if (character.codeSpeciality01) {
    const spe = specialities.find(spe => spe.code === character.codeSpeciality01);
    if (spe) libSpe += spe.name;
    doc.addImage(spe.getImageForArchetype(!isColored), 'JPEG', conv(1050), conv(30), conv(160), conv(160));
  }
  if (character.codeSpeciality02) {
    const spe = specialities.find(spe => spe.code === character.codeSpeciality02);
    if (spe) libSpe += ' / ' + spe.name;
    doc.addImage(spe.getImageForArchetype(!isColored), 'JPEG', conv(1210), conv(30), conv(160), conv(160));
  }
  doc.text('' + libSpe, conv(980), convFont(330), { align: 'center' });

  // --- CARACTERISTIQUES ET VALEURS DERIVES
  const lineH = 75;
  let x = 355;
  let y = 480;
  doc.text('' + character.caractFor, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.caractHab, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.caractInt, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.caractTen, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.caractCha, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.caractMag, conv(x), convFont(y), { align: 'center' });
  y += lineH * 2;
  doc.text('' + character.healthMax, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + character.initiative, conv(x), convFont(y), { align: 'center' });

  // --- PROPRIETES DIVINES
  x = 955;
  y = 480;
  if (character.divinity) {
    doc.text('' + character.divinity?.name, conv(x), convFont(y));
    y += lineH;
    doc.setFontSize(fontSizeMin);
    doc.text('' + character.divinity?.powerName, conv(x), convFont(y, true));
    doc.setFontSize(fontSize);
    y += lineH;
    doc.text('' + getPalier(character.divinityPoints) + ' / ' + character.deathPoints, conv(x), convFont(y));
  }

  // --- PROPRIETES RACIALES
  x = 955;
  y = 480 + lineH * 4;
  doc.text('' + character.race?.name, conv(x), convFont(y));

  x = 855;
  y = 855;
  const size = 56;
  const raceSkills = allSkills.filter(skill => skill.race && skill.race.code === character.codeRace);
  raceSkills.forEach(raceSkill => {
    if (raceSkill.image) doc.addImage(raceSkill.image, 'JPEG', conv(x - 75 - size/2), conv(y - size/2), conv(size), conv(size));
    doc.text(raceSkill.name, conv(x), convFont(y));
    y += lineH;
  });

  // --- COMPETENCES
  const skills = allSkills.filter(skill => character.idsSkills.includes(skill.id));
  x = 155;
  y = 1230;
  for (let i = 0; i < 3; i++) {
    if (skills.length > i) {
      const skill = skills[i];
      if (skill.image) doc.addImage(skill.image, 'JPEG', conv(x - 75 - size/2), conv(y - size/2), conv(size), conv(size));
      doc.text(skill.name, conv(x), convFont(y));
    }
    y += lineH;
  }

  x = 605;
  y = 1230;
  for (let i = 0; i < 3; i++) {
    if (skills.length > (i+3)) {
      const skill = skills[i+3];
      if (skill.image) doc.addImage(skill.image, 'JPEG', conv(x - 75 - size/2), conv(y - size/2), conv(size), conv(size));
      doc.text(skill.name, conv(x), convFont(y));
    }
    y += lineH;
  }

  x = 1055;
  y = 1230;
  for (let i = 0; i < 3; i++) {
    if (skills.length > (i+6)) {
      const skill = skills[i+6];
      if (skill.image) doc.addImage(skill.image, 'JPEG', conv(x - 75 - size/2), conv(y - size/2), conv(size), conv(size));
      doc.text(skill.name, conv(x), convFont(y));
    }
    y += lineH;
  }

  // --- PROFIL
  x = 395; // 405
  y = 1530;
  doc.setFontSize(fontSizeMin);
  let general = '';
  if (character.age && character.gender) general += character.age + ' ans, ' + character.gender;
  else if (character.age) general += character.age + ' ans';
  else if (character.gender) general += character.gender;
  doc.text('' + general, conv(x), convFont(y, true));
  y += lineH;
  doc.text('' + character.description, conv(x), convFont(y, true));
  y += lineH;
  doc.text('' + character.traits, conv(x), convFont(y, true));
  y += lineH;
  if (character.factionAlly) doc.text('' + character.factionAlly.name, conv(x), convFont(y, true));
  y += lineH;
  if (character.factionEnemy) doc.text('' + character.factionEnemy.name, conv(x), convFont(y, true));

  x = 755;
  y = 1600;
  doc.text('' + checkLibelle(character.story, 740, true), conv(x), convFont(y, true), {
    align: 'left',
    maxWidth: 175,
  });

  doc.addPage();
  const backgroundVerso = (isColored) ? 'assets/files/FichePerso_Verso_Colo.jpeg' : 'assets/files/FichePerso_Verso.jpeg';
  doc.addImage(backgroundVerso, 'JPEG', 0, 0, width, height);

  // --- POUVOIRS
  let powersA: ArlenorPower[] = [];
  let powersB: ArlenorPower[] = [];
  let powersC: ArlenorPower[] = [];

  x = 425;
  y = 182;
  if (character.codeSpeciality01) {
    const powers01 = allPowers.filter(power => character.idsPowers01.includes(power.id));
    powersA = powersA.concat(powers01.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code));
    powersB = powersB.concat(powers01.filter(power => power.codeRank === PowerRanksEnum.Rare.Code));
    powersC = powersC.concat(powers01.filter(power => power.codeRank === PowerRanksEnum.Commun.Code));
  }
  if (character.codeSpeciality02) {
    const powers02 = allPowers.filter(power => character.idsPowers02.includes(power.id));
    powersA = powersA.concat(powers02.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code));
    powersB = powersB.concat(powers02.filter(power => power.codeRank === PowerRanksEnum.Rare.Code));
    powersC = powersC.concat(powers02.filter(power => power.codeRank === PowerRanksEnum.Commun.Code));
  }

  x = 155;
  y = 105;
  for (let i = 0; i < 13; i++) {
    let power = null;
    if (0 <= i && i <= 5 && (i+1) <= powersC.length) power = powersC[i];
    if (6 <= i && i <= 9 && (i+1-6) <= powersB.length) power = powersB[i-6];
    if (10 <= i && i <= 12 && (i+1-10) <= powersA.length) power = powersA[i-10];
    if (power) {
      const title = checkLibelle(power.name + ' (' + power.type.Libelle + ')', 40);
      const subtitle = checkLibelle(power.category.Libelle, 6, true)
        + ' - ' + checkLibelle(power.duration.Libelle, 6, true)
        + ' - ' + checkLibelle(power.range.Libelle, 6, true);
      const description = checkLibelle(power.description, 185);

      if (power.image) doc.addImage(power.image, 'JPEG', conv(x - 75 - size/2), conv(y - size/2), conv(size), conv(size));
      doc.text(title, conv(x), convFont(y - lineH/5, true));
      doc.text(subtitle, conv(x), convFont(y + lineH/5, true));
      doc.text(description, conv(x+450), convFont(y - lineH/5), {
        align: 'left',
        maxWidth: 225,
      });
    }
    y += lineH;
  }

  doc.save('Arlenor_' + getName(character.name) + '.pdf');
};

const downloadPDFVide = async(isColored: boolean): Promise<void> => {
  const doc = new jsPDF('p', 'px', [conv(1400), conv(1980)], true);
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const backgroundRecto = (isColored) ? 'assets/files/FichePerso_Recto_Colo.jpeg' : 'assets/files/FichePerso_Recto.jpeg';
  doc.addImage(backgroundRecto, 'JPEG', 0, 0, width, height);
  doc.addPage();
  const backgroundVerso = (isColored) ? 'assets/files/FichePerso_Verso_Colo.jpeg' : 'assets/files/FichePerso_Verso.jpeg';
  doc.addImage(backgroundVerso, 'JPEG', 0, 0, width, height);
  doc.save('Arlenor_Vide.pdf');
};

const checkLibelle = (value: string, max: number, onlyPoint = false) => {
  let newValue: string;
  if (value.length <= (max - (onlyPoint ? 1 : 3))) newValue = value;
  else newValue = value.slice(0, max - (onlyPoint ? 1 : 3)) + (onlyPoint ? '.' : '...');
  return newValue.replace('\n', ' ');
};

const getName = (value: string) => {
  let code = value;
  code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  code = code.replace(/\s/g, '');
  return code.charAt(0).toUpperCase() + code.slice(1);
};

const getPalier = (value: number) => {
  if (value) {
    let result = 'Palier ';
    for (let i = 0; i < value; i++) {
      result += 'I';
    }
    return result;
  }
  else return '-';
};

export default {
  downloadPDF: downloadPDF,
  downloadPDFVide: downloadPDFVide,
};