<div class="calendar-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="layout-bloc">
      Section en cours de développement...
    </div>
  </head-layout>

  <div little-separator></div>

  <div class="layout-view">
    <div class="layout-bloc">
      Section en cours de développement...
    </div>
  </div>
</div>