<div class="system-view bg-roleplay">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <div class="layout-bloc full-width margin-top-1" [innerHtml]="systemText"></div>

    <div class="system-layout margin-top-1">
      <div class="system-left">
        <tree-menu [currentCode]="currentCode" [menu]="systemMenu" (outChangeCode)="moveToSection($event)">
        </tree-menu>
      </div>
      <div class="system-right">
        <head-section [previousSection]="previousSection" [currentSection]="currentSection" [nextSection]="nextSection"
          (outChange)="moveToSection($event)">
        </head-section>

        <system-caracts-view *ngIf="currentCode === 'SystemCaractsView'"></system-caracts-view>
        <system-races-view *ngIf="currentCode === 'SystemRacesView'"></system-races-view>
        <system-skills-view *ngIf="currentCode === 'SystemSkillsView'"></system-skills-view>
        <system-stuffs-view *ngIf="currentCode === 'SystemStuffsView'"></system-stuffs-view>
        <system-crystals-view *ngIf="currentCode === 'SystemCrystalsView'"></system-crystals-view>
        <system-story-view *ngIf="currentCode === 'SystemStoryView'"></system-story-view>

        <system-difficulties-view *ngIf="currentCode === 'SystemDifficultiesView'"></system-difficulties-view>
        <system-results-view *ngIf="currentCode === 'SystemResultsView'"></system-results-view>
        <system-fight-view *ngIf="currentCode === 'SystemFightView'"></system-fight-view>
        <system-alterations-view *ngIf="currentCode === 'SystemAlterationsView'"></system-alterations-view>
        <system-experience-view *ngIf="currentCode === 'SystemExperienceView'"></system-experience-view>
        <system-divine-view *ngIf="currentCode === 'SystemDivineView'"></system-divine-view>

        <footer-section [previousSection]="previousSection" [nextSection]="nextSection"
          (outChange)="moveToSection($event)">
        </footer-section>
      </div>
    </div>
  </div>
</div>