<div class="encyclo-adventure-view margin-top-1">
  <div *ngIf="!isAdmin && !isMaster && !isPlayer" class="layout-bloc is-info">
    Données réservées aux Maîtres du Jeu et/ou Joueurs.
  </div>

  <ng-container *ngIf="isAdmin || isMaster || isPlayer">
    <div class="layout-bloc" [innerHtml]="coalitionText"></div>

    <div *ngIf="!characters.length" class="layout-bloc is-info margin-top-1">
      Aucun personnage.
    </div>

    <div class="grid-list grid-2 margin-top-1">
      <div *ngFor="let character of characters" class="character-item grid-element">
        <img *ngIf="character.profil" class="character-image is-big" [src]="character.profil" [alt]="character.name">
        <div>
          <h3>{{ character.name }}</h3>
          <p class="text-bold">{{ character.description }}</p>
          <p class="text-italic">{{ character.traits }}</p>
          <p class="margin-bottom-0">{{ character.story ? character.story : 'Aucune description' }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>