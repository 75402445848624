<h3>{{ title }}</h3>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Nom</mat-label>
      <input type="text" matInput maxlength="35" formControlName="name" />
    </mat-form-field>

    <div class="layout-row margin-top-1">
      <mat-form-field>
        <mat-label>Classe</mat-label>
        <mat-select formControlName="codeSpeciality">
          <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code">
            {{ spe.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rang</mat-label>
        <mat-select formControlName="codeRank">
          <mat-option *ngFor="let rank of allRanks" [value]="rank.Code">
            {{ rank.Libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="layout-row margin-top-1">
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="codeType">
          <mat-option *ngFor="let type of allTypes" [value]="type.Code">
            {{ type.Libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Catégorie</mat-label>
        <mat-select formControlName="codeCategory">
          <mat-option *ngFor="let category of allCategories" [value]="category.Code">
            {{ category.Libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field class="margin-top-1">
      <mat-label>Description</mat-label>
      <textarea matInput maxlength="180" formControlName="description"></textarea>
    </mat-form-field>

    <div class="layout-row margin-top-1">
      <mat-form-field>
        <mat-label>Portée du pouvoir</mat-label>
        <mat-select formControlName="codeRange">
          <mat-option *ngFor="let range of allRanges" [value]="range.Code">
            {{ range.Libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Durée et rechargement du pouvoir</mat-label>
        <mat-select formControlName="codeDuration">
          <mat-option *ngFor="let duration of allDurations" [value]="duration.Code">
            {{ duration.Libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="warn" [mat-dialog-close]="false">Annuler</button>
  <button type="button" mat-stroked-button color="primary" [mat-dialog-close]="true" [disabled]="form.invalid"
    (click)="confirm()">
    Confirmer
  </button>
</mat-dialog-actions>