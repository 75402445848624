<div class="creation-content">
  <div class="creation-column">
    <img class="layout-bloc creation-img-perso" src="assets/images_filled/system/creature-intro_ld.webp"
      alt="Illustration d'une créature de JDR">
    <div class="layout-bloc semi-width margin-top-1" [innerHtml]="creatureInfoText"></div>
  </div>
</div>

<div class="creation-content-nav">
  <button type="button" mat-stroked-button color="primary" (click)="start()">
    Commencer la création
  </button>
</div>