import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-faradel-land-view',
  templateUrl: './encyclo-faradel-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloFaradelLandView {

  public citeFaradelResume = citeFaradelResume;
  public citeFaradelDetails = citeFaradelDetails;
  public citeFaradelImage = 'assets/images_filled/lands/section-faradel_md.png';

}

export const citeFaradelResume = convertMarkdownToHTML(`
Le climat est tempéré, le niveau de danger est paisible.
Les hommes et les elfes y vivent principalement.
Si Faradel est forte en technomagie, très avancée, un peu steampunk...
le reste de son territoire est assez médiéval / pauvre.
Niveau diplomatie / gestion du territoire, j'imagine un conseil avec plusieurs personnes importantes,
chacun dans son domaine : la science, la magie et l'armée sont une priorité.
`);

export const citeFaradelDetails = convertMarkdownToHTML(`
Histoire :

- Origines : Fondé il y a des siècles par une alliance entre les elfes et les humains pour exploiter les avancées technologiques et magiques.
- Événements Majeurs : Guerres d'indépendance, découvertes technologiques.

Géographie :

- Terres variées, des plaines fertiles aux forêts enchantées. Carte détaillée avec la Cité de Faradel comme point central.

Culture et Société :

- Structure Sociale : Division en castes basée sur la maîtrise de la magie ou des compétences technologiques.
- Coutumes : Célébrations de l'Innovation, où les nouvelles découvertes sont présentées.

Politique :

- Système Politique : Conseil Triumviral, avec des représentants des domaines scientifique, magique, et militaire.
- Relations Diplomatiques : Ouvertes au commerce, alliances solides avec des nations technomagiques.

Économie :

- Industries : Technomagie, artisanat, commerce.
- Commerce : Échanges technologiques contre des ressources naturelles.

Religion et Croyances :

- Panthéon : Reconnu, mais la religion est souvent liée à la magie ou à la technologie.
- Pratiques Religieuses : Cérémonies liées aux cycles d'innovation et de progrès.

Menaces et Enjeux :

- Adversaires : Tensions avec des nations préférant des approches plus traditionnelles.
- Problèmes Internes : Débats sur l'éthique de certaines avancées technologiques.

Technologie et Magie :

- Technologie : Machines alimentées par la magie, automates, voyages dimensionnels.
- Magie : Principalement basée sur la maîtrise des éléments et l'enchantement.

Éducation et Connaissances :

- Système Éducatif : Académies technomagiques.
- Institutions : Université de Faradel.

Personnages Importants :

- Dirigeants : Triumvirat actuel.
- Héros Nationaux : Inventeurs et mages renommés.

Quotidien des Citoyens :

- Style de Vie : Urbanisé à Faradel, plus rural ailleurs.
- Loisirs : Spectacles technomagiques, jeux d'innovation.

Conflits Récents ou Imminents :

- Conflits en Cours : Tensions territoriales avec des voisins plus traditionnels.

Lieux Notables :

- Villes : Faradel, Nexus des Inventeurs.
- Sites Historiques : L'Arche des Origines, où fut signée l'alliance elfe-humaine.

Événements Spéciaux :

- Festival de l'Innovation : Célébration annuelle des découvertes technologiques et magiques.

Évolution Potentielle :

- Projets : Expansion technologique, exploration de nouvelles dimensions.
- Intrigues : Conflits internes sur les limites éthiques de la technomagie.
`);
