import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TreeNode } from '../../models/TreeNode';

@Component({
  selector: 'head-section',
  templateUrl: './head-section.component.html',
  styleUrls: ['./head-section.component.scss'],
})
export class HeadSectionComponent {
  @Input() public previousSection: TreeNode;
  @Input() public currentSection: TreeNode;
  @Input() public nextSection: TreeNode;
  @Output() public outChange = new EventEmitter<string>();

  public change(code: string) {
    this.outChange.emit(code);
  }
}
