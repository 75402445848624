<div class="head-layout-view" [class]="background ? 'bg-block ' + background : 'bg-black'">
  <img *ngIf="imageLeft" class="is-head-desktop" [src]="imageLeft" alt="Image de présentation à gauche">
  <div class="head-title is-head-tablet">
    <img *ngIf="imageLeft" [src]="imageLeft" alt="Image de présentation à gauche">
    <h1>{{ headTitle }}</h1>
    <img *ngIf="imageRight" [src]="imageRight" alt="Image de présentation à droite">
  </div>
  <div class="head-text">
    <h1 class="is-head-desktop">{{ headTitle }}</h1>
    <ng-content></ng-content>
  </div>
  <img *ngIf="imageRight" class="is-head-desktop" [src]="imageRight" alt="Image de présentation à droite">
</div>