<h2 class="text-center margin-bottom-1">
  Récapitulatif
</h2>

<div class="creation-content">
  <div class="creation-column">
    <div class="semi-width">
      <div class="layout-bloc section is-height-semifree">
        <div class="section-header">
          <img *ngIf="character.avatar" class="section-icon img-rounded" [src]="character.profil"
            [alt]="character.name">
          <div class="text-left margin-left-1">
            <h2 class="margin-bottom-05">{{ character.name }}</h2>
            <div>{{ getDetails() }}</div>
          </div>
        </div>
        <div class="section-actions-buttons margin-top-1">
          <div class="full-width text-center">
            Votre personnage est désormais fini,
            <br>il ne reste plus qu'à lui donner vie dans cet univers !
            <br><b>Bienvenue dans le Monde d'Arlénor !!!</b>
          </div>
          <button type="button" mat-stroked-button color="primary" (click)="download(false)">
            Télécharger la fiche (imprimable)
          </button>
          <button type="button" mat-stroked-button color="primary" (click)="download(true)">
            Télécharger la fiche (colorée)
          </button>
        </div>
        <div *ngIf="character.numLevel === 1" class="section-actions-buttons margin-top-1">
          <button type="button" mat-stroked-button [disabled]="isSaved" (click)="openSavePopup()">
            <span *ngIf="!isSaved">Enregistrer le personnage</span>
            <span *ngIf="isSaved">Enregistrement effectué !</span>
          </button>
          <button type="button" mat-stroked-button *ngIf="isAdmin" [disabled]="isSavedBDD" (click)="openSavePopupBDD()">
            <span *ngIf="!isSavedBDD">Enregistrer le personnage en BDD</span>
            <span *ngIf="isSavedBDD">Enregistrement en BDD effectué !</span>
          </button>
        </div>
      </div>
    </div>

    <img class="creation-img-perso layout-bloc margin-top-1" src="assets/images_filled/system/character-final_ld.webp"
      alt="Illustration de personnages de JDR">
  </div>
</div>

<div class="creation-content-nav">
  <button type="button" mat-stroked-button color="primary" (click)="reset()">
    Créer un nouveau perso
  </button>
  <button type="button" mat-stroked-button color="primary" (click)="restart()">
    Modifier l'actuel
  </button>
</div>