import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TreeNode } from '../../models/TreeNode';

@Component({
  selector: 'footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss'],
})
export class FooterSectionComponent {
  @Input() public previousSection: TreeNode;
  @Input() public nextSection: TreeNode;
  @Output() public outChange = new EventEmitter<string>();

  public goTop() {
    window.scroll(0,0);
  }

  public change(code: string) {
    this.outChange.emit(code);
  }
}
