<div class="creature-view bg-roleplay">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <div *ngIf="step === -1" class="layout-bloc full-width is-warning margin-top-1">
      Le créateur de créatures est une solution en cours de développement,
      certaines fonctionnalités peuvent être absentes ou n'ont pas encore été testées.
    </div>

    <creature-intro-view *ngIf="step === -1" (outNext)="increaseStep()">
    </creature-intro-view>
    <creature-selection-view *ngIf="step === 0" (outNext)="increaseStep()">
    </creature-selection-view>
    <creature-identity-view *ngIf="step === 1" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </creature-identity-view>
    <creature-caracts-view *ngIf="step === 2" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </creature-caracts-view>
    <creature-crystals-view *ngIf="step === 3" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </creature-crystals-view>
    <creature-details-view *ngIf="step === 4" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </creature-details-view>
    <creature-final-view *ngIf="step === 5" (outPrevious)="reset($event)">
    </creature-final-view>

    <ul *ngIf="step > 0 && step < 5" class="creation-steps steps-container">
      <div class="dotline"></div>
      <li [class]="{'active': step === 1 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 2 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 3, 'warning': !creature.caractMag }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 4 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 5 }" class="dot"></li>
      <div class="dotline"></div>
    </ul>
  </div>
</div>