import { Component, EventEmitter, Output } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'character-intro-view',
  templateUrl: './character-intro.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterIntroView {

  @Output() public outNext = new EventEmitter();

  public characterInfoText = characterInfoText;
  public characterAlertText = characterAlertText;

  public start() {
    this.outNext.emit();
  }
}

export const characterInfoText = convertMarkdownToHTML(`
  Un personnage est défini par plusieurs choses : on commence par **ses caractéristiques**,
  **sa race**, **ses compétences principales**, **ses pouvoirs**... pour ensuite finir par **son identité** et quelques finitions.

  Cependant, malgré toutes les valeurs notées sur la fiche de personnage,
  c'est VOUS qui le ferez vivre via ses actions : on choisit ce que l'on est par nos actes !`);

export const characterAlertText = convertMarkdownToHTML(`
  Notez que la création de personnage prend du temps et aucune sauvegarde n'est faite
  (hormis les personnages de niveau 1 en fin de création).`);