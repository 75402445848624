import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ArlenorLevel } from '../../../../models/arlenor/ArlenorLevel';
import { ArlenorSkill, SkillTypesEnum } from '../../../../models/arlenor/ArlenorSkill';
import { ModelEnum } from '../../../../models/ModelEnum';
import { SkillModal } from '../../../modals/skill/skill.modal';

@Component({
  selector: 'skills-selection-table',
  templateUrl: './skills-selection-table.component.html',
  styles: [':host {	display: contents; }'],
})
export class SkillsSelectionTableComponent implements OnInit, OnChanges {

  @Input() public level: ArlenorLevel;
  @Input() public idsSkills: number[];
  @Input() public skills: ArlenorSkill[];
  @Output() public outAdd = new EventEmitter<ArlenorSkill>();
  @Output() public outRemove = new EventEmitter<ArlenorSkill>();

  public types: ModelEnum[];
  public displayedColumns: string[] = ['image', 'name', 'value'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataSources: any;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.updateTypes();
  }

  public ngOnChanges(): void {
    this.updateTypes();
  }

  public updateTypes() {
    if (!this.skills) return;
    this.types = [SkillTypesEnum.Weapon, SkillTypesEnum.Other];
    this.dataSources = {
      [SkillTypesEnum.Weapon.Code]: new MatTableDataSource<ArlenorSkill>(this._getSkillsByType(SkillTypesEnum.Weapon.Code)),
      [SkillTypesEnum.Other.Code]: new MatTableDataSource<ArlenorSkill>(this._getSkillsByType(SkillTypesEnum.Other.Code)),
    };
  }

  public changeSkill(value: boolean, skill: ArlenorSkill) {
    if (value) this.outAdd.emit(skill);
    else this.outRemove.emit(skill);
  }

  public checkSkill(skill: ArlenorSkill) {
    return this.idsSkills.includes(skill.id);
  }

  public checkDisabled(skill: ArlenorSkill) {
    if (this.checkSkill(skill)) return false;
    return this.idsSkills.length >= this.level.maxSkills;
  }

  public seeMore(skill: ArlenorSkill) {
    const dialogRef = this.dialog.open(SkillModal, {
      data: skill,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  private _getSkillsByType(codeType: string) {
    const skills = this.skills.filter(skill => skill.codeType === codeType);
    skills.sort((a, b) => a.name.localeCompare(b.name));
    return skills;
  }
}