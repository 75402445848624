import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'creation-form',
  templateUrl: './creation-form.component.html',
  styles: [':host {	display: contents; }'],
})
export class CreationFormComponent {

  @Input() public formTitle: string;
  @Input() public isModified: boolean;
  @Input() public isInvalid: boolean;
  @Output() public outCancel = new EventEmitter();
  @Output() public outSubmit = new EventEmitter();

  public needConfirm: boolean;

  public cancel() {
    if (this.isModified && !this.needConfirm) {
      this.needConfirm = true;
    } else {
      this.outCancel.emit();
    }
  }

  public submit() {
    this.outSubmit.emit();
  }
}
