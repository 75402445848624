/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArlenorPower } from '../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../models/arlenor/ArlenorSkill';
import { ArlenorStuff } from '../models/arlenor/ArlenorStuff';
import { equipmentExample, folderItemExample, itemExample, powerExample, skillExample } from './foundry_examples';
import random from './random';

export const getFolderItem = (name: string) => {
  const obj = Object.assign({}, folderItemExample);
  const id = random.generateID(16);
  obj._id = id;
  obj._key = '!folders!' + id;
  obj.name = name;
  return obj;
};

export const getSkill = (skill: ArlenorSkill, idFolder: string) => {
  const obj = Object.assign({}, skillExample);
  const id = random.generateID(16);
  obj._id = id;
  obj._key = '!items!' + id;
  obj.img = 'systems/arlenor/' + skill.image;
  obj.folder = idFolder;
  obj.name = skill.name;
  obj.system.description = skill.description;
  obj.system.skillType = skill.codeType;
  return obj;
};

export const getEquipment = (equipment: ArlenorStuff, idFolder: string) => {
  const obj = Object.assign({}, equipmentExample);
  const id = random.generateID(16);
  obj._id = id;
  obj._key = '!items!' + id;
  obj.img = 'systems/arlenor/' + equipment.image;
  obj.folder = idFolder;
  obj.name = equipment.name;
  obj.system.description = equipment.description;
  obj.system.equipmentType = equipment.codeType;
  return obj;
};

export const getItem = (item: ArlenorStuff, idFolder: string) => {
  const obj = Object.assign({}, itemExample);
  const id = random.generateID(16);
  obj._id = id;
  obj._key = '!items!' + id;
  obj.img = 'systems/arlenor/' + item.image;
  obj.folder = idFolder;
  obj.name = item.name;
  obj.system.description = item.description;
  obj.system.itemType = item.codeType;
  obj.system.quantity = 1;
  return obj;
};

export const getPower = (power: ArlenorPower, idFolder: string) => {
  const obj = Object.assign({}, powerExample);
  const id = random.generateID(16);
  obj._id = id;
  obj._key = '!items!' + id;
  obj.img = 'systems/arlenor/' + power.image;
  obj.folder = idFolder;
  obj.name = power.name;
  obj.system.description = power.description;
  obj.system.speciality = power.codeSpeciality;
  obj.system.category = power.codeCategory;
  obj.system.family = power.codeType;
  obj.system.rank = power.codeRank;
  obj.system.duration = power.codeDuration;
  obj.system.range = power.codeRange;
  return obj;
};
