<form *ngIf="hasSearch" [formGroup]="form" class="margin-bottom-1">
  <mat-form-field>
    <mat-label>Recherche</mat-label>
    <input type="text" matInput formControlName="search" />
    <span *ngIf="!form.controls.search.value" matSuffix class="margin-right-1 icon-search"></span>
    <span *ngIf="form.controls.search.value" matSuffix class="margin-right-1 icon-cross pointer"
      (click)="reset()"></span>
  </mat-form-field>
</form>

<div *ngIf="currentMenu.length === 0" class="layout-bloc">Aucun résultat disponible.</div>

<mat-tree *ngIf="currentMenu.length > 0" [dataSource]="dataSource" [treeControl]="treeControl" class="tree-menu">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <button *ngIf="node.code && node.code === currentCode" type="button" mat-button color="primary">
      {{ node.title }}
    </button>
    <button *ngIf="!node.code || node.code !== currentCode" type="button" mat-button (click)="selectNode(node)"
      [disabled]="!node.code">
      {{ node.title }}
    </button>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <div class="tree-menu-button" matTreeNodeToggle>
        <span class="margin-right-05" [class.icon-minus]="treeControl.isExpanded(node)"
          [class.icon-plus]="!treeControl.isExpanded(node)"></span>
        {{ node.title }}
      </div>
    </div>

    <div [class.tree-menu-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>