import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '../../../../components/modals/confirmation/confirmation.modal';
import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorPower } from '../../../../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../../../../models/arlenor/ArlenorSkill';
import { StoreService } from '../../../../services/store.service';
import downloads_characters from '../../../../utils/downloads_characters';

@Component({
  selector: 'character-selection-view',
  templateUrl: './character-selection.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterSelectionView implements OnInit {

  @Output() public outNext = new EventEmitter();
  @Output() public outPrevious = new EventEmitter();

  public form: FormGroup;
  public numeros = Array(20).fill(1).map((x,i) => x+i);

  private _destroyRef = inject(DestroyRef);

  public get selectedCharacter(): ArlenorCharacter | null {
    if (this.form.controls.guid) {
      const character = this.characters.find(charact => charact.guid === this.form.controls.guid.value);
      return character ? character : null;
    } else return null;
  }

  public get toDelete(): boolean {
    return this.selectedCharacter && (!this.selectedCharacter.isVersionOK || this.selectedCharacter.numLevel > 1);
  }

  public get characters(): ArlenorCharacter[] {
    const allCharacters = this._storeService.$allCharacters.value || [];
    const localCharacters = this._storeService.localCharacters || [];
    return allCharacters.concat(localCharacters);
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      guid: new FormControl(''),
      numLevel: new FormControl(1, Validators.required),
    });
  }

  public ngOnInit() {
    this.form.controls.guid.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((guid: string) => {
        if (guid) this.form.controls.numLevel.setValue(this.selectedCharacter.numLevel);
        else this.form.controls.numLevel.setValue(1);

        // On vérifie si le personnage est à supprimer
        if (this.toDelete) this.form.controls.numLevel.disable();
        else this.form.controls.numLevel.enable();
      });
  }

  public getDetails(character: ArlenorCharacter) {
    if (character.isVersionOK && character.numLevel === 1) {
      let libelle = character.race?.name;
      if (character.speciality01) libelle += ' - ' + character.speciality01.name;
      if (character.speciality02) libelle += '/' + character.speciality02.name;
      if (!character.isBDD) libelle += ' (' + character.date + ' ' + character.hour + ')';
      return libelle;
    } else {
      return '(ancienne version : à supprimer)';
    }
  }

  public start() {
    if (this.form.controls.guid.value) {
      this._storeService.changeCharacter(this.selectedCharacter);
      this._storeService.restartCharacter();
    } else this._storeService.resetCharacter();
    this._storeService.changeCharacterLevel(this.form.controls.numLevel.value);
    this.outNext.emit();
  }

  public quit() {
    this._reset();
    this.outPrevious.emit();
  }

  public selectCharacter(guid: string) {
    this.form.controls.guid.setValue(guid);
  }

  public async download(isColored: boolean) {
    if (this.selectedCharacter) {
      downloads_characters.downloadPDF(isColored, this.selectedCharacter, this.allSkills, this.allPowers);
    } else {
      downloads_characters.downloadPDFVide(isColored);
    }
  }

  public openDeletePopup() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression du personnage`,
        content: `Souhaitez-vous vraiment supprimer le personnage nommé
        <b>` + this.selectedCharacter.name + ` - Niv ` + this.selectedCharacter.numLevel + `</b> ?
        <br><br>
        Cette action est irréversible.`,
      },
    });

    dialogRef.afterClosed().subscribe((value: boolean) => {
      console.log('The dialog was closed', value);
      if (value) {
        this._storeService.deleteLocalCharacter(this.selectedCharacter.guid);
        this._reset();
      }
    });
  }

  private _reset(): void {
    this._storeService.resetCharacter();
    this.form.controls.guid.setValue('');
    this.form.controls.numLevel.setValue(1);
  }
}
