import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ArlenorLevel } from '../../../../models/arlenor/ArlenorLevel';
import { ArlenorPower, PowerRanksEnum } from '../../../../models/arlenor/ArlenorPower';
import { ModelEnum } from '../../../../models/ModelEnum';
import { PowerModal } from '../../../modals/power/power.modal';

@Component({
  selector: 'powers-selection-table',
  templateUrl: './powers-selection-table.component.html',
  styles: [':host {	display: contents; }'],
})
export class PowersSelectionTableComponent implements OnInit, OnChanges {

  @Input() public indexCrystal: number;
  @Input() public level: ArlenorLevel;
  @Input() public idsPowers: number[];
  @Input() public powers: ArlenorPower[];
  @Output() public outAdd = new EventEmitter<ArlenorPower>();
  @Output() public outRemove = new EventEmitter<ArlenorPower>();

  public ranks: ModelEnum[];
  public displayedColumns: string[] = ['image', 'name', 'value'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataSources: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentRanks: any;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.updateRanks();
  }

  public ngOnChanges(): void {
    this.updateRanks();
  }

  public updateRanks() {
    if (!this.powers) return;
    this.ranks = this.powers.map(power => power.rank).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
    this.ranks.sort((a, b) => b.Code.localeCompare(a.Code));
    this.dataSources = {
      [PowerRanksEnum.Commun.Code]: new MatTableDataSource<ArlenorPower>(this._getPowersByRank(PowerRanksEnum.Commun.Code)),
      [PowerRanksEnum.Rare.Code]: new MatTableDataSource<ArlenorPower>(this._getPowersByRank(PowerRanksEnum.Rare.Code)),
      [PowerRanksEnum.TresRare.Code]: new MatTableDataSource<ArlenorPower>(this._getPowersByRank(PowerRanksEnum.TresRare.Code)),
    };
    if (this.level) {
      this.currentRanks = {
        [PowerRanksEnum.Commun.Code]: { current: this._getNbRank(PowerRanksEnum.Commun.Code), max: this.level.maxRankC[this.indexCrystal] },
        [PowerRanksEnum.Rare.Code]: { current: this._getNbRank(PowerRanksEnum.Rare.Code), max: this.level.maxRankB[this.indexCrystal] },
        [PowerRanksEnum.TresRare.Code]: { current: this._getNbRank(PowerRanksEnum.TresRare.Code), max: this.level.maxRankA[this.indexCrystal] },
        [PowerRanksEnum.Unique.Code]: { current: this._getNbRank(PowerRanksEnum.Unique.Code), max: this.level.maxRankS[this.indexCrystal] },
      };
    } else {
      const current = this.idsPowers.length;
      const max = 5;
      this.currentRanks = {
        [PowerRanksEnum.Commun.Code]: { current, max },
        [PowerRanksEnum.Rare.Code]: { current, max },
        [PowerRanksEnum.TresRare.Code]: { current, max },
        [PowerRanksEnum.Unique.Code]: { current, max },
      };
    }
  }

  public changePower(value: boolean, power: ArlenorPower) {
    if (value) this.outAdd.emit(power);
    else this.outRemove.emit(power);
  }

  public checkPower(power: ArlenorPower) {
    return this.idsPowers.includes(power.id);
  }

  public checkDisabled(codeRank: string, power: ArlenorPower) {
    if (this.checkPower(power)) return false;
    return this.currentRanks[codeRank].current >= this.currentRanks[codeRank].max;
  }

  public seeMore(power: ArlenorPower) {
    const dialogRef = this.dialog.open(PowerModal, {
      data: power,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  private _getPowersByRank(rank: string) {
    return this.powers.filter(power => power.codeRank === rank);
  }

  private _getNbRank(rank: string) {
    return this.powers.filter(power => power.codeRank === rank && this.idsPowers.includes(power.id)).length;
  }
}