/* eslint-disable @typescript-eslint/no-explicit-any */
import { CelestiaQuizz } from '../models/celestia/CelestiaQuizz';
import { ResponseQuizz } from './api_models';
import requests from './requests';

// --- QUIZZ ---------------------------------------------------------------------------
const readAllQuizz = async(): Promise<CelestiaQuizz[]> => {
  const results: ResponseQuizz[] = await requests.requestGet('quizz');

  const finalResults: CelestiaQuizz[] = [];
  results.forEach((obj: ResponseQuizz) => {
    const quizz = new CelestiaQuizz();
    quizz.id = obj.ref_quizz ? 0 : 1;
    finalResults.push(quizz);
  });

  return finalResults;
};

const sendQuizz = async(quizz: CelestiaQuizz): Promise<string> => {
  quizz.initTime();
  const result: string = await requests.requestPost('quizz', quizz);
  return result;
};

export default {
  readAllQuizz,
  sendQuizz,
};