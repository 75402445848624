import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ArlenorStuff } from '../../../models/arlenor/ArlenorStuff';
import { StuffModal } from '../../modals/stuff/stuff.modal';

@Component({
  selector: 'stuffs-table',
  templateUrl: './stuffs-table.component.html',
  styleUrls: [],
})
export class StuffsTableComponent implements OnInit, OnChanges {

  @Input() public stuffs: ArlenorStuff[];

  public displayedColumns: string[] = ['image', 'name', 'type'];
  public dataSource: MatTableDataSource<ArlenorStuff>;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this._refresh();
  }

  public ngOnChanges(): void {
    this._refresh();
  }

  public seeMore(stuff: ArlenorStuff) {
    const dialogRef = this.dialog.open(StuffModal, {
      data: stuff,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  private _refresh() {
    if (!this.stuffs) this.dataSource = null;
    else {
      this.stuffs.sort((a, b) => {
        if (a.codeType === b.codeType) return a.name.localeCompare(b.name);
        else return a.codeType.localeCompare(b.codeType);
      });
      this.dataSource = new MatTableDataSource<ArlenorStuff>(this.stuffs);
    }
  }
}