<div class="encyclo-ether-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité d'Ether">
      <img class="encyclopedia-img" [src]="citeEtherImage" alt="La Cité d'Ether" />
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
      <!--div class="layout-bloc" [innerHTML]="citeEtherResume"></div>
      <div class="layout-bloc margin-top-1" [innerHTML]="citeEtherDetails"></div-->
    </mat-tab>
    <mat-tab label="La Forêt de Miryden">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
    <mat-tab label="Le Lac Utica">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
    <mat-tab label="Les Montagnes de Habivel">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
  </mat-tab-group>
</div>