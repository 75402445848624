import { Component, Input, OnInit } from '@angular/core';

import { ArlenorFaction } from '../../../../models/arlenor/ArlenorFaction';
import { getListFactions } from '../../../../models/data/ListFactions';

@Component({
  selector: 'encyclo-factions-view',
  templateUrl: './encyclo-factions.view.html',
  styleUrls: ['./encyclo-factions.view.scss'],
})
export class EncycloFactionsView implements OnInit {

  @Input() public isOff: boolean;

  public allFactions: ArlenorFaction[];
  public factionsOfficielles: ArlenorFaction[];
  public factionsNonOfficielles: ArlenorFaction[];

  public ngOnInit() {
    this.allFactions = getListFactions();
    this.factionsOfficielles = this.allFactions.filter((faction: ArlenorFaction) => faction.isOff);
    this.factionsNonOfficielles = this.allFactions.filter((faction: ArlenorFaction) => !faction.isOff);
  }
}
