<div class="layout-bloc margin-top-1" [innerHtml]="systemCaractsText01"></div>

<expand-bloc blocTitle="La Force" [blocState]="caractChoice === 1" (outToggle)="changeCaractChoice(1)">
  <div [innerHtml]="caractDescriptionEnum.Force.Libelle"></div>
</expand-bloc>

<expand-bloc blocTitle="L'Habileté" [blocState]="caractChoice === 2" (outToggle)="changeCaractChoice(2)">
  <div [innerHtml]="caractDescriptionEnum.Habilete.Libelle"></div>
</expand-bloc>

<expand-bloc blocTitle="L'Intellect" [blocState]="caractChoice === 3" (outToggle)="changeCaractChoice(3)">
  <div [innerHtml]="caractDescriptionEnum.Intellect.Libelle"></div>
</expand-bloc>

<expand-bloc blocTitle="La Ténacité" [blocState]="caractChoice === 4" (outToggle)="changeCaractChoice(4)">
  <div [innerHtml]="caractDescriptionEnum.Tenacite.Libelle"></div>
</expand-bloc>

<expand-bloc blocTitle="Le Charisme" [blocState]="caractChoice === 5" (outToggle)="changeCaractChoice(5)">
  <div [innerHtml]="caractDescriptionEnum.Charisme.Libelle"></div>
</expand-bloc>

<expand-bloc blocTitle="La Magie" [blocState]="caractChoice === 6" (outToggle)="changeCaractChoice(6)">
  <div [innerHtml]="caractDescriptionEnum.Magie.Libelle"></div>
</expand-bloc>

<div class="layout-bloc margin-top-1" [innerHtml]="systemCaractsText02"></div>