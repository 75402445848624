<div class="encyclo-adventure-view margin-top-1">
    <div *ngIf="!isAdmin && !isMaster && !isPlayer" class="layout-bloc is-info">
        Données réservées aux Maîtres du Jeu et/ou Joueurs.
    </div>

    <mat-tab-group *ngIf="isAdmin || isMaster || isPlayer" animationDuration="0ms">
        <mat-tab label="L'Equipe Satan">
            <mat-tab-group animationDuration="0ms" class="margin-top-1">
                <mat-tab label="0) Les Personnages">
                    <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamSatan }"></ng-container>
                </mat-tab>
                <mat-tab label="1) Le Temple de Kiranof">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalSatan01"></div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <mat-tab label="L'Equipe Asmodée">
            <mat-tab-group animationDuration="0ms" class="margin-top-1">
                <mat-tab label="0) Les Personnages">
                    <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamAsmodee }"></ng-container>
                </mat-tab>
                <mat-tab label="1) Le Temple de Kiranof">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalAsmodee01"></div>
                </mat-tab>
                <mat-tab label="2) La Forêt d'Imerys">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalAsmodee02"></div>
                </mat-tab>
                <mat-tab label="3) Les Mines de Jirakan">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalAsmodee03"></div>
                </mat-tab>
                <!--mat-tab label="4) La Lucifaniel">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalAsmodee04"></div>
                </mat-tab-->
            </mat-tab-group>
        </mat-tab>
        <mat-tab label="L'Equipe Gabriel">
            <mat-tab-group animationDuration="0ms" class="margin-top-1">
                <mat-tab label="0) Les Personnages">
                    <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamGabriel }"></ng-container>
                </mat-tab>
                <mat-tab label="1) Le Temple de Kiranof">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalGabriel01"></div>
                </mat-tab>
                <mat-tab label="2) La Forêt d'Imerys">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalGabriel02"></div>
                </mat-tab>
                <mat-tab label="3) Les Mines de Jirakan">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalGabriel03"></div>
                </mat-tab>
                <mat-tab label="4) La Lucifaniel">
                    <div class="layout-bloc margin-top-1" [innerHtml]="journalGabriel04"></div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #charactersGrid let-team="team">
    <div *ngIf="!getCharacters(team).length" class="layout-bloc is-info margin-top-1">
        Aucun personnage.
    </div>

    <div class="grid-list grid-2 margin-top-1">
        <div *ngFor="let character of getCharacters(team)" class="character-item grid-element">
            <img *ngIf="character.profil" class="character-image" [src]="character.profil" [alt]="character.name">
            <div>
                <h3>{{ character.name }}</h3>
                <p class="text-bold">{{ getDetails(character) }}</p>
                <p class="text-italic">
                    {{ character.description }}
                    <br>{{ character.traits }}
                </p>
                <p class="margin-bottom-0">
                    {{ character.story ? character.story : 'Aucune description' }}
                </p>
            </div>
        </div>
    </div>
</ng-template>