import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-dakaros-land-view',
  templateUrl: './encyclo-dakaros-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloDakarosLandView {

  public citeDakarosResume = citeDakarosResume;
  public citeDakarosDetails = citeDakarosDetails;
  public citeDakarosImage = 'assets/images_filled/lands/section-dakaros_md.png';

}

export const citeDakarosResume = convertMarkdownToHTML(`
Un climat alpin dans des montagnes avec des zones chaudes et d'autres enneigées.
Le territoire est contrôlé par une citée cachée par des montagnes, celle du peuple des nains.
Elle est nommée Dakaros et n'est accessible que par une cascade ou la voie des airs.
Toute autre race est exclue de leur territoire, sous peine de mort.
Les autres races n'ont pas conscience de ce territoire, elles le considèrent juste comme dangereux.
Les nains sont des purs carnivores et peuvent dès la naissance se lier à un dragon
(créature non découverte par les autres races).
Et ici, c'est la loi du plus fort qui fonctionne : pour devenir chef des nains,
il faut battre le précédent lors d'un duel avec les dragons.
`);

export const citeDakarosDetails = convertMarkdownToHTML(`
Histoire :

- Fondé par les Nains pour protéger leur mode de vie et leurs liens avec les dragons.
- Événements clés : Découverte de la cascade d'accès, établissement de Dakaros.

Géographie :

- Climat alpin, montagnes avec des zones chaudes et enneigées. Dakaros est cachée derrière les montagnes.

Culture et Société :

- Isolationnisme : Exclusion totale des autres races, considérées comme dangereuses.
- Lien avec les Dragons : Nains liés à des dragons dès la naissance.

Religion et Croyances :

- Dragonologie : Vénération des dragons, considérés comme protecteurs et puissants.
- Loi du Plus Fort : Leadership basé sur des duels avec les dragons.

Politique :

- Monarchie de la Force : Chef déterminé par des duels de dragons, succession violente.
- Exclusion Rigide : Peine de mort pour les intrus.

Économie :

- Ressources Montagnardes : Exploitation des ressources des montagnes.
- Commerce Restreint : Échanges limités avec l'extérieur.

Éducation et Connaissances :

- Art de la Guerre : Formation dès l'enfance aux duels avec les dragons.
- Connaissance des Montagnes : Apprentissage des ressources et dangers locaux.

Technologie et Magie :

- Technologie Naine : Axée sur l'exploitation minière et les armes.
- Magie Restreinte : Utilisation magique liée à la maîtrise des dragons.

Relations Internationales :

- Secrets et Méfiance : Les autres territoires ignorent Dakaros.
- Danger Percu : Dakaros est perçue comme une menace par les voisins.

Personnages Importants :

- Champion des Dragons : Leader actuel, puissant combattant.
- Dresseurs de Dragons : Nains experts dans la gestion des dragons.

Conflits Récents ou Imminents :

- Conflits pour la Suprématie : Tensions internes pour devenir le prochain champion.

Lieux Notables :

- Cascade d'Accès : Entrée secrète à Dakaros.
- L'Arène : Lieu où se déroulent les duels de dragons.

Événements Spéciaux :

- Tournois : Compétitions périodiques pour déterminer les futurs champions.
- Jours des Échanges : Rencontres avec d'autres territoires, avec précaution.

Quotidien des Citoyens :

- Entraînement Continu : Préparation constante aux duels et à la défense.
- Cérémonies de Liaison : Célébrations des liens avec les dragons.

Évolution Potentielle :

- Expansion Discrète : Exploration des territoires voisins sans révéler Dakaros.
- Risques Internes : Conflits pour le leadership, potentielle dissension.
`);