
export const environment = {
  production: true,
  NG_APP_BO_ADMIN: 'ArlenorBackofficeOpen',
  NG_APP_BO_MASTER: 'ArlenorGM',
  NG_APP_BO_PLAYER: 'ArlenorPL',
  NG_APP_API_ANON_KEY: 'CqI2xKFC2U4Cu8jzr2hhc4SB8hJA70134JU226iS',
  NG_APP_API_URL: 'https://ibq1mym018.execute-api.eu-west-3.amazonaws.com/production/',
  NG_APP_SUPABASE_ANON_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFvZHlub3hwdWZ2aXJlZmtseGN3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI5NjI3NTksImV4cCI6MjAwODUzODc1OX0.1InWwAnbEbVJqYTnYyfB1W1IrZTDs88qRVF6_o7dqN4',
  NG_APP_SUPABASE_URL: 'https://aodynoxpufvirefklxcw.supabase.co'
};
