<div class="encyclo-bestiary-view margin-top-1">
  <div class="layout-bloc">
    {{ bestiary.description }}
    <br><i>{{ bestiary.examples }}</i>
  </div>

  <div *ngIf="bestiary.code === 'ETOILESPRIMORDIALES'" class="layout-bloc margin-top-1"
    [innerHtml]="etoilesPrimordialesText">
  </div>

  <div *ngIf="!creatures.length" class="layout-bloc is-info margin-top-1">
    Aucune créature.
  </div>
  <div class="grid-list grid-2 margin-top-1">
    <div *ngFor="let creature of creatures" class="creature-item grid-element">
      <img *ngIf="creature.profil" class="creature-image" [src]="creature.profil" [alt]="creature.name">
      <div>
        <h3 class="margin-bottom-1">{{ creature.name }}</h3>
        <p><span [class]="creature.difficultyColor">{{ creature.difficultyLibelle }}</span></p>
        <p class="margin-bottom-0">{{ creature.story ? creature.story : 'Aucune description' }}</p>
      </div>
    </div>
  </div>
</div>