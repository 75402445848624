import { Component, Input } from '@angular/core';

import { ArlenorPower, PowerRanksEnum } from '../../models/arlenor/ArlenorPower';

@Component({
  selector: 'power-image',
  templateUrl: './power-image.component.html',
  styles: [':host {	display: contents; }'],
})
export class PowerImageComponent {

  @Input() public isBig: boolean;
  @Input() public power: ArlenorPower;

  public powerRanksEnum = PowerRanksEnum;
}
