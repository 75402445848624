import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SpecialityModal } from '../../../../components/modals/speciality/speciality.modal';
import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorPower, PowerRanksEnum } from '../../../../models/arlenor/ArlenorPower';
import { ArlenorSpeciality } from '../../../../models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '../../../../models/data/ListSpecialities';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'character-crystals-view',
  templateUrl: './character-crystals.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterCrystalsView implements OnInit, OnChanges {

  @Input() public indexCrystal: number;
  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public allSpecialities: ArlenorSpeciality[];
  public idsPowers: number[];
  public isModified: boolean;

  private _destroyRef = inject(DestroyRef);

  public get selectedSpeciality(): ArlenorSpeciality | null {
    if (!this.form.controls.codeSpeciality.value) return null;
    return ArlenorSpecialities.getSpeciality(this.form.controls.codeSpeciality.value);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get filteredPowers(): ArlenorPower[] {
    const filteredPowers = this.allPowers.filter(power => power.speciality.code === this.selectedSpeciality?.code);
    filteredPowers.sort((a, b) => a.name.localeCompare(b.name));
    return filteredPowers;
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeSpeciality: new FormControl(null, Validators.required),
      isNbPowersValid: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    const codeSpeciality = (this.indexCrystal === 0) ? this.character.codeSpeciality01 : this.character.codeSpeciality02;
    this.idsPowers = (this.indexCrystal === 0) ? this.character.idsPowers01 : this.character.idsPowers02;
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
    this.allSpecialities.sort((a, b) => a.name.localeCompare(b.name));

    // Init
    this.form.controls.codeSpeciality.setValue(codeSpeciality);

    this.form.controls.codeSpeciality.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
        this.idsPowers = [];
        this._checkNbPowers();
      });

    this._checkOldPowers();
  }

  public ngOnChanges() {
    this._checkOldPowers();
  }

  public addPower(power: ArlenorPower) {
    this.idsPowers.push(power.id);
    this.isModified = true;
    this._checkNbPowers();
  }

  public removePower(power: ArlenorPower) {
    this.idsPowers = this.idsPowers.filter((idPower: number) => idPower !== power.id);
    this.isModified = true;
    this._checkNbPowers();
  }

  public checkSpe(spe: ArlenorSpeciality) {
    if (!this.allPowers || !this.idsPowers) return false;
    // Pour tester si une classe est éligible
    if (this.indexCrystal === 1) {
      const character: ArlenorCharacter = this._storeService.character;
      if (spe.code === character.codeSpeciality01) return false;
    }

    const list = this.allPowers.filter(power => power.speciality?.code === spe.code);
    if (list.length === 0) return false;

    const nbRankS = list.filter(power => power.codeRank === PowerRanksEnum.Unique.Code).length;
    if (nbRankS < this.character.level.maxRankS[this.indexCrystal]) return false;

    const nbRankA = list.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code).length;
    if (nbRankA < this.character.level.maxRankA[this.indexCrystal]) return false;

    const nbRankB = list.filter(power => power.codeRank === PowerRanksEnum.Rare.Code).length;
    if (nbRankB < this.character.level.maxRankB[this.indexCrystal]) return false;

    const nbRankC = list.filter(power => power.codeRank === PowerRanksEnum.Commun.Code).length;
    if (nbRankC < this.character.level.maxRankC[this.indexCrystal]) return false;

    return true;
  }

  public openSpePopup() {
    // Archetypes
    const archetypes = this._storeService.$allArchetypes.value.filter(arch => arch.codeSpeciality === this.selectedSpeciality.code);
    if (archetypes.length > 0) this.selectedSpeciality.archetype01 = archetypes[0];
    if (archetypes.length > 1) this.selectedSpeciality.archetype02 = archetypes[1];

    const dialogRef = this.dialog.open(SpecialityModal, {
      data: {
        speciality: this.selectedSpeciality,
        archetypes: archetypes,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    const character: ArlenorCharacter = this._storeService.character;
    if (this.indexCrystal === 0) {
      newCharacter.codeSpeciality01 = this.form.controls.codeSpeciality.value;
      newCharacter.idsPowers01 = this.idsPowers;
      newCharacter.codeSpeciality02 = character.codeSpeciality02;
      newCharacter.idsPowers02 = character.idsPowers02;
    } else {
      newCharacter.codeSpeciality01 = character.codeSpeciality01;
      newCharacter.idsPowers01 = character.idsPowers01;
      newCharacter.codeSpeciality02 = this.form.controls.codeSpeciality.value;
      newCharacter.idsPowers02 = this.idsPowers;
    }
    this._storeService.changeCharacterCrystals(newCharacter);
  }

  private _checkOldPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const validIdsPowers = this.allPowers.map(power => power.id);
    this.idsPowers = this.idsPowers.filter((idPower: number) => validIdsPowers.includes(idPower));
    this._checkNbPowers();
  }

  private _checkNbPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const powersSelected = this.allPowers.filter(power => this.idsPowers.includes(power.id));
    const nbRankS = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Unique.Code).length;
    const nbRankA = powersSelected.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code).length;
    const nbRankB = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Rare.Code).length;
    const nbRankC = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Commun.Code).length;

    this.form.controls.isNbPowersValid.setValue((
      nbRankS === this.character.level.maxRankS[this.indexCrystal]
        && nbRankA === this.character.level.maxRankA[this.indexCrystal]
        && nbRankB === this.character.level.maxRankB[this.indexCrystal]
        && nbRankC === this.character.level.maxRankC[this.indexCrystal]
    ) ? true : null);
  }
}
