<creation-form formTitle="Choix des caractéristiques" [isModified]="isModified"
  [isInvalid]="form.controls.pointsLeft.value !== 0" (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form">
      <table mat-table [dataSource]="dataSource" class="hide-on-mobile margin-top-05">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="col-30">Nom</th>
          <td mat-cell *matCellDef="let element" class="col-30">
            {{ element.libelle }}&nbsp;({{ element.code }})
          </td>
        </ng-container>

        <ng-container matColumnDef="range">
          <th mat-header-cell *matHeaderCellDef class="col-40"></th>
          <td mat-cell *matCellDef="let element" class="col-40">
            <mat-slider [min]="element.isNullable ? 0 : 1" [max]="maxCaract" step="1" [showTickMarks]="true">
              <input type="range" matSliderThumb [formControlName]="element.key" (change)="changeCaract()">
            </mat-slider>
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef class="col-10">Valeur</th>
          <td mat-cell *matCellDef="let element" class="col-10">
            {{ form.controls[element.key].value }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="col-20">Actions</th>
          <td mat-cell *matCellDef="let element" class="col-20 text-right">
            <button type="button" mat-stroked-button color="primary" (click)="openCaractPopup(element.code)">
              En savoir plus
            </button>
          </td>
        </ng-container>

        <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
        <tr mat-row *matRowDef="let row; columns: ['name', 'range', 'value', 'actions'];"></tr>
      </table>

      <table mat-table [dataSource]="dataSource" class="show-on-mobile margin-top-05">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="col-10">Nom</th>
          <td mat-cell *matCellDef="let element" class="col-10">
            <button type="button" mat-stroked-button color="primary" (click)="openCaractPopup(element.code)">
              {{ element.code }}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="range">
          <th mat-header-cell *matHeaderCellDef class="col-70"></th>
          <td mat-cell *matCellDef="let element" class="col-70">
            <mat-slider [min]="element.isNullable ? 0 : 1" [max]="maxCaract" step="1" [showTickMarks]="true">
              <input type="range" matSliderThumb [formControlName]="element.key" (change)="changeCaract()">
            </mat-slider>
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef class="col-10">Valeur</th>
          <td mat-cell *matCellDef="let element" class="col-10 text-left">
            {{ form.controls[element.key].value }}
          </td>
        </ng-container>

        <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
        <tr mat-row *matRowDef="let row; columns: ['name', 'range', 'value'];"></tr>
      </table>
    </form>

    <div class="layout-row layout-col-mob margin-top-1">
      <div *ngIf="form.controls.pointsLeft.value !== 0" class="layout-bloc semi-width is-warning">
        Points restants à répartir : {{ form.controls.pointsLeft.value }}
      </div>
      <div *ngIf="form.controls.pointsLeft.value === 0" class="layout-bloc semi-width">
        Tous les points ont été répartis.
      </div>
      <div class="layout-bloc semi-width">
        Le Bonus d'Initiative est à : <b>{{ getInitiative() }}</b>
        <br>Les Points de Vie sont à : <b>{{ getPointsDeVie() }}</b>
        <span *ngIf="form.controls.mag.value === 0" class="text-red">
          <br><b>Le personnage n'aura pas de capacités magiques.</b>
        </span>
      </div>
    </div>
  </div>
</creation-form>