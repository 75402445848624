import { Component, OnInit } from '@angular/core';

import { ArlenorZone } from '../../models/arlenor/ArlenorZone';
import { getListZones } from '../../models/data/ListZones';

@Component({
  selector: 'universe-view',
  templateUrl: './universe.view.html',
  styleUrls: ['./universe.view.scss'],
})
export class UniverseView implements OnInit {

  public currentIndex: number;
  public allZones: ArlenorZone[];
  public showCities: boolean;

  public get previousIndex(): number {
    if (this.currentIndex === 0) return this.allZones.length - 1;
    else return this.currentIndex - 1;
  }

  public get nextIndex(): number {
    if (this.currentIndex === this.allZones.length - 1) return 0;
    else return this.currentIndex + 1;
  }

  public get currentZone(): ArlenorZone {
    return this.allZones[this.currentIndex];
  }

  public ngOnInit() {
    this.currentIndex = 0;
    this.allZones = getListZones();
  }

  public previousSelection() {
    if (this.currentIndex === 0) this.currentIndex = this.allZones.length - 1;
    else this.currentIndex = this.currentIndex - 1;
  }

  public nextSelection() {
    if (this.currentIndex === this.allZones.length - 1) this.currentIndex = 0;
    else this.currentIndex = this.currentIndex + 1;
  }

  public selectZone(index: number) {
    this.currentIndex = index;
  }

  public toggleMap(value: boolean) {
    this.showCities = value;
  }
}
