import { Component } from '@angular/core';

import { StoreService } from '../../../../services/store.service';
import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-divine-view',
  templateUrl: './encyclo-divine.view.html',
  styleUrls: [],
})
export class EncycloDivineView {

  public encycloDivineText = encycloDivineText;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  constructor(
    private _storeService: StoreService,
  ) {}
}

export const encycloDivineText = convertMarkdownToHTML(`
- Une divinité dans son corps divin est invulnérable avec un potentiel magique exponentiel : seule une autre divinité
pourrait en tuer une autre (ce qui la ferait passer **en mode Evaporation**).
- Une divinité vivant dans un Etherolithe est aussi invulnérable : mais son corps prisonnier est endormi... ou semi-réveillé
(l'Etherolithe dégage alors une énergie illimitée).
- Une divinité vivant dans un réceptacle la rend vulnérable : son potentiel magique est plus faible
et la divinité passe **en mode Evaporation** au bout de 3 morts du corps du réceptacle.
- Une divinité prenant le contrôle de son réceptacle est une mise en danger directe pour elle : si le corps du réceptacle
meurt dans cet état, la mort est définitive pour le réceptacle, et la divinité passe **en mode Evaporation**.

- Une divinité qui meurt ne perd pas tout de suite son essence, elle passe **en mode Evaporation** :
elle va chercher à se transmuter dans un objet qui lui est cher pour devenir une relique sans conscience,
ou chercher un réceptacle vide à posséder pour garder sa conscience.
- Si une divinité meurt et perd son essence, ou que sa relique est détruite : c'est sa fin. La fin d'une divinité
engendre des complications dans le monde liées à son principe ou son symbole.

- L'Etherolithe vidée de l'essence d'une divinité n'a aucun intérêt : l'ancien corps de la divinité y reste intact
mais sans la divinité, cela reste un simple corps.
- Les divinités peuvent avoir comme envie de récupérer leur propre corps : mais l'Etherolithe agit comme une barrière.
Actuellement, seule la Société de l'Eveil connait le processus d'extraction et possiblement celui de réinsertion.
`);