<div class="encyclo-somni-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Somni">
      <img class="encyclopedia-img" [src]="citeSomniImage" alt="La Cité de Somni" />
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
      <!--div class="layout-bloc" [innerHTML]="citeSomniResume"></div>
      <div class="layout-bloc margin-top-1" [innerHTML]="citeSomniDetails"></div-->
    </mat-tab>
    <mat-tab label="Les Terres Oubliées">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
  </mat-tab-group>
</div>