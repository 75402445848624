import { ArlenorBestiary } from '../arlenor/ArlenorBestiary';

export function getListBestiaries(): ArlenorBestiary[] {
  const bestiaries: ArlenorBestiary[]= [];

  bestiaries.push(new ArlenorBestiary(
    'Créations magiques',
    `Êtres sortis de l'imaginaire, ou objets devenus animés, dotés ou non de capacités magiques.`,
    'Exemples : balai, armure, golem...'
  ));
  bestiaries.push(new ArlenorBestiary(
    'Créatures animales',
    `Êtres de la faune naturelle, sans capacité magique.`,
    'Exemples : loup, oiseau, poisson...'
  ));
  bestiaries.push(new ArlenorBestiary(
    'Créatures mythiques',
    `Êtres liés à la lumière, souvent beaux, bienveillants et souvent dotés de capacités magiques innées.`,
    'Exemples : dryade, fée, dragon...'
  ));
  bestiaries.push(new ArlenorBestiary(
    'Créatures monstrueuses',
    `Êtres liés aux ténèbres, souvent difformes, terrifiants, malveillants et dangereux.`,
    'Exemples : mort-vivant, wendigo, harpie...'
  ));
  bestiaries.push(new ArlenorBestiary(
    'Divinités',
    `Êtres légendaires liés à la lumière ou aux ténèbres, appartenant à la famille des anges et des démons`,
    `Les divinités apparaissent seulement dans la campagne de JDR / l'Aventure, normalement.`
  ));
  bestiaries.push(new ArlenorBestiary(
    'Étoiles primordiales',
    `Êtres immatériels parfaits, venant du ciel, étant les premières entités de ce monde.`,
    `Ces êtres sont uniques.`
  ));
  bestiaries.push(new ArlenorBestiary(
    `Esprits fantômes`,
    `Êtres immatériels, anciennement mortels, pouvant être convoqués spirituellement par un Druide.`,
    'Ces esprits ont toujours quelque chose à régler...'
  ));

  return bestiaries;
}
