import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorSkill } from '../../../../models/arlenor/ArlenorSkill';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'character-skills-view',
  templateUrl: './character-skills.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterSkillsView implements OnInit, OnChanges {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public idsSkills: number[];
  public isModified: boolean;

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  constructor(private _storeService: StoreService) {
    this.form = new FormGroup({
      isNbSkillsValid: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    this.idsSkills = this.character.idsSkills;

    this._checkOldSkills();
  }

  public ngOnChanges() {
    this._checkOldSkills();
  }

  public addSkill(skill: ArlenorSkill) {
    this.idsSkills.push(skill.id);
    this.isModified = true;
    this._checkOldSkills();
  }

  public removeSkill(skill: ArlenorSkill) {
    this.idsSkills = this.idsSkills.filter((idSkill: number) => idSkill !== skill.id);
    this.isModified = true;
    this._checkOldSkills();
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.idsSkills = this.idsSkills;
    this._storeService.changeCharacterSkills(newCharacter);
  }

  private _checkOldSkills() {
    if (!this.allSkills || !this.idsSkills) return;
    const validIdsSkills = this.allSkills.map(skill => skill.id);
    this.idsSkills = this.idsSkills.filter(idSkill => validIdsSkills.includes(idSkill));
    this._checkNbSkills();
  }

  private _checkNbSkills() {
    if (!this.allSkills || !this.idsSkills) return;
    const nbSkills = this.idsSkills.length;
    this.form.controls.isNbSkillsValid.setValue((nbSkills === this.character.level.maxSkills) ? true : null);
  }
}
