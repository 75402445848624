import { Component, DestroyRef, EventEmitter, inject, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SpecialityModal } from '../../../../components/modals/speciality/speciality.modal';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { ArlenorPower } from '../../../../models/arlenor/ArlenorPower';
import { ArlenorSpeciality } from '../../../../models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '../../../../models/data/ListSpecialities';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'creature-crystals-view',
  templateUrl: './creature-crystals.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureCrystalsView implements OnInit, OnChanges {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public creature: ArlenorCreature;
  public allSpecialities: ArlenorSpeciality[];
  public idsPowers: number[];
  public isModified: boolean;

  private _destroyRef = inject(DestroyRef);

  public get selectedSpeciality(): ArlenorSpeciality | null {
    if (!this.form.controls.codeSpeciality.value) return null;
    return ArlenorSpecialities.getSpeciality(this.form.controls.codeSpeciality.value);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get filteredPowers(): ArlenorPower[] {
    const filteredPowers = this.allPowers.filter(power => power.speciality.code === this.selectedSpeciality?.code);
    filteredPowers.sort((a, b) => a.name.localeCompare(b.name));
    return filteredPowers;
  }

  public get selectedPowers(): ArlenorPower[] {
    const selectedPowers = this.allPowers.filter(power => this.idsPowers.includes(power.id));
    selectedPowers.sort((a, b) => a.name.localeCompare(b.name));
    return selectedPowers;
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeSpeciality: new FormControl(null),
      isNbPowersValid: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.creature = this._storeService.creature;
    this.idsPowers = this.creature.idsPowers;
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
    this.allSpecialities.sort((a, b) => a.name.localeCompare(b.name));

    // Init
    this.form.controls.codeSpeciality.setValue(this.allSpecialities[0].code);

    this.form.controls.codeSpeciality.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
        this._checkNbPowers();
      });

    this._checkOldPowers();
  }

  public ngOnChanges() {
    this._checkOldPowers();
  }

  public addPower(power: ArlenorPower) {
    this.idsPowers.push(power.id);
    this.isModified = true;
    this._checkNbPowers();
  }

  public removePower(power: ArlenorPower) {
    this.idsPowers = this.idsPowers.filter((idPower: number) => idPower !== power.id);
    this.isModified = true;
    this._checkNbPowers();
  }

  public openSpePopup() {
    // Archetypes
    const archetypes = this._storeService.$allArchetypes.value.filter(arch => arch.codeSpeciality === this.selectedSpeciality.code);
    if (archetypes.length > 0) this.selectedSpeciality.archetype01 = archetypes[0];
    if (archetypes.length > 1) this.selectedSpeciality.archetype02 = archetypes[1];

    const dialogRef = this.dialog.open(SpecialityModal, {
      data: {
        speciality: this.selectedSpeciality,
        archetypes: archetypes,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.idsPowers = this.idsPowers;
    this._storeService.changeCreatureCrystals(newCreature);
  }

  private _checkOldPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const validIdsPowers = this.allPowers.map(power => power.id);
    this.idsPowers = this.idsPowers.filter((idPower: number) => validIdsPowers.includes(idPower));
  }

  private _checkNbPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const powersSelected = this.allPowers.filter(power => this.idsPowers.includes(power.id));
    this.form.controls.isNbPowersValid.setValue((powersSelected.length === 5) ? true : null);
  }
}
