import { Component, EventEmitter, Output } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'creature-intro-view',
  templateUrl: './creature-intro.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureIntroView {

  @Output() public outNext = new EventEmitter();

  public creatureInfoText = creatureInfoText;

  public start() {
    this.outNext.emit();
  }
}

export const creatureInfoText = convertMarkdownToHTML(`
  Une créature est définie par peu de choses : contrairement au personnage, on commence par **son identité**,
  puis par **ses caractéristiques**, **ses capacités magiques** s'il y a... pour ensuite finir par quelques finitions.`);