import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { InformationModal } from '../../../../components/modals/information/information.modal';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { ArlenorPower } from '../../../../models/arlenor/ArlenorPower';
import { StoreService } from '../../../../services/store.service';
import downloads_creatures from '../../../../utils/downloads_creatures';
import random from '../../../../utils/random';
import supabase_api from '../../../../utils/supabase_api';

@Component({
  selector: 'creature-final-view',
  templateUrl: './creature-final.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureFinalView implements OnInit {

  @Output() public outPrevious = new EventEmitter();

  public creature: ArlenorCreature;
  public isSaved = false;
  public isSavedBDD = false;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.creature = this._storeService.creature;
  }

  public reset() {
    this._storeService.resetCreature();
    this.outPrevious.emit(false);
  }

  public restart() {
    this._storeService.restartCreature();
    this.outPrevious.emit(true);
  }

  public async download(isColored: boolean) {
    downloads_creatures.downloadPDF(isColored, this.creature, this.allPowers);
  }

  public openSavePopup() {
    const creature = this.creature;
    creature.initTime();
    creature.isBDD = false;
    this._storeService.saveLocalCreature(creature);
    this.isSaved = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde de la créature`,
        content: `Votre créature
        <b>` + creature.name + `</b>
        est désormais sauvegardée sur votre navigateur.
        <br><br>
        Note : En effaçant l'historique de votre navigateur, vos créatures sauvegardées seront effacées.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public async openSavePopupBDD() {
    const creature = this.creature;
    creature.initTime();
    creature.isBDD = true;
    creature.guid = random.generateID(20);
    await supabase_api.postCreature(creature);
    this.isSavedBDD = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde de la créature`,
        content: `Votre créature
        <b>` + creature.name + `</b>
        est désormais sauvegardée en base de données.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }
}
