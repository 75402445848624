import { Component, OnInit } from '@angular/core';

import { CelestiaCharacter } from '../../models/celestia/CelestiaCharacter';
import { CelestiaQuizz } from '../../models/celestia/CelestiaQuizz';
import { getListCelestias } from '../../models/data/ListCelestia';
import { PageTitles } from '../../models/PagesTitles';
import { StoreService } from '../../services/store.service';
import api from '../../utils/api';

@Component({
  selector: 'celestia-view',
  templateUrl: './celestia.view.html',
  styleUrls: ['./celestia.view.scss'],
})
export class CelestiaView implements OnInit {

  public title = PageTitles.celestia;
  public imageLeft = 'assets/images_alpha/magic/magic_left.png';
  public imageRight = 'assets/images_alpha/magic/magic_right.png';

  public currentIndex: number;
  public currentQuestion: number;
  public pourcent: number;
  public allCelestias: CelestiaCharacter[];
  public allQuizz: CelestiaQuizz[];
  public quizz: CelestiaQuizz;

  public get previousIndex(): number {
    if (this.currentIndex === 0) return this.allCelestias.length - 1;
    else return this.currentIndex - 1;
  }

  public get nextIndex(): number {
    if (this.currentIndex === this.allCelestias.length - 1) return 0;
    else return this.currentIndex + 1;
  }

  public get currentCelestia(): CelestiaCharacter {
    return this.allCelestias[this.currentIndex];
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.currentIndex = 0;
    this.currentQuestion = 100;
    this.pourcent = 100;
    this.allCelestias = getListCelestias();
    this.allQuizz = [];
    this.quizz = this._storeService.quizz;
    this._loadQuizz();
  }

  public previousSelection() {
    if (this.currentIndex === 0) this.currentIndex = this.allCelestias.length - 1;
    else this.currentIndex = this.currentIndex - 1;
  }

  public nextSelection() {
    if (this.currentIndex === this.allCelestias.length - 1) this.currentIndex = 0;
    else this.currentIndex = this.currentIndex + 1;
  }

  public getListArray(arr: string[]) {
    let lib = '';
    arr.forEach(ele => {
      lib += '<br/>- ' + ele;
    });
    return lib;
  }

  public getLibArray(arr: string[]) {
    let lib = '';
    arr.forEach((ele, index) => {
      lib += ele;
      if (index < arr.length - 1) lib += ', ';
    });
    return lib;
  }

  public getLibFireWater() {
    const value = this.quizz.fire - this.quizz.water;
    if (value > 3) return `Dominance du Feu`;
    if (value < -3) return `Dominance de l'Eau`;
    return `Aucune dominance`;
  }

  public getLibWindEarth() {
    const value = this.quizz.wind - this.quizz.earth;
    if (value > 3) return `Dominance de l'Air`;
    if (value < -3) return `Dominance de la Terre`;
    return `Aucune dominance`;
  }

  public startQuestion() {
    this.currentQuestion = 0;
  }

  public async nextQuestion() {
    // On finit le questionnaire
    if (this.currentQuestion + 1 === this.quizz.questions.length) {
      this.currentQuestion = 200;
      await api.sendQuizz(this.quizz);
      await this._loadQuizz();
      this._checkPourcent();
    }
    // On change de question
    else this.currentQuestion++;
  }

  private async _loadQuizz() {
    const allQuizz = await api.readAllQuizz();
    this.allQuizz = allQuizz;
  }

  private _checkPourcent() {
    if (!this.allQuizz.length) this.pourcent = 100;
    let nbSame = 0;
    this.allQuizz.forEach(quizz => {
      if (quizz.result.code === this.quizz.result.code) nbSame++;
    });
    this.pourcent = Math.round((nbSame / this.allQuizz.length) * 100);
  }
}
