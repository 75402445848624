import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorFaction } from '../../../models/arlenor/ArlenorFaction';

@Component({
  selector: 'faction-modal',
  templateUrl: './faction.modal.html',
  styleUrls: ['faction.modal.scss'],
})
export class FactionModal implements OnInit {

  public faction: ArlenorFaction;

  constructor(
    public dialogRef: MatDialogRef<FactionModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public ngOnInit(): void {
    this.faction = this.data;
  }
}