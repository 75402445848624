<creation-form formTitle="Choix de la race" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form" class="semi-width">
      <mat-form-field>
        <mat-label>Race du personnage</mat-label>
        <mat-select formControlName="raceCode">
          <mat-option *ngFor="let race of allRaces" [value]="race.code">{{ race.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="semi-width margin-top-1">
      <button type="button" mat-stroked-button color="primary" [disabled]="!currentRace" (click)="openRacePopup()">
        En savoir plus
      </button>
    </div>

    <div *ngIf="currentRace" class="grid-list grid-2 grid-sized margin-top-1">
      <div *ngFor="let skill of getSkills(currentRace.code)" class="grid-element">
        <img *ngIf="skill.image" [src]="skill.image" [alt]="skill.name">
        <div>
          <div class="grid-header text-bold">{{ skill.name }}</div>
          <p class="grid-body" [innerHtml]="skill.description"></p>
        </div>
      </div>
    </div>
  </div>
</creation-form>