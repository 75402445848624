import { ModelAPI } from '../ModelAPI';
import { DifficultyEnum } from '../ModelEnum';
import { ArlenorBestiary } from './ArlenorBestiary';

export const CURRENT_CREATURE_VERSION = 1;

export class ArlenorCreature extends ModelAPI {

  // Variables à sauvegarder
  public isBDD: boolean;
  public version: number;
  public guid: string;
  public avatar: string;
  public name: string;

  public codeBestiary: string | null;

  public caractFor: number;
  public caractHab: number;
  public caractInt: number;
  public caractTen: number;
  public caractCha: number;
  public caractMag: number;

  public idsPowers: number[];

  public story: string;
  public description: string;
  public traits: string;

  // Variables dérivées
  public get isVersionOK(): boolean {
    return this.version === CURRENT_CREATURE_VERSION;
  }

  public get initiative(): number {
    return this.caractHab + this.caractInt;
  }

  public get totalCaracts(): number {
    return this.caractFor
      + this.caractHab
      + this.caractInt
      + this.caractTen
      + this.caractCha
      + this.caractMag;
  }

  public get difficultyColor(): string {
    const stats = this.caractFor
      + this.caractHab
      + this.caractInt
      + this.caractTen
      + this.caractCha
      + this.caractMag;
    if (Math.round(stats / 6) >= 5) return 'status-violet';
    else if (Math.round(stats / 6) >= 4) return 'status-red';
    else if (Math.round(stats / 6) >= 3) return 'status-yellow';
    else if (Math.round(stats / 6) >= 2) return 'status-green';
    else return 'status-ocean';
  }

  public get difficultyLibelle(): string {
    const stats = this.caractFor
      + this.caractHab
      + this.caractInt
      + this.caractTen
      + this.caractCha
      + this.caractMag;
    if (Math.round(stats / 6) >= 5) return DifficultyEnum.Legendaire.Libelle;
    else if (Math.round(stats / 6) >= 4) return DifficultyEnum.Epique.Libelle;
    else if (Math.round(stats / 6) >= 3) return DifficultyEnum.Complexe.Libelle;
    else if (Math.round(stats / 6) >= 2) return DifficultyEnum.Standard.Libelle;
    else return DifficultyEnum.Simple.Libelle;
  }

  public get healthMax(): number {
    let points = 4;
    if (this.caractTen > 3) points++;
    else if (this.caractTen === 1) points--;
    return points;
  }

  public get profil(): string | null {
    if (this.avatar && this.avatar.indexOf('data') === 0) return this.avatar;
    else if (this.avatar) return 'assets/images_filled/creatures/' + this.avatar + '.png';
    else return '';
  }

  public get bestiary(): ArlenorBestiary | null {
    if (!this.codeBestiary) return null;
    return ArlenorBestiary.getBestiary(this.codeBestiary);
  }

  constructor() {
    super();
    this.isBDD = false;
    this.version = CURRENT_CREATURE_VERSION;
    this.guid = '';
    this.avatar = '';
    this.name = '';

    this.codeBestiary = null;

    this.caractFor = 1;
    this.caractHab = 1;
    this.caractInt = 1;
    this.caractTen = 1;
    this.caractCha = 1;
    this.caractMag = 0;

    this.idsPowers = [];

    this.story = '';
    this.description = '';
    this.traits = '';
  }
}
