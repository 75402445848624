/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

export const folderItemExample = {
  'name': 'Guardians',
  'type': 'Item',
  'sorting': 'a',
  'folder': null,
  'color': null,
  'flags': {},
  '_stats': {
    'systemId': 'arlenor',
    'systemVersion': '1.2.0',
    'coreVersion': '11.315',
    'createdTime': null,
    'modifiedTime': null,
    'lastModifiedBy': null
  },
  '_id': '6q2eHdXOcMKZJMD3',
  'sort': 100000,
  '_key': '!folders!6q2eHdXOcMKZJMD3'
};

export const skillExample = {
  'name': 'SkillEx',
  'type': 'skill',
  'img': 'icons/svg/item-bag.svg',
  'system': {
    'description': '',
    'skillType': 'OTHER'
  },
  'effects': [],
  'folder': null,
  'ownership': {
    'default': 0,
  },
  'flags': {},
  '_stats': {
    'systemId': 'arlenor',
    'systemVersion': '1.2.0',
    'coreVersion': '11.315',
    'createdTime': null,
    'modifiedTime': null,
    'lastModifiedBy': null
  },
  '_id': 'WtEibxJm3HDtUTkY',
  'sort': 0,
  '_key': '!items!WtEibxJm3HDtUTkY'
};

export const equipmentExample = {
  'name': 'EquipEx',
  'type': 'equipment',
  'img': 'icons/svg/item-bag.svg',
  'system': {
    'description': '',
    'equipmentType': 'CAC'
  },
  'effects': [],
  'folder': null,
  'ownership': {
    'default': 0,
  },
  'flags': {},
  '_stats': {
    'systemId': 'arlenor',
    'systemVersion': '1.2.0',
    'coreVersion': '11.315',
    'createdTime': null,
    'modifiedTime': null,
    'lastModifiedBy': null
  },
  '_id': 'jMZ6nbhKUkA3RDPc',
  'sort': 0,
  '_key': '!items!jMZ6nbhKUkA3RDPc'
};

export const itemExample = {
  'name': 'ItemEx',
  'type': 'item',
  'img': 'icons/svg/item-bag.svg',
  'system': {
    'description': '',
    'itemType': 'AUTRE',
    'quantity': 1
  },
  'effects': [],
  'folder': null,
  'ownership': {
    'default': 0
  },
  'flags': {},
  '_stats': {
    'systemId': 'arlenor',
    'systemVersion': '1.2.0',
    'coreVersion': '11.315',
    'createdTime': null,
    'modifiedTime': null,
    'lastModifiedBy': null
  },
  '_id': 'KOaV4pg8W4yukR7y',
  'sort': 0,
  '_key': '!items!KOaV4pg8W4yukR7y'
};

export const powerExample = {
  'name': 'PowerG01',
  'type': 'power',
  'img': 'icons/svg/item-bag.svg',
  'system': {
    'description': '',
    'powerType': 'EVO',
    'speciality': 'GARDIEN',
    'category': 'SPELL',
    'family': 'OFF',
    'rank': 'C',
    'duration': '0',
    'range': '0'
  },
  'effects': [],
  'folder': null,
  'ownership': {
    'default': 0
  },
  'flags': {},
  '_stats': {
    'systemId': 'arlenor',
    'systemVersion': '1.2.0',
    'coreVersion': '11.315',
    'createdTime': null,
    'modifiedTime': null,
    'lastModifiedBy': null
  },
  '_id': 'VPGhUcE31GEyAUJM',
  'sort': 0,
  '_key': '!items!VPGhUcE31GEyAUJM'
};