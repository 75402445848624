<creation-form formTitle="Choix des détails" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form" class="semi-width">
      <mat-form-field>
        <mat-label>Description (physique)</mat-label>
        <input type="text" matInput maxlength="35" placeholder="Robuste, élégant, frêle, ridé, jeune..."
          formControlName="description" />
      </mat-form-field>
      <mat-form-field class="margin-top-1">
        <mat-label>Personnalité (caractère)</mat-label>
        <input type="text" matInput maxlength="35" placeholder="Timide, sérieux, charmeur, rêveur, généreux..."
          formControlName="traits" />
      </mat-form-field>
      <mat-form-field class="margin-top-1">
        <mat-label>Histoire personnelle et faits divers</mat-label>
        <textarea matInput maxlength="740"
          placeholder="Son enfance, sa motivation dans la vie, l'acquisition de ses pouvoirs, des haut-faits réalisés..."
          formControlName="story">
        </textarea>
      </mat-form-field>
    </form>
  </div>
</creation-form>