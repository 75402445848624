import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'system-results-view',
  templateUrl: './system-results.view.html',
  styleUrls: [],
})
export class SystemResultsView {

  public systemResultsText01 = systemResultsText01;
  public systemResultsText02 = systemResultsText02;

  public displayedColumns: string[] = [
    'name',
    'score0',
    'score1',
    'score2',
    'score3',
    'score4',
    'score5'
  ];

  public data: ResultElement[] = [
    {
      name: 'Simple',
      description: '(1 dé gagnant)',
      scores: ['Score max à 1', 'Score max à 2', 'Score max à 3', 'Score max à 4', 'Score max à 5', 'Score max à 6']
    },
    {
      name: 'Standard',
      description: '(2 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6']
    },
    {
      name: 'Complexe',
      description: '(3 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6']
    },
    {
      name: 'Épique',
      description: '(4 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6']
    },
    {
      name: 'Légendaire',
      description: '(5 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6']
    },
  ];
  public dataSource = new MatTableDataSource<ResultElement>(this.data);

}

export interface ResultElement {
  name: string;
  description: string;
  scores: string[];
}

export const systemResultsText01 = convertMarkdownToHTML(`
  Pour les **tests de difficulté**, on utilisera cette grille de réussites et d'échec comme référence :
  ces tests concernent généralement le personnage lui-même, ou des actions sur des cibles alliées
  (qui n'opposent pas de résistance).

  Pour les **tests d'opposition**, on utilisera la même grille :
  le MJ jugera simplement de la difficulté de l'opposant.
  *Exemple : Pour un type d'ennemi, l'esquiver sera une difficulté Complexe, mais l'attaquer sera une difficulté Simple.
  Autre exemple : Forcer cette porte sera une difficulté Standard.*`);

export const systemResultsText02 = convertMarkdownToHTML(`
  **Réussite critique** : L'action est réussie de manière exceptionnelle,
  obtenant un résultat au-delà des attentes.
  **Réussite simple** : L'action est réussie sans complication ni coût supplémentaire.
  **Réussite à coût** : L'action est réussie, mais avec des complications mineures
  ou des coûts supplémentaires.
  **Échec simple** : L'action échoue, mais sans conséquence.
  **Échec à conséquence** : L'action échoue, mais avec des conséquences mineures.
  **Échec critique** : L'action est un échec total, avec des conséquences graves.`);