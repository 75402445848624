import { Component } from '@angular/core';

import { PageTitles } from '../../../models/PagesTitles';

@Component({
  selector: 'calendar-view',
  templateUrl: './calendar.view.html',
  styleUrls: ['./calendar.view.scss'],
})
export class CalendarView {
  public title = PageTitles.calendar;
  public imageLeft = 'assets/images_alpha/religion/angel.png';
  public imageRight = 'assets/images_alpha/religion/demon.png';
}
