import { convertMarkdownToHTML } from '../../../../utils/conversions';

export const journalGabrielPersos = convertMarkdownToHTML(`
  **Les personnages**

  **Demetrius**, c'est un elfe arborant beaucoup de bijoux, amnésique et clerc.
  Il a eu le droit à une puberté express.
  D'un enfant relativement petit, il arrive presque à rivaliser avec ma taille. Et tout ça en moins de quelques semaines.

  **Kasyade** est un invocateur, grand, tatoué et végan.
  Il a bien changé depuis notre rencontre.
  Si on peut acquiescer qu'une chenille devenir un papillon, on peut très bien s'imaginer un tel changement chez l'humain.

  **Decim** est un barde collectionneur d'artéfacts, de bijoux et de conquêtes et se trouve être également un enfant-réceptacle.
  Note à part, je trouve ça un peu suspicieux de sa part de partager ce genre d'informations avec des inconnus.
  Toutefois, il nous rassure en rappellant qu'il est là après que nos deux mentors (?) lui soient apparus en rêve.

  Pour **Dynn**, on va donner que des informations vagues.
  Nous venons seulement de rencontrer Decim, je ne sais pas encore comment il réagit aux mutants.
  Mes compagnons de route, eux, sont déjà au courant mais il vaut mieux ne pas le crier sur tous les toits.
`);

export const journalGabriel01 = convertMarkdownToHTML(`
  **Scénario 1 : Le Temple de Kiranof**

  *En cours d'écriture...*
`);

export const journalGabriel02 = convertMarkdownToHTML(`
  **Scénario 2 : La Forêt d'Imerys**
  
  *Taverne*

  Ayant reçu la missive d'Érick à Kiranof, nous nous rendons avec Démitrius et Kasyade au village proche de la lisière de la Forêt d'Imérys.
  Notre contact sur place doit nous retrouver à la Taverne de la Sirène.
  Decim, un humain barde violoniste aux très belles cuisses nous accueille et nous entraîne vers la dite taverne.
  Le tavernier, Bob, nous mène à une table et nous laisse nous présenter avant d'apporter de quoi se sustenter. Tour de table rapide. 
  Bob nous donne pas mal d'informations personnelles concernant son arbre généalogique
  et sur les futures rencontres que nous pourrions faire dans d'autres villages et dans les alentours.
  Il nous présente également la forêt d'Imérys.
  Selon les rumeurs, seulement quelques personnes arrivent à sortir de cette forêt après y être rentrée.
  Il parait également d'un village de mutants de trouverait en plein milieu. 
  Notre objectif était de retrouver un enfant-réceptacle dans cette forêt.
  Finalement, faire un crochet dans ce village de mutants pourrait être une bonne idée. Nous passons la nuit et nous préparons pour notre expédition.

  *Forêt d'Imerys*
  
  Honnêtement, heureusement que nous nous sommes un minimum préparés.
  Il fait froid, humide, nous avons quelques trous dans le feuillage qui filtre la lumière.
  Le tonnerre tombe non loin mais à une fréquence assez élevée.
  Nos remarquons des sentiers marqués par le passage même si ce mot est un peu exagéré comparé à la réalité.
  Démitrius trouve un corps calciné derrière un fourré.
  Nous nous retrouvons face à une intersection.
  Un chemin semble être plus utilisé que l'autre. Kasyade fait apparaître un oiseau et l'envoie survoler la zone.
  Ce dernier repère un maison un peu plus loin. Pour y accéder, il faudrait prendre le chemin en mauvais état.
  Après une petite concertation, nous allons nous approcher de la maison avant de faire demi-tour et prendre l'autre chemin.

  *Maison mystérieuse*

	Une fois sur place, nous sommes arrivés devant une chaumière de bois. Une sorcière aurait pu y vivre paisible.
  Devant elle, se trouvait un arbre mort. Il semblerait que la foudre y tombe dessus assez régulièrement vu les traces calcinées qui le parcourent. 
  Après différentes conversations pour savoir si oui ou non on devait faire demi-tour, Decim frappe à la porte.
  S'en suit une partie de séduction entre le propriétaire et le barde. Quelques maladresses furent échangées.
  L'ambiance ici est étranger. L'homme de la maison nous appris que la maison appartenait à sa famille et qu'il vivait de ses cultures.
  Afin de créer un peu d'espace, je demande s'il était possible de récupérer quelques légumes pour la route.
  Il accepte et nous conduit à son potager. 
  Démitrius s'excuse, prétextant avec une envie pressante. Nous le laissons s'éloigner vers les toilettes sèches.
  En arrivant au potager, nous remarquons des plantes médicinales et nécessaires à la réalisation de concoctions.
  A minima, l'homme ne sait pas qu'il possède ces plantes ou alors il s'est bien caché de le préciser.
  Sur le chemin, nous lui demandons si un village existait bien sur ces terres et s'il pouvait nous indiqué le chemin.
  Alors que nous échangions ces informations, un craquement électrique retentit. Nous prenons tous le chemin de la maison.
  Sur le sol se trouvait Démitrius, proche de l'arbre. Le propriétaire devient beaucoup moins amical, demandant qu'on quitte rapidement les lieux.
  Nous en profitons pour prendre le chemin derrière la maison pour rejoindre le village rapidement.
  Avec un blessé sur les bras, nous ne passons pas inaperçu.
  Nous apercevons l'entrée du village. Sur le chemin, Démitrius nous expliqua ce qu'il avait fait.
  Il regardait à l'intérieur de la maison, identifiant des cristaux reliés les uns aux autres conduisant à l'étage.

  *Village en pleine forêt*

  L'ambiance ici est plus bucolique. Nous nous sentons à l'aise.
  Toutefois, notre arrivée n'annonçait pas des bonnes nouvelles pour les villageois qui s'enfermaient chez eux.
  Nous suivons l'allée principale menant à un temple.
  Nous n'en avons jamais vu des pareils aux travers des différents villages que nous avons pu traverser.
  Nous suivons les indications en direction d'un médecin et nous découvrons Amélia, une jeune femme blonde aux vêtements blancs (style prêtresse).
  Elle nous dirige vers chez son père, le médecin. Il prend en charge Démitrius.
  Amélia semble un peu trop intéressée par ses blessures au point d'en donner la chair de poule. Le groupe se scinde en deux :
  Démitrius, Decim et Kasyade et moi. Notre groupe se rend au temple pour prier. Le deuxième reste avec Amélia. 
  Durant l'excursion au temple, j'ai une vision d'Amélia, une aura blanche avec une boule sombre à l'intérieure.
  Rien de rassurant, nous repartons fissa voir nos compagnons.
  En arrivant, Démitrius dort et Decim et Amélia discutent.
  Nous apprenons qu'Amélia est une enfant-réceptacle, qu'elle connait Trémen, le magicien de la chaumière,
  qui mène des expériences douteuses dont actuellement sur une dryade
  et qu'elle a pour intension de récupérer un artéfact dans les ruines non loin du village (grande passion de Decim après les conquêtes).
  Finalement, tout le monde part se reposer. Kasyade invoque un chien pour monter la garde.
  Ce dernier nous réveille et nous apercevons Amélia sortir de la maison en pleine nuit.
  Nous la suivons discrètement dans le village, puis la forêt jusqu'aux fameuses ruines.

  *Ruines*

	Ces ruines ne nous rappellent rien. Leur architectures remontent plus loin que celles que nous connaissons.
  La végétation a repris le dessus. Ce bâtiment de jour ne doit pas inspirer confiance mais alors de nuit, c'est encore pire.
  Dans tous les cas, les traces nous ont amenées jusqu'ici. Nous n'avons pas d'autres choix que d'avancer.
  J'enroule les cristaux de feu au niveau de mes poings, on ne sait jamais ce qu'il pourrait se passer là-dessous.
  En entrant, nous allumons chacun une torche. Il fait très sombre. Nous arrivons à une première salle. Elle est assez spacieuse.
  Trois arbres sont positionnés en triangle autour d'une personne sur le sol. Il fallait s'en douter, il s'agit d'Amélia.
  Elle est entourée de lianes et semble endormie. Les trois arbres bougent légèrement. Je m'avance et commence à leur parler.
  Finalement, tout le groupe saisit l'occasion pour apporter des détails concernant la conversation avec les dryades.
  Si on peut esquiver un combat perdu d'avance, autant le faire. Nous proposons un marché avec elles :
  donner des informations sur le ravisseur qui a enlevé leur sœur contre Amélia. Elles ont l'air d'accepter.
  Kasyade en profite pour leur indiquer la position de la chaumière de Trémen (aka le proprio de la chaumière étrange) avec son oiseau.
  L'un des arbres disparait et revient quelques temps après accompagné de l'oiseau.
  Elles libèrent Amélia mais nous préférons qu'elles la gardent endormie le temps qu'on finisse d'explorer les ruines.
  Elles acceptent et nous ouvrent le passage vers une autre salle.
  Dans cette dernière, des fresques sont peintes.
  Elles représentent différents éléments de la création du monde et d'une guerre entre les serviteurs d'Arlenor.
  Sur la première, on peut voir Arlénor qui laisse le monde qu'il vient de créer à deux groupes,
  20 personnes au total, 10 anges et 10 démons (figures en noir et blanc).
  Sur la deuxième, une guerre éclatent entre deux groupes (10 vs 10). Chaque groupe étant constitué de figures blanches et noires. 
  Sur la troisième, un ange se désagrègent en tombant vers le bas, un ange se retrouve en haut de la fresque
  et les 18 personnages de l'histoire sont enfermés dans des cristaux.
`);

export const journalGabriel03 = convertMarkdownToHTML(`
  **Scénario 3 : Les Mines de Jirakan**

  *En cours d'écriture...*
`);

export const journalGabriel04 = convertMarkdownToHTML(`
  **Scénario 4 : La Lucifaniel**
  
  Cela fait quelques jours que nous sommes montés à bord du BAMM (Bateau à Moteur Magique).

  Nous nous sommes bien habitué aux lieu, et par réflexe ce matin nous nous sommes dirigés vers le bar du bateau.
  Nous y avons retrouvé Joffrey, le beau barman qui est également un enfant réceptacle habité par la divinité du partage.

  Il nous indique que Erick et Anabeth nous convient à une réunion, sûrement signe de nouveaux ennuis.

  On rechigne à y aller mais on ne nous laisse pas le choix.
  Nous y retrouvons tout le monde : Joffrey, Cléofée, Magnus, Zelana, Jessica, Amelia et Lysandre.

  On gueule un peu pour avoir de l'argent de poche (qu'on obtient) puis ils nous annoncent que la prochaine mission est une mission repos !!!
  Nous allons à la fête de Lucifugus organisé par la troupe du cirque.
  On pourra y croiser une autre enfant réceptacle mais le but n'est pas de la recruter, seulement de s'amuser pour une fois.

  On débat entre nous sur qui emporter avec nous au cirque devant les autres,
  le choix s'arrêtant sur Cleofée, Magnus, Joffrey, Zelana et Jessica.

  Nous arrivons en milieu d'après midi, le grand spectacle ne commençant que le soir nous nous décidons à faire le tour des animations.

  On commence par une mare aux canards avec cleofée tandis que Kasyade et Demitrius se sont essayé au stand de tir et de magie.

  Onfait le tour pour s'acheter des babioles puis on nous propose de participer à un concours
  le lendemain contre les troupes du cirque et des naines triplés siamoises.

  On s'est fait démarcher par une astrologue avec qui Kasyade s'est super bien entendue,
  nous dévoilant ses propres compétences pour lire dans les étoiles.

  Le temps d'inscrire nos équipes, le soleil se couche et vient l'heure du spectacle.
  Ils ont des acrobates, des la ceurs de couteaux, des dompteurs de bêtes sauvages,... Et notamment Melody qui était sublime !
  Dynn et Kasyade ont été enchantés par le spectacle, mais pas Demetrius et moi qui avons trouvé que le spectacle semblait un peu trop "charmant"
  
  On va ensuite vers la roulotte de la diseuse de bonne aventure pour avoir des prédictions sur notre avenir.
  C'était le bordel, entre des séances de divinations écourtées, des bourrasques de vent soudains,
  des pénis dessinés et des mains volés mais retrouvés.

  On a ensuite eu un moment de Team Building où on s'est tous dit la vérité,
  et on est ressorti encore plus pote que je ne pouvais l'imaginer c'était top !!!!
  Et comme de bons copains, on s'est tous retrouvé à poil aux bains du cirque.

  Kasyade a eu un moment spécial avec Joffrey que j'ai récupéré par la suite,
  Magnus était avec Dynn et Demettrius était là, il se repose tranquille.
  On rentre tous dormir et le lendemain matin on se retrouve autour d'une bonne bière du matin.
  Le retour fût compliqué pour Kasyade qui s'était endormi dehors mais aucun soucis.

  J'ai reçu une lettre mystérieuse que je décide d'ouvrir à l'écart plus tard,
   pensant qu'il s'agit d'un message d'un énième amant dont je ne souhaite pas que les sentiments
   soient connus des autres par respect à son égard. (Flemme de lire tout de suite)

  C'est l'heure du grand concours avec comme équipes :
  Les Pffff (dynn, Magnus, zelana)
  Les BG Mécheux (Joffrey, Demetrius, Kasyade)
  Les Queens (decim, Jessica, Cleofée)
  Les Multifaces (Thalios, Melionas, Arlequine)
  Les Baladeurs (Pedro, Melody, Keith)
  Les Nain-phètes (les 3naines siamoises)

  Le concours se déroule sans magie grâce à des bloqueurs (Dynn perd la main lol).

  La course était épique. Pendant que je bouffais le sol pendant 20ans, les multi faces ont gagné ex-aequo avec les baladeurs, suivi des Pff

  Nous devons ensuite choisir de participer à 3 épreuves sur 4 entre:
  Lancer de dague
  Tir magique
  Création de potion
  Marteau

  (Je ne sais plus les résultats finaux)

  On doit ensuite réfléchir à une prestation que l'on doit faire le lendemain pour l'épreuve finale.
  On réfléchit à tricher mais les autres pensent que ce n'est pas "éthique"
  On doit tout donner pour gagner une pierre qui permet d'exaucer un souhait (c'est le sentiment qui s'en dégage de la pierre)

  Le soir on se sépare .
  Pendant que Kasyade, Magnus, Dynn, Demetrius, Joffrey, et Zelana vont aux bains,
  Cleofée, Jessica et moi allons au spectacle.

  Mais c'est alors que se produit un Clifhanger pour tous ceux qui n'étaient pas au spectacle....
`);