<div class="encyclo-jirakan-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Jirakan et le Désert de Sanderten">
      <img class="encyclopedia-img" [src]="citeJirakanImage" alt="La Cité de Jirakan" />
      <div class="layout-bloc" [innerHTML]="citeJirakanResume"></div>
      <div class="layout-bloc margin-top-1" [innerHTML]="citeJirakanDetails"></div>
    </mat-tab>
    <mat-tab label="La Grande Révolte">
      <img class="encyclopedia-img" [src]="grandeRevolteImage" alt="La Grande Révolte" />
      <div class="layout-bloc" [innerHTML]="grandeRevolteResume"></div>
    </mat-tab>
  </mat-tab-group>
</div>