import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'expand-bloc',
  templateUrl: './expand-bloc.component.html',
  styleUrls: ['./expand-bloc.component.scss'],
})
export class ExpandBlocComponent {
  @Input() public blocTitle: string;
  @Input() public blocState: boolean;
  @Output() public outToggle = new EventEmitter<null>();

  public toggleState() {
    this.outToggle.emit();
  }
}