import { Component } from '@angular/core';

import { PageTitles } from '../../../models/PagesTitles';

@Component({
  selector: 'academy-view',
  templateUrl: './academy.view.html',
  styleUrls: ['./academy.view.scss'],
})
export class AcademyView {
  public title = PageTitles.academy;
  public imageLeft = 'assets/images_alpha/religion/angel.png';
  public imageRight = 'assets/images_alpha/religion/demon.png';
}
