<mat-tab-group animationDuration="0ms" class="margin-top-1">
  <mat-tab label="La Liste des Classes">
    <div class="layout-bloc" [innerHtml]="systemCrystalsText01"></div>

    <expand-bloc *ngFor="let role of allRoles" [blocTitle]="'Les ' + role.name + 's'"
      [blocState]="roleChoice === role.code" (outToggle)="changeRoleChoice(role.code)">
      <div class="grid-list grid-2">
        <div *ngFor="let spe of getSpecialities(role.code)" class="grid-element">
          <img [src]="spe.image" [alt]="spe.name">
          <div>
            <div class="grid-header">
              <span class="text-bold" (click)="changeSpe(spe.code)">
                {{ spe.name }}
              </span>
              <i [class]="'margin-left-05 ' + spe.role.icon + ' text-' + spe.color" [title]="spe.role.name"></i>
            </div>
            <p class="grid-body">{{ spe.description }}</p>
            <div class="margin-top-05 margin-bottom-05">
              <arrow-button linkName="En savoir plus" linkPage="/jeu-de-roles/cristaux"
                [linkParams]="{ spe: spe.code }">
              </arrow-button>
            </div>
          </div>
        </div>
      </div>
    </expand-bloc>
  </mat-tab>
  <mat-tab label="Les Pouvoirs et leurs Propriétés">
    <div class="layout-bloc" [innerHtml]="systemCrystalsText02"></div>
  </mat-tab>
</mat-tab-group>