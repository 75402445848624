<creation-form formTitle="Choix de l'identité" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form">
      <div class="margin-bottom-1">
        <div>Avatar du personnage</div>
        <label for="avatar" class="creation-avatar margin-top-05">
          <span *ngIf="!form.controls.avatar.value">Importer une image<br>(ratio 1:1 | JPG, PNG | max 1 Mo)</span>
          <img *ngIf="form.controls.avatar.value" alt="Avatar du personnage"
            [src]="getProfil(form.controls.avatar.value)">
        </label>
        <input type="file" name="avatar" id="avatar" accept="image/png, image/jpeg" (change)="changeAvatar($event)">
      </div>

      <div class="semi-width">
        <mat-form-field>
          <mat-label>Nom du personnage</mat-label>
          <input type="text" matInput maxlength="25" formControlName="name" />
        </mat-form-field>

        <div class="layout-bloc is-warning text-left margin-top-1">
          Dans la campagne de JDR / l'Aventure, les personnages doivent commencer
          avec un âge compris entre 20 et 30 ans (pour le bien être scénaristique).
        </div>

        <div class="layout-row layout-col-mob margin-top-1">
          <mat-form-field>
            <mat-label>Âge</mat-label>
            <input type="number" matInput min="1" max="200" formControlName="age" placeholder="1-200" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Genre du personnage</mat-label>
            <input type="text" matInput maxlength="12" formControlName="gender"
              placeholder="Masculin, féminin, indéterminé (il, elle, iel)..." />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</creation-form>