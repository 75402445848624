<creation-form formTitle="Choix des capacités" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form" class="semi-width margin-bottom-1">
      <mat-form-field>
        <mat-label>Filtre sur une classe</mat-label>
        <mat-select formControlName="codeSpeciality">
          <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code">
            {{ spe.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="layout-bloc semi-width margin-top-1">
      Ici, le choix d'une classe permet juste de filtrer l'affichage des pouvoirs / capacités magiques pour les
      parcourir. La créature en peut avoir jusqu'à 5, mais c'est pas obligatoire d'en avoir 5.
    </div>

    <!--div class="semi-width margin-top-1">
      <button type="button" mat-stroked-button color="primary" [disabled]="!selectedSpeciality"
        (click)="openSpePopup()">
        En savoir plus
      </button>
    </div-->

    <div class="creation-tables margin-top-1">
      <powers-selection-table [indexCrystal]="0" [idsPowers]="idsPowers" [powers]="filteredPowers"
        (outAdd)="addPower($event)" (outRemove)="removePower($event)">
      </powers-selection-table>
    </div>

    <div class="layout-row layout-col-mob margin-top-1">
      <div *ngIf="!form.controls.isNbPowersValid.value" class="layout-bloc semi-width">
        Vous pouvez encore sélectionner
        <b>
          {{
          (5 - idsPowers.length) > 1 ?
          '' + (5 - idsPowers.length) + ' capacités magiques'
          : '1 capacité magique'
          }},
        </b>
        toute classe confondue.
      </div>
      <div *ngIf="form.controls.isNbPowersValid.value" class="layout-bloc semi-width">
        Le maximum de capacités magiques a été atteint.
      </div>
    </div>

    <div *ngIf="idsPowers.length" class="creation-tables margin-top-1">
      <powers-selection-table [indexCrystal]="0" [idsPowers]="idsPowers" [powers]="selectedPowers"
        (outAdd)="addPower($event)" (outRemove)="removePower($event)">
      </powers-selection-table>
    </div>
  </div>
</creation-form>