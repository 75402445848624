<div class="layout-bloc margin-top-1" [innerHtml]="systemDifficultiesText0A"></div>

<div class="layout-bloc margin-top-1" [innerHtml]="systemDifficultiesText0B"></div>

<div class="layout-bloc margin-top-1" [innerHtml]="systemDifficultiesText0C"></div>

<expand-bloc blocTitle="Exemple n°1" [blocState]="exampleChoice === 1" (outToggle)="changeExampleChoice(1)">
  <div [innerHtml]="systemDifficultiesText01"></div>
</expand-bloc>

<expand-bloc blocTitle="Exemple n°2" [blocState]="exampleChoice === 2" (outToggle)="changeExampleChoice(2)">
  <div [innerHtml]="systemDifficultiesText02"></div>
</expand-bloc>