<h3>Description de la classe</h3>
<mat-dialog-content class="mat-typography speciality-modal">
  <div>
    <img class="speciality-img" [src]="speciality.image" [alt]="speciality.name">
    <h2>{{ speciality.name }}</h2>
    <p>{{ speciality.description }}</p>
  </div>

  <div *ngIf="archetype01">
    <h4>Archétype : "{{ archetype01.name }}"</h4>
    <p>{{ archetype01.description }}</p>
  </div>

  <div *ngIf="archetype02">
    <h4>Archétype : "{{ archetype02.name }}"</h4>
    <p>{{ archetype02.description }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>