<div class="kit-ui-view bg-global">
  <div class="layout-full-view">
    <section>
      <div class="example-label">Arrow button</div>
      <div class="example-container">
        <arrow-button linkName="En savoir plus" linkPage="/kit-ui"></arrow-button>
      </div>
    </section>

    <section>
      <div class="example-label">Mat-button</div>
      <div class="example-container">
        <button type="button" mat-button>Basic</button>
        <button type="button" mat-button color="primary">Primary</button>
        <button type="button" mat-button color="warn">Warn</button>
        <button type="button" mat-button disabled>Disabled</button>
        <a mat-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </section>

    <section>
      <div class="example-label">Mat-stroked-button</div>
      <div class="example-container">
        <button type="button" mat-stroked-button>Basic</button>
        <button type="button" mat-stroked-button color="primary">Primary</button>
        <button type="button" mat-stroked-button color="warn">Warn</button>
        <button type="button" mat-stroked-button disabled>Disabled</button>
        <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </section>

    <section>
      <div class="example-label">Buttons with icons</div>
      <div class="example-container">
        <button type="button" mat-stroked-button>
          <i class="icon-folder-upload"></i>
          Basic
        </button>
        <button type="button" mat-stroked-button color="primary">
          <i class="icon-folder-upload"></i>
          Primary
        </button>
        <button type="button" mat-stroked-button color="warn">
          <i class="icon-folder-upload"></i>
          Warn
        </button>
        <button type="button" mat-stroked-button disabled>
          <i class="icon-folder-upload"></i>
          Disabled
        </button>
        <a mat-stroked-button href="https://www.google.com/" target="_blank">
          <i class="icon-folder-upload"></i>
          Link
        </a>
      </div>
    </section>

    <section>
      <div class="example-label">Buttons</div>
      <div class="example-container">
        <button type="button" mat-icon-button>
          <i class="icon-folder-upload"></i>
        </button>
        <button type="button" mat-icon-button color="primary">
          <i class="icon-folder-upload"></i>
        </button>
        <button type="button" mat-icon-button color="warn">
          <i class="icon-folder-upload"></i>
        </button>
        <button type="button" mat-icon-button disabled>
          <i class="icon-folder-upload"></i>
        </button>
      </div>
    </section>

    <section>
      <div class="example-label">Autocomplete</div>
      <div class="example-container">
        <form>
          <mat-form-field>
            <mat-label>Label autocomplete</mat-label>
            <input type="text" matInput [formControl]="autoControl" [matAutocomplete]="auto" autocomplete="off"
              placeholder="Placeholder autocomplete" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of autoFilteredOptions" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </section>

    <section>
      <div class="example-label">Checkboxes</div>
      <div class="example-container">
        <form>
          <mat-checkbox [formControl]="checkControl">
            Checkbox
          </mat-checkbox>
          <mat-checkbox disabled="true">
            Checkbox disabled
          </mat-checkbox>
        </form>
      </div>
    </section>

    <section>
      <div class="example-label">Stepper</div>
      <div class="example-container">
        <mat-stepper orientation="vertical" #stepper>
          <mat-step [stepControl]="firstFormGroup" label="Step 1">
            <form [formGroup]="firstFormGroup">
              <div>Step content 1</div>
              <div>
                <button type="button" mat-stroked-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup" label="Step 2">
            <form [formGroup]="secondFormGroup">
              <div>Step content 2</div>
              <div>
                <button type="button" mat-stroked-button color="warn" matStepperPrevious>Back</button>
                <button type="button" mat-stroked-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step label="Step 3">
            <div>Step content 3</div>
            <div>
              <button type="button" mat-stroked-button color="warn" matStepperPrevious>Back</button>
              <button type="button" mat-stroked-button color="primary" (click)="stepper.reset()">Check</button>
            </div>
          </mat-step>

          <ng-template matStepperIcon="number">
            <i class="icon-pencil"></i>
          </ng-template>
          <ng-template matStepperIcon="edit">
            <i class="icon-pencil"></i>
          </ng-template>
          <ng-template matStepperIcon="done">
            <i class="icon-heart"></i>
          </ng-template>
          <ng-template matStepperIcon="error">
            <i class="icon-hour-glass"></i>
          </ng-template>
        </mat-stepper>
      </div>
    </section>

    <section>
      <div class="example-label">Inputs / Textarea</div>
      <div class="example-container">
        <form>
          <mat-form-field>
            <mat-label>Label text</mat-label>
            <input type="text" matInput [formControl]="textControl" placeholder="Placeholder text" />
          </mat-form-field>
        </form>
        <form>
          <mat-form-field>
            <mat-label>Label number</mat-label>
            <input type="number" matInput [formControl]="numberControl" placeholder="Placeholder number" />
          </mat-form-field>
        </form>
        <form>
          <mat-form-field>
            <mat-label>Label textarea</mat-label>
            <textarea matInput [formControl]="areaControl" placeholder="Placeholder textarea"></textarea>
          </mat-form-field>
        </form>
      </div>
    </section>

    <section>
      <div class="example-label">Spinner</div>
      <div class="example-container">
        <mat-spinner></mat-spinner>
      </div>
    </section>

    <section>
      <div class="example-label">Radio buttons</div>
      <div class="example-container">
        <mat-radio-group [formControl]="radioControl">
          <mat-radio-button *ngFor="let option of radioOptions" [value]="option">{{option}}</mat-radio-button>
          <mat-radio-button disabled>Disabled</mat-radio-button>
        </mat-radio-group>
      </div>
    </section>

    <section>
      <div class="example-label">Select</div>
      <div class="example-container">
        <form>
          <mat-form-field>
            <mat-label>Label select</mat-label>
            <mat-select [formControl]="selectControl">
              <mat-option *ngFor="let option of selectOptions" [value]="option">{{option}}</mat-option>
              <mat-option disabled>Disabled</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </section>

    <section>
      <div class="example-label">Slider</div>
      <div class="example-container">
        <form>
          <mat-slider [min]="0" [step]="1" [max]="10" [showTickMarks]="true">
            <input type="range" matSliderThumb [formControl]="sliderControl">
          </mat-slider>
        </form>
      </div>
    </section>

    <section>
      <div class="example-label">Tabs</div>
      <div class="example-container">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="First">Content 1</mat-tab>
          <mat-tab label="Second">Content 2</mat-tab>
          <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
      </div>
    </section>

    <section>
      <div class="example-label">Toggle</div>
      <div class="example-container">
        <form>
          <mat-slide-toggle [formControl]="toggleControl">
            Toggle me!
          </mat-slide-toggle>
          <mat-slide-toggle [checked]="true" [disabled]="true">
            Toggle me!
          </mat-slide-toggle>
        </form>
      </div>
    </section>
  </div>
</div>