<div class="universe-view bg-universe">
  <div class="layout-view">
    <div class="universe-container">
      <div class="universe-left section">
        <img class="section-image" [src]="currentZone.image" [alt]="currentZone.name">
        <div class="section-header-buttons">
          <div class="section-button inverted" (click)="previousSelection()">
            <i class="icon-arrow-right"></i>
          </div>
          <div class="text-center margin-bottom-1">
            <h2>{{ currentZone.name }}</h2>
            <h3>{{ showCities ? currentZone.sector.city : currentZone.sector.name }}</h3>
          </div>
          <div class="section-button" (click)="nextSelection()">
            <i class="icon-arrow-right"></i>
          </div>
        </div>
        <div class="section-description">
          <span [innerHtml]="currentZone.description"></span>
        </div>
        <p *ngIf="currentZone.comment" class="section-comment margin-top-1"
          [title]="currentZone.comment + '\n - ' + currentZone.commentName">
          "{{ currentZone.comment }}"
          <br>
          - {{ currentZone.commentName }}
        </p>
      </div>
      <div class="universe-right">
        <div class="universe-title margin-bottom-1">
          <h2>{{ showCities ? currentZone.sector.city : currentZone.sector.name }}</h2>
          <h3 [class.is-hidden]="showCities">
            Climat&nbsp;:&nbsp;<span class="text-capitalize">{{ currentZone.sector.climate }}</span>
          </h3>
          <h3 [class.is-hidden]="!showCities">
            Niveau de danger&nbsp;:&nbsp;<span class="text-capitalize">{{ currentZone.sector.danger }}</span>
          </h3>
        </div>
        <div class="universe-map-section">
          <i *ngFor="let zone of allZones; let index = index" [value]="zone.code"
            class="universe-map-target icon-location mid-opacity pointer"
            [class.full-opacity]="zone.name === currentZone.name"
            [style]="{ top: zone.posTop + '%', left: zone.posLeft + '%' }" (click)="selectZone(index)"></i>
          <img [class.is-hidden]="showCities" class="universe-map layout-bloc"
            src="assets/images_filled/map/map-climates_hd.png" alt="Carte des Climats">
          <img [class.is-hidden]="!showCities" class="universe-map layout-bloc"
            src="assets/images_filled/map/map-cities_hd.png" alt="Carte des Cités">
        </div>
        <div class="universe-title margin-top-1">
          <toggle-button [text]="'Voir la version des cités et les niveaux de danger'" [value]="showCities"
            (outChange)="toggleMap($event)"></toggle-button>
        </div>
      </div>
    </div>
  </div>
</div>