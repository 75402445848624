import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorSkill, SkillTypesEnum } from '../../../models/arlenor/ArlenorSkill';

@Component({
  selector: 'skill-modal',
  templateUrl: './skill.modal.html',
  styleUrls: [],
})
export class SkillModal implements OnInit {

  public skillTypesEnum = SkillTypesEnum;
  public skill: ArlenorSkill;

  constructor(
    public dialogRef: MatDialogRef<SkillModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public ngOnInit(): void {
    this.skill = this.data;
  }
}