<div class="religion-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="layout-bloc" [innerHtml]="resume"></div>
  </head-layout>

  <div little-separator></div>

  <div class="layout-view">
    <div class="religion-list">
      <img *ngFor="let angel of allAngels"
        [src]="(currentDivinity.name === angel.name) ? angel.imageSelected : angel.image" [alt]="angel.name"
        (click)="changeSelection(angel)">
    </div>
    <div class="religion-description layout-bloc margin-top-1 margin-bottom-1">
      <h3 class="margin-top-1">{{ currentDivinity.isInversed ? "La Cabale Inversée" : "La Sainte Cabale" }}</h3>
      <h2>
        {{ currentDivinity.libNum }}<span class="text-lower">{{ currentDivinity.isInversed ? "i" : "" }}</span>
        - {{ currentDivinity.name }}, {{ currentDivinity.title }}
      </h2>
      <h3>{{ currentDivinity.symbols }}</h3>
      <p class="religion-comment">"{{ currentDivinity.comment }}"<br>- {{ currentDivinity.commentName }}</p>
      <h3>Pouvoir divin : {{ currentDivinity.powerName }}</h3>
      <p>{{ currentDivinity.power }}</p>
    </div>
    <div class="religion-list">
      <img *ngFor="let demon of allDemons"
        [src]="(currentDivinity.name === demon.name) ? demon.imageSelected : demon.image" [alt]="demon.name"
        (click)="changeSelection(demon)">
    </div>
  </div>
</div>