import { getListRaces } from '../data/ListRaces';
import { ArlenorSpecialities } from '../data/ListSpecialities';
import { ModelAPI } from '../ModelAPI';
import { ArlenorDivinity } from './ArlenorDivinity';
import { ArlenorFaction } from './ArlenorFaction';
import { ArlenorLevel } from './ArlenorLevel';
import { ArlenorRace } from './ArlenorRace';
import { ArlenorSpeciality } from './ArlenorSpeciality';

export const CURRENT_CHARACTER_VERSION = 3;

export class ArlenorCharacter extends ModelAPI {

  // Variables à sauvegarder
  public isBDD: boolean;
  public version: number;
  public numLevel: number;
  public guid: string;
  public avatar: string;
  public name: string;
  public age: number | null;
  public gender: string;

  public codeDivinity: string | null;
  public divinityPoints: number;
  public deathPoints: number;

  public caractFor: number;
  public caractHab: number;
  public caractInt: number;
  public caractTen: number;
  public caractCha: number;
  public caractMag: number;

  public codeRace: string | null;
  public idsSkills: number[];
  public codeSpeciality01: string | null;
  public idsPowers01: number[];
  public codeSpeciality02: string | null;
  public idsPowers02: number[];

  public story: string;
  public description: string;
  public traits: string;
  public codeAlly: string | null;
  public codeEnemy: string | null;

  // Variables dérivées
  public get isVersionOK(): boolean {
    return this.version === CURRENT_CHARACTER_VERSION;
  }

  public get initiative(): number {
    return this.caractHab + this.caractInt;
  }

  public get totalCaracts(): number {
    return this.caractFor
      + this.caractHab
      + this.caractInt
      + this.caractTen
      + this.caractCha
      + this.caractMag;
  }

  public get healthMax(): number {
    let bonusMalus = 0;
    if (this.codeRace) {
      const races = getListRaces();
      if (this.codeRace === races[1].code || this.codeRace === races[4].code) bonusMalus++;
    }
    if (this.caractTen > 3) bonusMalus++;
    else if (this.caractTen === 1) bonusMalus--;
    return this.level.maxHealth + bonusMalus;
  }

  public get level(): ArlenorLevel {
    if (!this.numLevel) return new ArlenorLevel(1);
    return new ArlenorLevel(this.numLevel);
  }

  public get divinity(): ArlenorDivinity | null {
    if (!this.codeDivinity) return null;
    return ArlenorDivinity.getDivinity(this.codeDivinity);
  }

  public get profil(): string | null {
    if (this.avatar && this.avatar.indexOf('data') === 0) return this.avatar;
    else if (this.avatar) return 'assets/images_filled/characters/' + this.avatar + '.png';
    else return '';
  }

  public get race(): ArlenorRace | null {
    if (!this.codeRace) return null;
    return ArlenorRace.getRace(this.codeRace);
  }

  public get speciality01(): ArlenorSpeciality | null {
    if (!this.codeSpeciality01) return null;
    return ArlenorSpecialities.getSpeciality(this.codeSpeciality01);
  }

  public get speciality02(): ArlenorSpeciality | null {
    if (!this.codeSpeciality02) return null;
    return ArlenorSpecialities.getSpeciality(this.codeSpeciality02);
  }

  public get factionAlly(): ArlenorFaction | null {
    if (!this.codeAlly) return null;
    return ArlenorFaction.getFaction(this.codeAlly);
  }

  public get factionEnemy(): ArlenorFaction | null {
    if (!this.codeEnemy) return null;
    return ArlenorFaction.getFaction(this.codeEnemy);
  }

  constructor() {
    super();
    this.isBDD = false;
    this.version = CURRENT_CHARACTER_VERSION;
    this.numLevel = 1;
    this.guid = '';
    this.avatar = '';
    this.name = '';
    this.age = null;
    this.gender = '';

    this.codeDivinity = null;
    this.divinityPoints = 0;
    this.deathPoints = 0;

    this.story = '';
    this.description = '';
    this.traits = '';
    this.codeAlly = null;
    this.codeEnemy = null;

    this.caractFor = 1;
    this.caractHab = 1;
    this.caractInt = 1;
    this.caractTen = 1;
    this.caractCha = 1;
    this.caractMag = 1;

    const races = getListRaces();
    this.codeRace = races[0].code;
    this.idsSkills = [];
    this.codeSpeciality01 = null;
    this.idsPowers01 = [];
    this.codeSpeciality02 = null;
    this.idsPowers02 = [];
  }
}
