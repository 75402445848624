import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CelestiaAnswer, CelestiaQuizz } from '../../models/celestia/CelestiaQuizz';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'celestia-question',
  templateUrl: './celestia-question.component.html',
  styleUrls: ['./celestia-question.component.scss'],
})
export class CelestiaQuestionComponent implements OnInit {
  @Input() public indexQuestion: number;
  @Output() public outNextQuestion = new EventEmitter<null>();

  public form: FormGroup;
  public quizz: CelestiaQuizz;
  public question: string;
  public answers: CelestiaAnswer[];

  constructor(private _storeService: StoreService) {
    this.form = new FormGroup({
      response: new FormControl('', Validators.required)
    });
  }

  public ngOnInit(): void {
    this.quizz = this._storeService.quizz;
    this.question = this.quizz.questions[this.indexQuestion].libelle;
    this.answers = this.quizz.questions[this.indexQuestion].answers;
  }

  public submitForm() {
    this._save();
    this.outNextQuestion.emit();
  }

  private _save() {
    const newQuizz = new CelestiaQuizz();
    newQuizz.questions[this.indexQuestion].selection = this.form.controls.response.value;
    this._storeService.changeQuizz({ index: this.indexQuestion, quizz: newQuizz });
  }
}
