<div class="character-view bg-roleplay">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <character-intro-view *ngIf="step === -1" (outNext)="increaseStep()">
    </character-intro-view>
    <character-selection-view *ngIf="step === 0" (outNext)="increaseStep()">
    </character-selection-view>
    <character-race-view *ngIf="step === 1" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-race-view>
    <character-caracts-view *ngIf="step === 2" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-caracts-view>
    <character-crystals-view *ngIf="step === 3" [indexCrystal]="0" (outPrevious)="decreaseStep()"
      (outNext)="increaseStep()">
    </character-crystals-view>
    <character-crystals-view *ngIf="step === 4 && character.numLevel >= 5" [indexCrystal]="1"
      (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-crystals-view>
    <character-skills-view *ngIf="step === 5" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-skills-view>
    <character-identity-view *ngIf="step === 7" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-identity-view>
    <character-divinity-view *ngIf="step === 8" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-divinity-view>
    <character-details-view *ngIf="step === 9" (outPrevious)="decreaseStep()" (outNext)="increaseStep()">
    </character-details-view>
    <character-final-view *ngIf="step === 10" (outPrevious)="reset($event)">
    </character-final-view>

    <ul *ngIf="step > 0 && step < 10" class="creation-steps steps-container">
      <div class="dotline"></div>
      <li [class]="{'active': step === 1 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 2 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 3, 'warning': !character.caractMag }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 4, 'warning': (!character.caractMag || character.numLevel < 5) }" class="dot">
      </li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 5 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 6, 'warning': true }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 7 }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 8, 'warning': !character.caractMag }" class="dot"></li>
      <div class="dotline"></div>
      <li [class]="{'active': step === 9 }" class="dot"></li>
      <div class="dotline"></div>
    </ul>
  </div>
</div>