import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationModal } from '../../../components/modals/confirmation/confirmation.modal';
import { PowerFormModal } from '../../../components/modals/power-form/power-form.modal';
import { ArlenorArchetype } from '../../../models/arlenor/ArlenorArchetype';
import { ArlenorPower } from '../../../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../../../models/arlenor/ArlenorSkill';
import { ArlenorSpeciality } from '../../../models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '../../../models/data/ListSpecialities';
import { ModelEnum } from '../../../models/ModelEnum';
import { PageTitles } from '../../../models/PagesTitles';
import { StoreService } from '../../../services/store.service';
import { convertMarkdownToHTML } from '../../../utils/conversions';
import supabase_api from '../../../utils/supabase_api';

@Component({
  selector: 'crystals-view',
  templateUrl: './crystals.view.html',
  styleUrls: ['./crystals.view.scss'],
})
export class CrystalsView implements OnInit {

  public invocateurText01 = invocateurText01;
  public invocateurText02 = invocateurText02;

  public title = PageTitles.crystals;
  public allArchetypes: ArlenorArchetype[];
  public allPowers: ArlenorPower[];
  public allSkills: ArlenorSkill[];

  public codeSpe: string;
  public currentSpeciality: ArlenorSpeciality;
  public allSpecialities: ArlenorSpeciality[];
  public specialityPowers: ArlenorPower[];
  public selectedPower: ArlenorPower;
  public ranks: ModelEnum[];

  private _destroyRef = inject(DestroyRef);

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  constructor(
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this._activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(params => {
        this.codeSpe = params.spe;
        if (!this.codeSpe) this.moveToSpe('GARDIEN');
        else this._updatePage();
      });

    this._storeService.$allPowers
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(value => {
        if (value) this.allPowers = value;
        this._updatePage();
      });

    this._storeService.$allSkills
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(value => {
        if (value) this.allSkills = value;
        this._updatePage();
      });

    this._storeService.$allArchetypes
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(value => {
        if (value) this.allArchetypes = value;
        this._updatePage();
      });

    this._storeService.loadAllPowers();
    this._storeService.loadAllSkills();
    this._storeService.loadAllArchetypes();
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
  }

  public moveToSpe(code: string) {
    this._router.navigate([], {
      queryParams: { spe: code },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.codeSpe = code;
    this._updatePage();
  }

  public selectPower(power: ArlenorPower | null) {
    this.selectedPower = power;
  }

  public openAddPower() {
    const dialogRef = this.dialog.open(PowerFormModal, {
      data: {
        title: `Ajout d'un pouvoir`,
        codeSpe: this.codeSpe,
        power: new ArlenorPower(),
      }
    });

    dialogRef.afterClosed().subscribe(async (value: ArlenorPower) => {
      if (value) {
        const result = await supabase_api.postPower(value);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public openModifyPower() {
    const dialogRef = this.dialog.open(PowerFormModal, {
      data: {
        title: `Modification du pouvoir`,
        codeSpe: this.codeSpe,
        power: this.selectedPower,
      }
    });

    dialogRef.afterClosed().subscribe(async (value: ArlenorPower) => {
      if (value) {
        const result = await supabase_api.putPower(value);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public openDeletePower() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression du pouvoir`,
        content: `Vous allez supprimer "` + this.selectedPower.name + `" et c'est irréversible.`,
      }
    });

    dialogRef.afterClosed().subscribe(async (value: boolean) => {
      if (value) {
        const result = await supabase_api.deletePower(this.selectedPower);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public getRankPowers(rank: ModelEnum): ArlenorPower[] {
    const powers = this.specialityPowers.filter(power => power.rank.Code === rank.Code);
    powers.sort((a, b) => b.rank.Code.localeCompare(a.rank.Code));
    return powers;
  }

  private _updatePage() {
    if (this.codeSpe) {
      const targetSpeciality = ArlenorSpecialities.getListSpecialities().find(spe => spe.code === this.codeSpe);
      this.currentSpeciality = targetSpeciality ? targetSpeciality : null;
      this.selectPower(null);
    } else {
      this.currentSpeciality = null;
    }

    if (this.currentSpeciality && this.allArchetypes && this.allSkills) {
      // Archetypes
      const archetypes = this.allArchetypes.filter(arch => arch.codeSpeciality === this.currentSpeciality?.code);
      if (this.currentSpeciality && archetypes.length > 0) {
        this.currentSpeciality.archetype01 = archetypes[0];
        const skill = this.allSkills.find(skill => skill.id === this.currentSpeciality?.archetype01?.idSkill);
        if (skill) this.currentSpeciality.archetype01.skill = skill;
      }
      if (this.currentSpeciality && archetypes.length > 1) {
        this.currentSpeciality.archetype02 = archetypes[1];
        const skill = this.allSkills.find(skill => skill.id === this.currentSpeciality?.archetype02?.idSkill);
        if (skill) this.currentSpeciality.archetype02.skill = skill;
      }
    }

    if (this.currentSpeciality && this.allPowers) {
      // Pouvoirs
      const spe = this.currentSpeciality;
      const list = spe.code ? this.allPowers.filter(power => power.speciality && power.speciality.code === spe.code) : [];
      list.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.specialityPowers = list;
      this.ranks = this.specialityPowers.map(power => power.rank).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
      this.ranks.sort((a, b) => b.Code.localeCompare(a.Code));
    } else {
      this.specialityPowers = [];
      this.ranks = [];
    }
  }
}

export const invocateurText01 = convertMarkdownToHTML(`
Les invocateurs sont des mages ayant la possibilité de faire appel à des invocations en se liant à elles.
Les invocations sont...
- soit des **créations magiques** : un pseudo-esprit est alors créé via l'imaginaire sous une forme matérielle.
- soit des **étoiles primordiales** : un esprit sous un contrat magique / spirituel, descend alors dans le monde sous une forme matérielle.

La capacité magique de l'invocateur définit son nombre d'invocations / de liens (créations et contrats confondus) :
3 en Magie = 3 invocations possibles.`);

export const invocateurText02 = convertMarkdownToHTML(`
**Les contrats avec les étoiles primordiales**

**La création de contrat**.
- Le contrat peut être créé à n'importe quel moment (même au lancement d'un sort d'invocation) tant que les deux partis sont d'accord.
- Le contrat doit être réalisé via un sort spécifique.
- C'est au Maître du Jeu d'interpréter les esprits et de savoir s'ils acceptent ou non leur contrat.
- Un esprit ne peut être lié qu'à un seul contrat à la fois : ce qui fait que les invocateurs ne peuvent pas avoir le même esprit.
- Le contrat permet d'invoquer l'esprit afin de communiquer avec lui ou de lui donner des ordres.

**La résiliation d'un contrat**.
- Le contrat peut être résilié de même à n'importe quel moment par un des deux partis.
- Les contrats rompus le sont à jamais, sans possibilité de s'y reconnecter.
- La défaite de l'esprit invoqué entraîne une perte temporaire de conscience de l'invocateur.
- La défaite de l'esprit invoqué peut entrainer la perte de leur contrat (si l'esprit n'a pas aimé cette défaite).
- Les esprits vaincus retournent sous leur forme stellaire (étoile primordiale).

**Les contrats et les sorts**.
- Un contrat peut être réalisé même si l'invocateur n'a pas la capacité (le sort) pour l'invoquer (mais c'est rare et inutile).
- La capacité d'invoquer plusieurs esprits à la fois, est liée aux choix des sorts de l'invocateur.`);