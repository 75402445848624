import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'creature-details-view',
  templateUrl: './creature-details.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureDetailsView implements OnInit {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public creature: ArlenorCreature;
  public isModified: boolean;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      story: new FormControl(null),
      description: new FormControl(null),
      traits: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.creature = this._storeService.creature;

    // Init
    this.form.controls.story.setValue(this.creature.story);
    this.form.controls.description.setValue(this.creature.description);
    this.form.controls.traits.setValue(this.creature.traits);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
      });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.story = this.form.controls.story.value;
    newCreature.description = this.form.controls.description.value;
    newCreature.traits = this.form.controls.traits.value;
    this._storeService.changeCreatureDetails(newCreature);
  }
}
