import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'kit-ui-view',
  templateUrl: './kit-ui.view.html',
  styleUrls: ['./kit-ui.view.scss'],
})
export class KitUIView implements OnInit {

  public autoControl = new FormControl<string>('');
  public autoOptions: string[] = ['Mary', 'Shelley', 'Igor'];
  public autoFilteredOptions: string[];

  public checkControl = new FormControl<boolean>(true);
  public textControl = new FormControl<string>('');
  public numberControl = new FormControl<number>(null);
  public areaControl = new FormControl<string>('');

  public radioControl = new FormControl<string>('');
  public radioOptions: string[] = ['Mary', 'Shelley', 'Igor'];

  public selectControl = new FormControl<string>('');
  public selectOptions: string[] = ['Mary', 'Shelley', 'Igor'];

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;

  public sliderControl = new FormControl<number>(null);
  public toggleControl = new FormControl<boolean>(true);

  constructor(private _formBuilder: FormBuilder) {
    this.firstFormGroup = this._formBuilder.group({});
    this.secondFormGroup = this._formBuilder.group({});
  }

  public ngOnInit() {
    this.autoControl.valueChanges.subscribe((value: string) => {
      this.autoFilteredOptions = value ? this._autoFilter(value) : this.autoOptions.slice();
    });
  }

  private _autoFilter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.autoOptions.filter(option => option.toLowerCase().includes(filterValue));
  }
}
