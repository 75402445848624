<a *ngIf="linkPage && linkParams" class="arrow-button" [routerLink]="linkPage" [queryParams]="linkParams"
  mat-stroked-button color="primary">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>

<a *ngIf="linkPage && !linkParams" class="arrow-button" [routerLink]="linkPage" mat-stroked-button color="primary">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>

<a *ngIf="linkUrl" class="arrow-button" [href]="linkUrl" mat-stroked-button color="primary" target="_blank"
  rel="noopener noreferrer">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>