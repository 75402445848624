import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-ether-land-view',
  templateUrl: './encyclo-ether-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloEtherLandView {

  public citeEtherResume = citeEtherResume;
  public citeEtherDetails = citeEtherDetails;
  public citeEtherImage = 'assets/images_filled/lands/section-ether_md.png';

}

export const citeEtherResume = convertMarkdownToHTML(`
Un climat presque tropical dans une forêt dense, un peu magique.
Le territoire est dominé par une citée cachée, celle du peuple des arléniens et des pans.
Elle est construite autour d'un arbre géant où se trouve leur divinité Nébulys.
Toute autre race est exclue de leur territoire, sous peine de mort.
Les autres races n'ont pas conscience de ce territoire, elles le considèrent juste comme dangereux.
Les arléniens et les pans sont vegans ou végétariens, respectant la nature.
Ils sont aussi dévoués à une famille de prêtres pouvant communiquer avec Nébulys.
`);

export const citeEtherDetails = convertMarkdownToHTML(`
Histoire :

- Fondé par les Arléniens et les Pans pour préserver leur mode de vie végétalien et leur connexion avec Nébulys.
- Événements clés : La découverte du Bosquet, la consolidation du culte de Nébulys.

Géographie :

- Climat tropical, forêt dense imprégnée de magie. Cité cachée autour de l'Arbre de Nébulys.

Culture et Société :

- Vie Communautaire : Forte communauté, chacun contribue à la préservation de la nature.
- Pratiques Alimentaires : Végétaliens et végétariens, respectueux de la vie naturelle.

Religion et Croyances :

- Divinité : Nébulys, vénérée comme la manifestation de la nature.
- Clergé : Famille de prêtres exclusivement dédiée à la communication avec Nébulys.

Politique :

- Système Politique : Théocratie dirigée par les prêtres de Nébulys.
- Exclusion des Autres Races : Lois strictes, peine de mort pour les intrus.

Économie :

- Autosuffisance : Ressources naturelles exploitées de manière durable.
- Commerce Restreint : Échanges limités avec l'extérieur.

Éducation et Connaissances :

- Éducation Environnementale : Apprentissage centré sur la préservation de la nature.
- Rituels Religieux : Part intégrante de l'éducation.

Technologie et Magie :

- Magie Naturelle : Utilisation magique limitée à la préservation de la nature.
- Technologie Limitée : Dépendance des ressources naturelles.

Relations Internationales :

- Isolationnisme : Le Bosquet reste discret pour éviter les interactions non désirées.
- Paix Précaire : Tensions avec les territoires voisins qui ignorent le Bosquet.

Personnages Importants :

- Prêtres de Nébulys : Détenteurs du pouvoir et guides spirituels.
- Arléniens et Pans Importants : Leaders communautaires influents.

Conflits Récents ou Imminents :

- Menaces Extérieures : Risque d'intrusions par des explorateurs ou des chercheurs de trésors.

Lieux Notables :

- Cité Cachée : Ether, construite autour de l'Arbre de Nébulys.
- Sanctuaire de Nébulys : Lieu saint réservé aux prêtres.

Événements Spéciaux :

- Cérémonies Religieuses : Festivals périodiques en l'honneur de Nébulys.

Quotidien des Citoyens :

- Routines Spirituelles : Prières matinales, méditations quotidiennes.
- Travail Collectif : Contributions à la préservation de l'environnement.

Évolution Potentielle :

- Extension de l'Influence : Expansion discrète pour préserver la paix.
- Risques Internes : Dissensions sur la gestion des ressources naturelles.
`);
