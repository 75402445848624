import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '../../../../components/modals/confirmation/confirmation.modal';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { ArlenorPower } from '../../../../models/arlenor/ArlenorPower';
import { StoreService } from '../../../../services/store.service';
import downloads_creatures from '../../../../utils/downloads_creatures';

@Component({
  selector: 'creature-selection-view',
  templateUrl: './creature-selection.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureSelectionView {

  @Output() public outNext = new EventEmitter();
  @Output() public outPrevious = new EventEmitter();

  public form: FormGroup;

  public get selectedCreature(): ArlenorCreature | null {
    if (this.form.controls.guid) {
      const creature = this.creatures.find(charact => charact.guid === this.form.controls.guid.value);
      return creature ? creature : null;
    } else return null;
  }

  public get toDelete(): boolean {
    return this.selectedCreature && (!this.selectedCreature.isVersionOK);
  }

  public get creatures(): ArlenorCreature[] {
    const allCreatures = this._storeService.$allCreatures.value || [];
    const localCreatures = this._storeService.localCreatures || [];
    return allCreatures.concat(localCreatures);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      guid: new FormControl(''),
    });
  }

  public getDetails(creature: ArlenorCreature) {
    if (creature.isVersionOK) {
      let libelle = creature.bestiary?.name;
      if (!creature.isBDD) libelle += ' (' + creature.date + ' ' + creature.hour + ')';
      return libelle;
    } else {
      return '(ancienne version : à supprimer)';
    }
  }

  public start() {
    if (this.form.controls.guid.value) {
      this._storeService.changeCreature(this.selectedCreature);
      this._storeService.restartCreature();
    } else this._storeService.resetCreature();
    this.outNext.emit();
  }

  public quit() {
    this._reset();
    this.outPrevious.emit();
  }

  public selectCreature(guid: string) {
    this.form.controls.guid.setValue(guid);
  }

  public async download(isColored: boolean) {
    if (this.selectedCreature) {
      downloads_creatures.downloadPDF(isColored, this.selectedCreature, this.allPowers);
    } else {
      downloads_creatures.downloadPDFVide(isColored);
    }
  }

  public openDeletePopup() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression de la créature`,
        content: `Souhaitez-vous vraiment supprimer la créature nommée
        <b>` + this.selectedCreature.name + `</b> ?
        <br><br>
        Cette action est irréversible.`,
      },
    });

    dialogRef.afterClosed().subscribe((value: boolean) => {
      console.log('The dialog was closed', value);
      if (value) {
        this._storeService.deleteLocalCreature(this.selectedCreature.guid);
        this._reset();
      }
    });
  }

  private _reset(): void {
    this._storeService.resetCreature();
    this.form.controls.guid.setValue('');
  }
}
