<div class="layout-bloc margin-top-1" [innerHtml]="systemStoryText"></div>

<expand-bloc blocTitle="Avatar et description physique" [blocState]="finitionChoice === 1"
  (outToggle)="changeFinitionChoice(1)">
  <div [innerHtml]="systemStoryText01"></div>
</expand-bloc>

<expand-bloc blocTitle="Trait de caractère, idéologies, croyances..." [blocState]="finitionChoice === 2"
  (outToggle)="changeFinitionChoice(2)">
  <div [innerHtml]="systemStoryText02"></div>
</expand-bloc>

<expand-bloc blocTitle="Histoire personnelle" [blocState]="finitionChoice === 3" (outToggle)="changeFinitionChoice(3)">
  <div [innerHtml]="systemStoryText03"></div>
</expand-bloc>