import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import { MaterialsModule } from '../components/materials.modules';
import { AboutView } from './about/about.view';
import { AcademyView } from './celestia/academy/academy.view';
import { CelestiaView } from './celestia/celestia.view';
import { ChronologyView } from './celestia/chronology/chronology.view';
import { HomeView } from './home/home.view';
import { KitUIView } from './kit-ui/kit-ui.view';
import { LegacyView } from './legacy/legacy.view';
import { AvatarView } from './roleplay/avatar/avatar.view';
import { CharacterFinalView } from './roleplay/character/base/character-final.view';
import { CharacterIntroView } from './roleplay/character/base/character-intro.view';
import { CharacterSelectionView } from './roleplay/character/base/character-selection.view';
import { CharacterCaractsView } from './roleplay/character/caracts/character-caracts.view';
import { CharacterView } from './roleplay/character/character.view';
import { CharacterCrystalsView } from './roleplay/character/crystals/character-crystals.view';
import { CharacterDetailsView } from './roleplay/character/details/character-details.view';
import { CharacterDivinityView } from './roleplay/character/divinity/character-divinity.view';
import { CharacterIdentityView } from './roleplay/character/identity/character-identity.view';
import { CharacterRaceView } from './roleplay/character/race/character-race.view';
import { CharacterSkillsView } from './roleplay/character/skills/character-skills.view';
import { CreatureFinalView } from './roleplay/creature/base/creature-final.view';
import { CreatureIntroView } from './roleplay/creature/base/creature-intro.view';
import { CreatureSelectionView } from './roleplay/creature/base/creature-selection.view';
import { CreatureCaractsView } from './roleplay/creature/caracts/creature-caracts.view';
import { CreatureView } from './roleplay/creature/creature.view';
import { CreatureCrystalsView } from './roleplay/creature/crystals/creature-crystals.view';
import { CreatureDetailsView } from './roleplay/creature/details/creature-details.view';
import { CreatureIdentityView } from './roleplay/creature/identity/creature-identity.view';
import { CrystalsView } from './roleplay/crystals/crystals.view';
import { RoleplayView } from './roleplay/roleplay.view';
import { SystemCaractsView } from './roleplay/system/perso/system-caracts.view';
import { SystemCrystalsView } from './roleplay/system/perso/system-crystals.view';
import { SystemRacesView } from './roleplay/system/perso/system-races.view';
import { SystemSkillsView } from './roleplay/system/perso/system-skills.view';
import { SystemStoryView } from './roleplay/system/perso/system-story.view';
import { SystemStuffsView } from './roleplay/system/perso/system-stuffs.view';
import { SystemAlterationsView } from './roleplay/system/rules/system-alterations.view';
import { SystemDifficultiesView } from './roleplay/system/rules/system-difficulties.view';
import { SystemDivineView } from './roleplay/system/rules/system-divine.view';
import { SystemExperienceView } from './roleplay/system/rules/system-experience.view';
import { SystemFightView } from './roleplay/system/rules/system-fight.view';
import { SystemResultsView } from './roleplay/system/rules/system-results.view';
import { SystemView } from './roleplay/system/system.view';
import { CalendarView } from './universe/calendar/calendar.view';
import { EncycloCoalitionView } from './universe/encyclopedia/adventure/encyclo-coalition.view';
import { EncycloDivineView } from './universe/encyclopedia/adventure/encyclo-divine.view';
import { EncycloJournauxView } from './universe/encyclopedia/adventure/encyclo-journaux.view';
import { EncycloSocieteEveilView } from './universe/encyclopedia/adventure/encyclo-societe-eveil.view';
import { EncycloBestiaryView } from './universe/encyclopedia/bestiary/encyclo-bestiary.view';
import { EncyclopediaView } from './universe/encyclopedia/encyclopedia.view';
import { EncycloFactionsView } from './universe/encyclopedia/factions/encyclo-factions.view';
import { EncycloAtlanticaLandView } from './universe/encyclopedia/lands/encyclo-atlantica-land.view';
import { EncycloDakarosLandView } from './universe/encyclopedia/lands/encyclo-dakaros-land.view';
import { EncycloEtherLandView } from './universe/encyclopedia/lands/encyclo-ether-land.view';
import { EncycloFaradelLandView } from './universe/encyclopedia/lands/encyclo-faradel-land.view';
import { EncycloJirakanLandView } from './universe/encyclopedia/lands/encyclo-jirakan-land.view';
import { EncycloNoLandView } from './universe/encyclopedia/lands/encyclo-no-land.view';
import { EncycloSomniLandView } from './universe/encyclopedia/lands/encyclo-somni-land.view';
import { MagicView } from './universe/magic/magic.view';
import { RacesView } from './universe/races/races.view';
import { ReligionView } from './universe/religion/religion.view';
import { UniverseView } from './universe/universe.view';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MaterialsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AboutView,
    AcademyView,
    AvatarView,
    HomeView,
    CalendarView,
    CelestiaView,
    ChronologyView,
    CharacterView,
    CharacterCaractsView,
    CharacterCrystalsView,
    CharacterDetailsView,
    CharacterDivinityView,
    CharacterFinalView,
    CharacterIdentityView,
    CharacterIntroView,
    CharacterRaceView,
    CharacterSelectionView,
    CharacterSkillsView,
    CreatureView,
    CreatureCaractsView,
    CreatureCrystalsView,
    CreatureDetailsView,
    CreatureFinalView,
    CreatureIdentityView,
    CreatureIntroView,
    CreatureSelectionView,
    CrystalsView,
    EncyclopediaView,
    EncycloAtlanticaLandView,
    EncycloBestiaryView,
    EncycloDakarosLandView,
    EncycloDivineView,
    EncycloCoalitionView,
    EncycloEtherLandView,
    EncycloFactionsView,
    EncycloFaradelLandView,
    EncycloJirakanLandView,
    EncycloJournauxView,
    EncycloNoLandView,
    EncycloSocieteEveilView,
    EncycloSomniLandView,
    KitUIView,
    LegacyView,
    MagicView,
    RacesView,
    ReligionView,
    RoleplayView,
    SystemView,
    SystemAlterationsView,
    SystemCaractsView,
    SystemCrystalsView,
    SystemDifficultiesView,
    SystemDivineView,
    SystemExperienceView,
    SystemFightView,
    SystemRacesView,
    SystemResultsView,
    SystemSkillsView,
    SystemStoryView,
    SystemStuffsView,
    UniverseView,
  ]
})
export class ViewsModule {}
