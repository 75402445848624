import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorArchetype } from '../../../models/arlenor/ArlenorArchetype';
import { ArlenorSpeciality } from '../../../models/arlenor/ArlenorSpeciality';
import { CaractDescriptionEnum } from '../../../models/ModelEnum';

@Component({
  selector: 'speciality-modal',
  templateUrl: './speciality.modal.html',
  styleUrls: ['./speciality.modal.scss'],
})
export class SpecialityModal implements OnInit {

  public caractDescriptionEnum = CaractDescriptionEnum;

  public speciality: ArlenorSpeciality;
  public archetype01: ArlenorArchetype;
  public archetype02: ArlenorArchetype;

  constructor(
    public dialogRef: MatDialogRef<SpecialityModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public ngOnInit(): void {
    this.speciality = this.data.speciality;
    this.archetype01 = this.data.archetypes.length > 0 ? this.data.archetypes[0] : null;
    this.archetype02 = this.data.archetypes.length > 1 ? this.data.archetypes[1] : null;
  }
}