<table *ngFor="let type of types" mat-table [dataSource]="dataSources[type.Code]">
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef colspan="3" class="col-100">
      <div class="table-separator">
        <span class="table-separator-line"></span>
        <span class="table-separator-title">
          {{ type.Libelle }}
        </span>
        <span class="table-separator-line"></span>
      </div>
    </th>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef class="col-20"></th>
    <td mat-cell *matCellDef="let element" class="col-20 pointer" (click)="seeMore(element)">
      <div class="power-img-layout" [title]="element.type.Libelle">
        <img *ngIf="element.image" class="power-img" [src]="element.image" [alt]="element.type.Libelle">
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="col-60"></th>
    <td mat-cell *matCellDef="let element" class="col-60 pointer" (click)="seeMore(element)">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef class="col-20"></th>
    <td mat-cell *matCellDef="let element" class="col-20">
      <mat-checkbox [checked]="checkSkill(element)" color="primary" [disabled]="checkDisabled(element)"
        (change)="changeSkill($event.checked, element)"></mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['type']"></tr>
  <tr mat-row *matRowDef=" let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="skills.length === 0" class="layout-bloc is-info text-center">Aucune compétence.</div>