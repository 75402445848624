import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { RaceModal } from '../../../../components/modals/race/race.modal';
import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorRace } from '../../../../models/arlenor/ArlenorRace';
import { ArlenorSkill } from '../../../../models/arlenor/ArlenorSkill';
import { getListRaces } from '../../../../models/data/ListRaces';
import { DifficultyEnum } from '../../../../models/ModelEnum';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'character-race-view',
  templateUrl: './character-race.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterRaceView implements OnInit {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public allRaces: ArlenorRace[];
  public isModified: boolean;

  private _destroyRef = inject(DestroyRef);

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get currentRace(): ArlenorRace | null {
    const race = this.allRaces.find(race => race.code === this.form.controls.raceCode.value);
    return race ? race : null;
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      raceCode: new FormControl(null, Validators.required)
    });
  }

  public ngOnInit() {
    this.allRaces = getListRaces().filter(race => race.difficulty !== DifficultyEnum.Impossible.Code);

    // Init
    this.form.controls.raceCode.setValue(this._storeService.character.codeRace || this.allRaces[0].code);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
      });
  }

  public getSkills(raceCode: string) {
    const skills = this.allSkills.filter(skill => skill.race && skill.race.code === raceCode);
    while (skills.length < 4) skills.push({ name: '-', description: '-' } as ArlenorSkill);
    return skills;
  }

  public openRacePopup() {
    const dialogRef = this.dialog.open(RaceModal, {
      data: this.currentRace,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit();
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.codeRace = this.form.controls.raceCode.value;
    this._storeService.changeCharacterRace(newCharacter);
  }
}
