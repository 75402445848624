import { Component } from '@angular/core';

import { PageTitles } from '../../../models/PagesTitles';

@Component({
  selector: 'chronology-view',
  templateUrl: './chronology.view.html',
  styleUrls: ['./chronology.view.scss'],
})
export class ChronologyView {
  public title = PageTitles.chronology;
  public imageLeft = 'assets/images_alpha/religion/angel.png';
  public imageRight = 'assets/images_alpha/religion/demon.png';
}
