import { Component, Input } from '@angular/core';

@Component({
  selector: 'head-layout',
  templateUrl: './head-layout.component.html',
  styleUrls: ['./head-layout.component.scss'],
})
export class HeadLayoutComponent {
  @Input() public headTitle: string;
  @Input() public background: string;
  @Input() public imageLeft: string;
  @Input() public imageRight: string;
}
