import { Component, Input } from '@angular/core';

@Component({
  selector: 'arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
})
export class ArrowButtonComponent {
  @Input() public linkName: string;
  @Input() public linkPage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public linkParams: any;
  @Input() public linkUrl: string;
}
