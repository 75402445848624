import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-somni-land-view',
  templateUrl: './encyclo-somni-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloSomniLandView {

  public citeSomniResume = citeSomniResume;
  public citeSomniDetails = citeSomniDetails;
  public citeSomniImage = 'assets/images_filled/lands/section-somni_md.png';

}

export const citeSomniResume = convertMarkdownToHTML(`
Refuge des Cristallins exilés, les Terres Oubliées sont un royaume gelé, éloigné de l'influence d'Arlénor.
Les Cristallins, des êtres immortels mais fragiles, survivent grâce aux cristaux magiques qui composent leur corps.
Ces créatures, hostiles à la religion d'Arlénor, ont adopté un mode de vie apathique dans l'isolement,
se rassemblant périodiquement pour échanger des fragments de mémoire.
Leur gouvernement anarchique est caractérisé par des discussions informelles sur les ressources
et les menaces lors de rassemblements temporaires. Les Cristallins, engourdis pendant la nuit permanente,
cherchent constamment de nouveaux cristaux pour maintenir leur immortalité fragile.
Stagnant dans une stagnation temporelle, les Terres Oubliées résistent au changement,
explorant occasionnellement les environs à la recherche de nouvelles sources de pouvoir cristallin.
`);

export const citeSomniDetails = convertMarkdownToHTML(`
Histoire :

Origines : Terres ancestrales des Cristallins, créés par des êtres divins déchus.
Événements Majeurs : Découverte de la Cité des Fragments, lieu de rassemblement des Cristallins.
Géographie :

Contraste : Des étendues glacées et une Cité des Fragments animée.
Mobilité : Nomadisme des Cristallins, qui hibernent pendant l'hiver.
Culture et Société :

Anarchie Vivante : Gouvernement tribal avec une Cité des Fragments comme lieu de convergence.
Résilience Cristalline : La fragilité physique de certains Cristallins forge une communauté solidaire.
Politique :

Leadership Décentralisé : Absence de dirigeants permanents, les Cristallins se réunissent à la Cité des Fragments.
Hibernation Hivernale : La plupart des Cristallins entrent en dormance pendant l'hiver.
Économie :

Échange de Connaissances : La Cité des Fragments devient le marché des fragments de mémoire et des connaissances cristallines.
Ressources Nomades : La mobilité des Cristallins les amène à explorer de nouvelles régions.
Religion et Croyances :

Défiance Religieuse : Rejet de la religion d'Arlénor, focalisation sur les mystères cristallins.
Culte de l'Équilibre : L'hibernation hivernale est vue comme un cycle naturel d'équilibre.
Menaces et Enjeux :

Isolement Hivernal : Défis pour la survie pendant l'hibernation.
Intrusions Extérieures : Risque d'exploitation par d'autres peuples pendant l'hibernation.
Technologie et Magie :

Utilisation Limitée : La magie est principalement utilisée pour préserver la Cité des Fragments.
Artisanat Cristallin : Création d'artefacts magiques en cristal pendant l'hibernation.
Éducation et Connaissances :

Échange Continuel : Transmission des fragments de mémoire et des connaissances à la Cité des Fragments.
Exploration Éducative : Les Cristallins découvrent de nouvelles régions lors de leurs périodes nomades.
Personnages Importants :

Gardien de la Cité : Protecteur de la Cité des Fragments pendant l'hibernation.
Sage Itinérant : Cristallin expert dans la préservation des fragments de mémoire.
Conflits Récents ou Imminents :

Conflits Internes : Tensions lors des rassemblements à la Cité des Fragments.
Exploration Périlleuse : Risques liés à la découverte de nouvelles régions pendant la période nomade.
Lieux Notables :

Cité des Fragments : Centre vivant de la culture et de l'échange cristallins.
Terres de l'Éveil : Régions explorées pendant la période nomade.
Événements Spéciaux :

Festival de l'Éveil : Célébration à la Cité des Fragments au début du printemps.
Veille Hivernale : Période d'hibernation marquant la fin de l'automne.
`);
