<div class="celestia-view bg-celestia">
  <head-layout [headTitle]="title" background="bg-celestia-spe">
    <iframe src="https://www.youtube.com/embed/Plk1gRTv4jA"
      title="Trailer - Etincelles : Premières étincelles de Célestia" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    <div class="layout-bloc">
      Sur une île céleste, éloignée de tout le reste... sans la religion d'Arlénor, sans les cristaux habituels...
      Un petit peuple se développait tranquillement jusqu'à l'arrivée des <b>Wendigos</b> dans leur vie.
      Des célestiens sont devenus des mages en même temps que leur apparition, et entre eux se mène un combat de
      longue durée...
      <br><br>
      Un jeune groupe de mages s'entraine actuellement afin d'affronter leur <b>Destin</b>.<br>
      Et l'une d'entre eux, <b>Elisa Felnéris</b>, semble avoir la clé pour mettre fin à ces tueries.
    </div>
  </head-layout>

  <div little-separator></div>

  <div class="layout-view bg-black">
    <div class="celestia-container">
      <div class="celestia-left">
        <img class="celestia-character layout-bloc" [src]="currentCelestia.image"
          [alt]="'Illustration de' + currentCelestia.firstname + ' ' + currentCelestia.lastname">
      </div>

      <div class="celestia-right">
        <div class="layout-bloc section">
          <div class="section-header-buttons">
            <div class="section-button inverted" (click)="previousSelection()">
              <i class="icon-arrow-right"></i>
            </div>
            <div class="text-center margin-bottom-1">
              <h2>{{ currentCelestia.firstname }} {{ currentCelestia.lastname }}</h2>
              <h3>{{ currentCelestia.grade }}</h3>
            </div>
            <div class="section-button" (click)="nextSelection()">
              <i class="icon-arrow-right"></i>
            </div>
          </div>
          <div class="section-description">
            <div class="margin-bottom-1 text-center">
              <span>{{ currentCelestia.age }} ans - {{ currentCelestia.astro }} - {{ currentCelestia.mbti }}</span>
            </div>
            <div class="margin-bottom-1">
              <span class="text-bold">Emotion dominante&nbsp;:&nbsp;</span>
              <span>{{ currentCelestia.emotion }}</span><br>
              <span class="text-bold">Grade&nbsp;:&nbsp;</span>
              <span>{{ currentCelestia.grade }}</span>
            </div>
            <div class="margin-bottom-1">
              <span class="text-bold">Orientation - Relations</span><br>
              <span>{{ currentCelestia.orientation }} - {{ currentCelestia.situation }}</span>
              <span [innerHtml]="getListArray(currentCelestia.relations)"></span>
            </div>
            <div>
              <span class="text-bold">Qualités&nbsp;:&nbsp;</span>
              <span>{{ getLibArray(currentCelestia.qualities) }}</span><br>
              <span class="text-bold">Défauts&nbsp;:&nbsp;</span>
              <span>{{ getLibArray(currentCelestia.defaults) }}</span>
            </div>
          </div>
          <p class="section-comment margin-top-1" [title]="currentCelestia.comment">
            "{{ currentCelestia.comment }}"
          </p>
        </div>
      </div>
    </div>
  </div>

  <div little-separator></div>

  <div class="layout-view">
    <h2>Quel mage de Célestia êtes-vous ?</h2>

    <div *ngIf="currentQuestion === 100" class="layout-bloc text-center">
      <div class="margin-top-1">
        L'Académie des Mages Evanell recrute ponctuellement des mages pour défendre les célestiens
        contre les attaques des Wendigos (titans monstrueux venant de l'En-Bas).<br>
        L'Académie comporte 9 types de mages élémentaires, qui suivent une formation avant de combattre.<br>
        Si vous étiez membre de cette académie, quelle sorte de mage seriez-vous ?<br>
        <br>
        Le questionnaire commence par des questions sur vous dans la vie réelle,
        puis sur vous si vous étiez mage sur Célestia.
      </div>
      <button type="button" mat-stroked-button color="primary" class="margin-top-1 margin-bottom-1"
        (click)="startQuestion()">
        Commencer le test
      </button>
    </div>

    <div *ngIf="currentQuestion === 200" class="layout-bloc section is-height-free">
      <div class="section-header">
        <img class="section-icon" [src]="quizz.result.image" [alt]="quizz.result.libelle">
        <div class="text-center margin-left-1">
          <h2>{{ quizz.result.libelle }}</h2>
          <span *ngIf="pourcent !== 100">(<b>{{ pourcent }}%</b> des mages)</span>
        </div>
      </div>
      <div class="section-description margin-top-1">
        <div class="margin-bottom-1">
          <span class="text-bold">Axe :</span>
          <span>{{ quizz.result.axe }}</span>
        </div>
        <div class="margin-bottom-1">
          <span class="text-bold">Symboles :</span>
          <span>{{ quizz.result.symboles }}</span>
        </div>
        <div>
          <span [innerHtml]="quizz.result.description"></span>
        </div>
      </div>
      <p class="section-comment margin-top-1">
        Feu : {{ quizz.fire }} - Eau : {{ quizz.water }}<br>
        Air : {{ quizz.wind }} - Terre : {{ quizz.earth }}<br>
        <br>
        Alliance Feu-Eau : {{ quizz.fire - quizz.water }} ({{ getLibFireWater() }})<br>
        Alliance Air-Terre : {{ quizz.wind - quizz.earth }} ({{ getLibWindEarth() }})
      </p>
    </div>

    <ng-container *ngFor="let question of quizz.questions; let index = index">
      <celestia-question *ngIf="currentQuestion === index" [indexQuestion]="index" (outNextQuestion)="nextQuestion()">
      </celestia-question>
    </ng-container>

    <ul class="celestia-steps steps-container">
      <ng-container *ngFor="let question of quizz.questions; let index = index">
        <div class="dotline"></div>
        <li [class.active]="currentQuestion === index" class="dot"></li>
      </ng-container>
      <div class="dotline"></div>
    </ul>
  </div>
</div>