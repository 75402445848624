<h3>Pouvoir : {{ power.name }}</h3>
<mat-dialog-content class="mat-typography">
  <p>
    <i>
      <span title="Type de pouvoir">{{ power.type.Libelle }}</span>
      &nbsp;•&nbsp;
      <i class="icon-spinner" title="Catégorie du pouvoir"></i>&nbsp;
      <span title="Catégorie du pouvoir">{{ power.category.Libelle }}</span>
      &nbsp;•&nbsp;
      <i class="icon-enlarge" title="Portée du pouvoir"></i>&nbsp;
      <span title="Portée du pouvoir">{{ power.range.Libelle }}</span>
      &nbsp;•&nbsp;
      <i class="icon-hour-glass" title="Durée et rechargement du pouvoir"></i>&nbsp;
      <span title="Durée et rechargement du pouvoir">{{ power.duration.Libelle }}</span>
    </i>
    <br>
    {{ power.description ? power.description : "Aucune description disponible" }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>