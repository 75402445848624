<h2 class="text-center margin-bottom-1">
  Choix dans la liste
</h2>

<div class="creation-content">
  <div class="creation-column">
    <div class="creation-selections">
      <div class="creation-selection" [class.is-selected]="!selectedCreature" (click)="selectCreature('')">
        Nouvelle<br>créature
      </div>
      <div *ngFor="let creature of creatures" class="creation-selection"
        [class.is-selected]="selectedCreature?.guid === creature.guid" (click)="selectCreature(creature.guid)">
        <img *ngIf="creature.avatar" class="creation-selection-img" [src]="creature.profil" [alt]="creature.name">
        <div *ngIf="!creature.avatar" class="creation-selection-img"></div>
        <div class="creation-selection-text">
          <div>{{ creature.name }}</div>
          <span [class]="creature.difficultyColor">{{ creature.difficultyLibelle }}</span>
          <div *ngIf="getDetails(creature)" class="creation-selection-subtitle">
            {{ getDetails(creature) }}
          </div>
        </div>
      </div>
    </div>

    <div class="layout-row layout-col-mob margin-top-1">
      <button type="button" mat-stroked-button color="primary" class="full-width" [disabled]="toDelete"
        (click)="download(false)">
        <span *ngIf="selectedCreature">Télécharger la fiche (imprimable)</span>
        <span *ngIf="!selectedCreature">Télécharger la fiche vierge (imprimable)</span>
      </button>
      <button type="button" mat-stroked-button color="primary" class="full-width" [disabled]="toDelete"
        (click)="download(true)">
        <span *ngIf="selectedCreature">Télécharger la fiche (colorée)</span>
        <span *ngIf="!selectedCreature">Télécharger la fiche vierge (colorée)</span>
      </button>
      <button type="button" mat-stroked-button color="warn" *ngIf="selectedCreature && !selectedCreature.isBDD"
        class="full-width" (click)="openDeletePopup()">
        Supprimer la créature
      </button>
    </div>
  </div>
</div>

<div class="creation-content-nav">
  <button type="button" mat-stroked-button color="warn" (click)="quit()">Quitter</button>
  <button type="button" mat-stroked-button color="primary" (click)="start()">
    <span *ngIf="!selectedCreature">Créer une créature</span>
    <span *ngIf="selectedCreature">Modifier la créature</span>
  </button>
</div>