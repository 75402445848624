<div class="encyclo-dakaros-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Dakaros">
      <img class="encyclopedia-img" [src]="citeDakarosImage" alt="La Cité de Dakaros" />
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
      <!--div class="layout-bloc" [innerHTML]="citeDakarosResume"></div>
      <div class="layout-bloc margin-top-1" [innerHTML]="citeDakarosDetails"></div-->
    </mat-tab>
    <mat-tab label="Les Montagnes de Kazador">
      <div class="layout-bloc is-info">
        Contenu à venir...
      </div>
    </mat-tab>
  </mat-tab-group>
</div>