<div class="layout-bloc margin-top-1" [innerHtml]="systemRacesText"></div>

<div class="grid-list grid-2 margin-top-1">
  <div *ngFor="let race of allRaces" class="grid-element">
    <img class="img-rounded" [src]="race.image" [alt]="race.name">
    <div>
      <div class="grid-header">
        <span class="text-bold">{{ race.name }}</span>
        <span [class]="'margin-left-05 ' + getDifficultyColor(race)">{{ getDifficultyLibelle(race) }}</span>
      </div>
      <p class="grid-body" [innerHtml]="race.description"></p>
    </div>
  </div>
</div>