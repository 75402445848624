<creation-form formTitle="Choix de l'identité" [isModified]="isModified" [isInvalid]="!form.valid"
  (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form">
      <div class="margin-bottom-1">
        <div>Avatar de la créature</div>
        <label for="avatar" class="creation-avatar margin-top-05">
          <span *ngIf="!form.controls.avatar.value">Importer une image<br>(ratio 1:1 | JPG, PNG | max 1 Mo)</span>
          <img *ngIf="form.controls.avatar.value" alt="Avatar de la créature"
            [src]="getProfil(form.controls.avatar.value)">
        </label>
        <input type="file" name="avatar" id="avatar" accept="image/png, image/jpeg" (change)="changeAvatar($event)">
      </div>

      <div class="semi-width">
        <mat-form-field>
          <mat-label>Nom de la créature</mat-label>
          <input type="text" matInput maxlength="25" formControlName="name" />
        </mat-form-field>

        <mat-form-field class="margin-top-1">
          <mat-label>Type de créature</mat-label>
          <mat-select formControlName="codeBestiary">
            <mat-option *ngFor="let bestiary of allBestiaries" [value]="bestiary.code">
              {{ bestiary.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="currentBestiary" class="layout-bloc margin-top-1">
          <h3>{{ currentBestiary.name }}</h3>
          <div class="margin-top-1">
            {{ currentBestiary.description }}
            <ng-container *ngIf="currentBestiary.examples">
              <br><i>{{ currentBestiary.examples }}</i>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!currentBestiary" class="layout-bloc margin-top-1">
          Aucun type de créature n'est sélectionné.
        </div>
      </div>
    </form>
  </div>
</creation-form>