import { getListBestiaries } from '../data/ListBestiaries';

export class ArlenorBestiary {
  public name: string;
  public description: string;
  public examples: string;

  // Variables dérivées
  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  constructor(name: string, description: string, examples = '') {
    this.name = name;
    this.description = description;
    this.examples = examples;
  }

  public static getBestiary(code: string): ArlenorBestiary {
    const arlenorBestiaries = getListBestiaries();
    const result = arlenorBestiaries.find(bestiary => bestiary.code === code);
    if (!result) console.error('ERROR getBestiary : ', code);
    return result || arlenorBestiaries[0];
  }
}
