<div class="encyclo-atlantica-land-view margin-top-1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité d'Atlantica">
      <img class="encyclopedia-img" [src]="citeAtlanticaImage" alt="La Cité d'Atlantica" />
      <div class="layout-bloc" [innerHTML]="citeAtlanticaResume"></div>
      <div class="layout-bloc margin-top-1" [innerHTML]="citeAtlanticaDetails"></div>
    </mat-tab>
    <mat-tab label="Les Tribus de l'Eau">
      <div class="layout-bloc" [innerHTML]="tribusDetails"></div>
    </mat-tab>
    <mat-tab label="La Mer de Shivazen">
      <img class="encyclopedia-img" [src]="merShivazenImage" alt="La Mer de Shivazen" />
      <div class="layout-bloc" [innerHTML]="merShivazenResume"></div>
    </mat-tab>
  </mat-tab-group>
</div>