<div class="home-view">
  <div [class.active]="selection === 0" class="home-container bg-universe bg-block">
    <div class="home-subcontainer">
      <img src="assets/images_alpha/logo_md.png" alt="Logo d'Arlénor">
      <h3>{{ pages.home }}</h3>
      <h1>{{ pages.universe }}</h1>
      <p>
        Découvrez l'univers du Monde d'Arlénor, la religion dominante, la magie omni-présente, les différents peuples
        y vivant et les conflits y règnant...
      </p>
      <arrow-button linkName="Découvrir" linkPage="/univers"></arrow-button>
    </div>
  </div>
  <div [class.active]="selection === 1" class="home-container bg-roleplay-reverse bg-block">
    <div class="home-subcontainer inversed-container">
      <h3>{{ pages.home }}</h3>
      <h1>{{ pages.roleplay }}</h1>
      <p>
        Vivez une histoire épique à travers de jeunes héros, découvrant la notion du Destin et de l'effet papillon...
      </p>
      <arrow-button linkName="Osez l'aventure" linkPage="/jeu-de-roles"></arrow-button>
    </div>
  </div>
  <div [class.active]="selection === 2" class="home-container bg-celestia bg-block">
    <div class="home-subcontainer">
      <h3>{{ pages.home }}</h3>
      <h1>{{ pages.celestia }}</h1>
      <p>
        Chaque personnage a une histoire. Découvrez celles de Romain, Elisa, Jérémy... et de tous les autres, sur
        l'île de Célestia.
      </p>
      <arrow-button linkName="Volez vers Célestia" linkPage="/celestia"></arrow-button>
    </div>
  </div>
  <ul class="steps-container">
    <div class="dotline"></div>
    <li [class.active]="selection === 0" class="dot" (click)="setSelection(0)"></li>
    <div class="dotline"></div>
    <li [class.active]="selection === 1" class="dot" (click)="setSelection(1)"></li>
    <div class="dotline"></div>
    <li [class.active]="selection === 2" class="dot" (click)="setSelection(2)"></li>
    <div class="dotline"></div>
  </ul>
</div>