import { Component, OnDestroy, OnInit } from '@angular/core';

import { ArlenorCreature } from '../../../models/arlenor/ArlenorCreature';
import { PageTitles } from '../../../models/PagesTitles';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'creature-view',
  templateUrl: './creature.view.html',
  styleUrls: ['./creature.view.scss'],
})
export class CreatureView implements OnInit, OnDestroy {

  public title = PageTitles.creature;

  public step: number;

  public get creature(): ArlenorCreature {
    return this._storeService.creature;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.step = -1;

    this._storeService.loadAllCreatures(true);
    this._storeService.loadLocalCreatures();
    this._storeService.loadAllArchetypes();
    this._storeService.loadAllPowers();
    this._checkModifications();
  }

  public ngOnDestroy() {
    this._storeService.resetCreature();
    this._checkModifications(true);
  }

  public decreaseStep(): void {
    this.step--;
    if (this.step === 3 && !this.creature.caractMag) this.step--;
    window.scroll(0,0);
    this._checkModifications();
  }

  public increaseStep(): void {
    this.step++;
    if (this.step === 3 && !this.creature.caractMag) this.step++;
    window.scroll(0,0);
    this._checkModifications();
  }

  public reset(isRestart: boolean): void {
    this.step = isRestart ? 1 : 0;
    window.scroll(0,0);
    this._checkModifications();
  }

  private _checkModifications(forceFalse = false) {
    // Permet de vérifier qu'on est en pleine création
    if (!forceFalse) this._storeService.hasModification = (0 < this.step && this.step < 5);
    else this._storeService.hasModification = false;
  }
}
