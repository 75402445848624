<div class="magic-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="layout-bloc" [innerHTML]="resume"></div>
  </head-layout>

  <div little-separator></div>

  <div class="layout-view">
    <div class="magic-container">
      <div class="magic-left">
        <div class="magic-imgs">
          <img class="crystal-main" src="assets/images_alpha/magic/crystal.png" alt="Illustration d'un cristal">
          <img class="crystal-data01" src="assets/images_alpha/magic/crystal_data01.png" aria-hidden="true">
          <img class="crystal-data02" src="assets/images_alpha/magic/crystal_data02.png" aria-hidden="true">
          <img class="crystal-data03" src="assets/images_alpha/magic/crystal_data03.png" aria-hidden="true">
        </div>
      </div>
      <div class="magic-right">
        <h2 class="text-center">Propriétés globales aux cristaux</h2>
        <div class="layout-bloc">
          <div class="text-center">
            Ces propriétés s'appliquent pour les cristaux&nbsp;:<br>
            <b>énergétiques</b>, <b>instantanés</b> et <b>évolutifs</b>.
          </div>
          <div class="margin-top-1">
            - Ils mesurent entre 1 cm et 2 m.<br>
            - La couleur d'un cristal est liée à son effet, à son pouvoir.<br>
            - Un cristal est très solide, résistant.<br>
            - L'utilisateur se lie à un cristal évolutif à la fois.<br>
            - Plus l'utilisateur utilise ses cristaux, plus le lien entre eux s'intensifie.<br>
            - Plus l'utilisateur est loin de son cristal, plus il lui est difficile de l'utiliser.<br>
            - Les cristaux viennent principalement des mines de la cité désertique Jirakan.<br>
            - Seuls les cristalliens (métier rare et complexe) peuvent les retravailler
            en leur donnant une forme précise.<br>
            - Les cristaux ne peuvent pas être séparés en plusieurs parties.
            Cassés, ils perdent totalement leur pouvoir.<br>
            - Il existerait des cristaux uniques, dits 'légendaires', aux puissances incommensurables...
          </div>
        </div>
      </div>
      <div class="magic-items grid-list">
        <div *ngFor="let magic of allMagics" class="grid-element">
          <img [src]="magic.image" [alt]="magic.name">
          <div>
            <div class="grid-header">
              <span class="text-bold">{{ magic.name }}</span>
            </div>
            <p class="grid-body" [innerHtml]="magic.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>