<h3>Description de la caractéristique</h3>
<mat-dialog-content class="mat-typography race-modal">
  <ng-container *ngIf="caract === caractDescriptionEnum.Force.Code">
    <h2 class="margin-top-0 text-center">La Force</h2>
    <span [innerHtml]="caractDescriptionEnum.Force.Libelle"></span>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Habilete.Code">
    <h2 class="margin-top-0 text-center">L'Habileté</h2>
    <span [innerHtml]="caractDescriptionEnum.Habilete.Libelle"></span>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Intellect.Code">
    <h2 class="margin-top-0 text-center">L'Intellect</h2>
    <span [innerHtml]="caractDescriptionEnum.Intellect.Libelle"></span>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Tenacite.Code">
    <h2 class="margin-top-0 text-center">La Ténacité</h2>
    <span [innerHtml]="caractDescriptionEnum.Tenacite.Libelle"></span>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Charisme.Code">
    <h2 class="margin-top-0 text-center">Le Charisme</h2>
    <span [innerHtml]="caractDescriptionEnum.Charisme.Libelle"></span>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Magie.Code">
    <h2 class="margin-top-0 text-center">La Magie</h2>
    <span [innerHtml]="caractDescriptionEnum.Magie.Libelle"></span>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>