import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppPopupComponent } from './app-popup/app-popup.component';
import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { CelestiaQuestionComponent } from './celestia-question/celestia-question.component';
import { CreationFormComponent } from './creation/creation-form.component';
import { ExpandBlocComponent } from './expand-bloc/expand-bloc.component';
import { FooterLinkComponent } from './footer-link/footer-link.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { GlypheComponent } from './glyphe/glyphe.component';
import { HeadLayoutComponent } from './head-layout/head-layout.component';
import { HeadSectionComponent } from './head-section/head-section.component';
import { MaterialsModule } from './materials.modules';
import { MeikerComponent } from './meiker/meiker.component';
import { CaractModal } from './modals/caract/caract.modal';
import { ConfirmationModal } from './modals/confirmation/confirmation.modal';
import { FactionModal } from './modals/faction/faction.modal';
import { InformationModal } from './modals/information/information.modal';
import { PowerFormModal } from './modals/power-form/power-form.modal';
import { PowerModal } from './modals/power/power.modal';
import { RaceModal } from './modals/race/race.modal';
import { SkillModal } from './modals/skill/skill.modal';
import { SpecialityModal } from './modals/speciality/speciality.modal';
import { StuffModal } from './modals/stuff/stuff.modal';
import { UserFormModal } from './modals/user-form/user-form.modal';
import { PowerImageComponent } from './power-image/power-image.component';
import { CharactersTableComponent } from './tables/characters/characters-table.component';
import { DivinitiesTableComponent } from './tables/divinities/divinities-table.component';
import { PowersTableComponent } from './tables/powers/powers-table.component';
import { PowersSelectionTableComponent } from './tables/powers/selection/powers-selection-table.component';
import { SkillsSelectionTableComponent } from './tables/skills/selection/skills-selection-table.component';
import { SkillsTableComponent } from './tables/skills/skills-table.component';
import { StuffsTableComponent } from './tables/stuffs/stuffs-table.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { TreeMenuComponent } from './tree-menu/tree-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialsModule,
  ],
  declarations: [
    AppFooterComponent,
    AppHeaderComponent,
    AppPopupComponent,
    ArrowButtonComponent,
    CaractModal,
    CelestiaQuestionComponent,
    CharactersTableComponent,
    ConfirmationModal,
    CreationFormComponent,
    DivinitiesTableComponent,
    ExpandBlocComponent,
    FactionModal,
    FooterLinkComponent,
    FooterSectionComponent,
    GlypheComponent,
    HeadLayoutComponent,
    HeadSectionComponent,
    InformationModal,
    MeikerComponent,
    PowerImageComponent,
    PowerModal,
    PowerFormModal,
    PowersSelectionTableComponent,
    PowersTableComponent,
    RaceModal,
    SkillModal,
    SkillsSelectionTableComponent,
    SkillsTableComponent,
    SpecialityModal,
    StuffModal,
    StuffsTableComponent,
    ToggleButtonComponent,
    TreeMenuComponent,
    UserFormModal,
  ],
  exports: [
    AppFooterComponent,
    AppHeaderComponent,
    AppPopupComponent,
    ArrowButtonComponent,
    CaractModal,
    CelestiaQuestionComponent,
    CharactersTableComponent,
    ConfirmationModal,
    CreationFormComponent,
    DivinitiesTableComponent,
    ExpandBlocComponent,
    FactionModal,
    FooterLinkComponent,
    FooterSectionComponent,
    GlypheComponent,
    HeadLayoutComponent,
    HeadSectionComponent,
    InformationModal,
    MeikerComponent,
    PowerImageComponent,
    PowerModal,
    PowerFormModal,
    PowersSelectionTableComponent,
    PowersTableComponent,
    RaceModal,
    SkillModal,
    SkillsSelectionTableComponent,
    SkillsTableComponent,
    SpecialityModal,
    StuffModal,
    StuffsTableComponent,
    ToggleButtonComponent,
    TreeMenuComponent,
    UserFormModal,
  ],
})
export class ComponentsModule { }
