<h2 class="text-center margin-bottom-1">
  {{ formTitle }}
  <!--ng-container *ngIf="isModified">&nbsp;*</ng-container-->
</h2>

<div class="creation-content">
  <ng-content></ng-content>
</div>

<div class="creation-content-nav">
  <button type="button" mat-stroked-button [color]="(isModified && needConfirm) ? 'warn' : 'primary'"
    (click)="cancel()">
    <ng-container *ngIf="!isModified">
      Précédent
    </ng-container>
    <ng-container *ngIf="isModified">
      Annuler les modifications
      <ng-container *ngIf="needConfirm">
        (Confirmez ?)
      </ng-container>
    </ng-container>
  </button>

  <button type="button" mat-stroked-button color="primary" [disabled]="isInvalid" (click)="submit()">Suivant</button>
</div>