import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ArlenorRace } from '../../../models/arlenor/ArlenorRace';
import { ArlenorSkill } from '../../../models/arlenor/ArlenorSkill';
import { getListRaces } from '../../../models/data/ListRaces';
import { PageTitles } from '../../../models/PagesTitles';
import { StoreService } from '../../../services/store.service';
import { convertMarkdownToHTML } from '../../../utils/conversions';

@Component({
  selector: 'races-view',
  templateUrl: './races.view.html',
  styleUrls: ['./races.view.scss'],
})
export class RacesView implements OnInit {

  public resume = racesResume;

  public currentIndex: number;
  public currentImage: string;
  public previousImage: string;
  public nextImage: string;
  public allRaces: ArlenorRace[];
  public allSkills: ArlenorSkill[];

  public title = PageTitles.races;
  public imageLeft = 'assets/images_alpha/races/adn_left.png';
  public imageRight = 'assets/images_alpha/races/adn_right.png';

  private _destroyRef = inject(DestroyRef);

  public get previousIndex(): number {
    if (this.currentIndex === 0) return this.allRaces.length - 1;
    else return this.currentIndex - 1;
  }

  public get nextIndex(): number {
    if (this.currentIndex === this.allRaces.length - 1) return 0;
    else return this.currentIndex + 1;
  }

  public get currentRace(): ArlenorRace {
    return this.allRaces[this.currentIndex];
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.currentIndex = 0;

    this.allRaces = getListRaces();
    this._updateRace();

    this._storeService.$allSkills
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(value => {
        if (value) this.allSkills = value;
      });
    this._storeService.loadAllSkills();
  }

  public getSkillsByRace(codeRace: string) {
    if (!this.allSkills) return [];
    const skills = this.allSkills.filter(skill => skill.codeRace === codeRace);
    skills.sort((a, b) => a.name.localeCompare(b.name));
    while (skills.length < 4) skills.push({ name: '-', description: '-' } as ArlenorSkill);
    return skills;
  }

  public previousSelection() {
    if (this.currentIndex === 0) this.currentIndex = this.allRaces.length - 1;
    else this.currentIndex = this.currentIndex - 1;
    this._updateRace();
  }

  public nextSelection() {
    if (this.currentIndex === this.allRaces.length - 1) this.currentIndex = 0;
    else this.currentIndex = this.currentIndex + 1;
    this._updateRace();
  }

  private _updateRace() {
    this.previousImage = this.allRaces[this.previousIndex].image;
    this.currentImage = this.allRaces[this.currentIndex].image;
    this.nextImage = this.allRaces[this.nextIndex].image;
  }
}

export const racesResume = convertMarkdownToHTML(`
  En se familiarisant avec les cristaux, les humains se sont alors mis en quête d'aventure :
  explorer le monde qui leur avait été donné, tout en recherchant de nouvelles sources d'énergies ou de pouvoirs...

  La manipulation et la présence des cristaux aux côtés des humains, ont au fil du temps
  **altéré leur code génétique**, donnant vie à de nouvelles races.
  Des relations se sont créées alors entre les différences races, certaines amicales, neutres, et d'autres... meurtrières.

  Les **humains** et les **elfes** vivent en toute liberté alors que les **nains** et les **mutants**
  essayent de survivre du mieux qu'ils le peuvent.
  Les **pans** et les **arléniens**, eux, restent cachés, observant le monde de loin.
  Et les **célestiens**, coupés de tout, vivent leur propre aventure...`);