import { Component, Input } from '@angular/core';

@Component({
  selector: 'glyphe',
  templateUrl: './glyphe.component.html',
  styleUrls: ['./glyphe.component.scss'],
})
export class GlypheComponent {
  @Input() public icon: string;

  public getImage(id: number): string {
    return 'assets/images_alpha/glyphes/' + this.icon + '_0' + id + '.png';
  }
}
