import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageTitles } from 'src/app/models/PagesTitles';

@Component({
  selector: 'home-view',
  templateUrl: './home.view.html',
  styleUrls: ['./home.view.scss'],
})
export class HomeView implements OnInit {

  public pages = PageTitles;
  public selection = 0;
  private _timeSub: number = null;

  constructor(private _title: Title) {
    this._title.setTitle(PageTitles.home);
  }

  public ngOnInit() {
    this._launchTimeout();
  }

  public changeSelection(): void {
    if (this.selection === 0) this.selection = 1;
    else if (this.selection === 1) this.selection = 2;
    else if (this.selection === 2) this.selection = 0;
    this._launchTimeout();
  }

  public setSelection(newSelection: number): void {
    this.selection = newSelection;
    this._launchTimeout();
  }

  private _launchTimeout(): void {
    if (this._timeSub) window.clearTimeout(this._timeSub);
    this._timeSub = window.setTimeout(() => {
      this.changeSelection();
    }, 12000);
  }
}
