<table *ngFor="let rank of ranks" mat-table [dataSource]="dataSources[rank.Code]">
  <ng-container matColumnDef="rank">
    <th mat-header-cell *matHeaderCellDef colspan="3" class="col-100">
      <div class="table-separator">
        <span class="table-separator-line"></span>
        <span class="table-separator-title">
          Rang {{ rank.Libelle }}
        </span>
        <span class="table-separator-line"></span>
        <ng-container *ngIf="level">
          <span class="table-separator-title">
            {{ currentRanks[rank.Code].current }} / {{ currentRanks[rank.Code].max }}
          </span>
          <span class="table-separator-line"></span>
        </ng-container>
      </div>
    </th>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef class="col-20"></th>
    <td mat-cell *matCellDef="let element" class="col-20 pointer" (click)="seeMore(element)">
      <power-image [power]="element"></power-image>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="col-60"></th>
    <td mat-cell *matCellDef="let element" class="col-60 pointer" (click)="seeMore(element)">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef class="col-20"></th>
    <td mat-cell *matCellDef="let element" class="col-20">
      <mat-checkbox [checked]="checkPower(element)" color="primary" [disabled]="checkDisabled(rank.Code, element)"
        (change)="changePower($event.checked, element)"></mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['rank']"></tr>
  <tr mat-row *matRowDef=" let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="powers.length === 0" class="layout-bloc is-info text-center">Aucun pouvoir.</div>