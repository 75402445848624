import { getListFactions } from '../data/ListFactions';

export class ArlenorFaction {
  public isOff: boolean;
  public name: string;
  public description: string;
  public urlImage: string | null;
  public values: string[];
  public location: string;

  // Variables dérivées
  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  public get image(): string | null {
    if (this.urlImage) return 'assets/images_filled/factions/' + this.urlImage;
    else return null;
  }

  constructor(isOff: boolean, urlImage: string, name: string, description: string, values: string[], location: string) {
    this.isOff = isOff;
    this.name = name;
    this.description = description;
    this.urlImage = urlImage;
    this.values = values;
    this.location = location;
  }

  public static getFaction(code: string): ArlenorFaction {
    const arlenorFactions = getListFactions();
    const result = arlenorFactions.find(faction => faction.code === code);
    if (!result) console.error('ERROR getFaction : ', code);
    return result || arlenorFactions[0];
  }
}
