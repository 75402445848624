import { Component, OnInit } from '@angular/core';

import { AssetsItem, avatarItems } from 'src/assets/meiker/avatar-files';

import { PageTitles } from '../../../models/PagesTitles';

@Component({
  selector: 'avatar-view',
  templateUrl: './avatar.view.html',
  styleUrls: ['./avatar.view.scss'],
})
export class AvatarView implements OnInit {

  public title = PageTitles.avatar;

  public items: AssetsItem[] = [];
  public name = 'avatar';
  public height = 700;
  public width = 700;

  public ngOnInit() {
    this.items = avatarItems;
  }
}
