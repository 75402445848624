<h3>Description de la faction</h3>
<mat-dialog-content class="mat-typography faction-modal">
  <div class="faction-modal-content">
    <img class="faction-modal-img" [src]="faction.image" [alt]="faction.name">
    <h2>{{ faction.name }}</h2>
    <p [innerHtml]="faction.description"></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>