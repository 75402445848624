<h3>Description de la race</h3>
<mat-dialog-content class="mat-typography race-modal">
  <div class="race-modal-content">
    <img class="race-modal-img" [src]="race.image" [alt]="race.name">
    <h2>{{ race.name }}</h2>
    <p class="text-italic">
      {{ race.infoAge }}
      <br>Apparence : {{ race.infoAppareance }}
    </p>
    <p [innerHtml]="race.description"></p>
    <div class="section-moreinfos">
      <p class="margin-bottom-0">
        <span class="text-bold">{{ race.ratioWorld }}%</span>
        du monde sont des {{ race.name.toLowerCase() }}s
      </p>
      <p class="margin-bottom-0">
        <span>Localisations</span><br>
        <span *ngFor="let location of race.locations">- {{ location }}<br></span>
      </p>
      <p class="margin-bottom-0">
        <span class="text-bold">{{ race.ratioMagic }}%</span>
        des {{ race.name.toLowerCase() }}s peuvent utiliser la magie
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>