/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from 'src/environments/environment';

const url = environment.NG_APP_API_URL || '';
const key = environment.NG_APP_API_ANON_KEY || '';

const requestGet = async(target: string): Promise<any[]> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', key);

  const result: any[] = [];
  try {
    return await fetch(url + target,
      {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
      })
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          return JSON.parse(data.body);
        } else {
          console.error('Error:', data.body);
          return result;
        }
      });
  } catch (err) {
    console.error('Error:', err);
  }
  return result;
};

const requestPost = async(target: string, item: any): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', key);

  try {
    return await fetch(url + target,
      {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(item),
      })
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          return JSON.parse(data.body);
        } else {
          console.error('Error:', data.body);
        }
      });
  } catch (err) {
    console.error('Error:', err);
  }
  return '';
};


const requestPut = async(target: string, item: any): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', key);

  try {
    return await fetch(url + target,
      {
        method: 'PUT',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(item),
      })
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          return JSON.parse(data.body);
        } else {
          console.error('Error:', data.body);
        }
      });
  } catch (err) {
    console.error('Error:', err);
  }
  return '';
};

const requestDelete = async(target: string, id: number): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', key);

  try {
    return await fetch(url + target,
      {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(id),
      })
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          return JSON.parse(data.body);
        } else {
          console.error('Error:', data.body);
        }
      });
  } catch (err) {
    console.error('Error:', err);
  }
  return '';
};

export default {
  requestGet,
  requestPost,
  requestPut,
  requestDelete
};