<creation-form [formTitle]="(indexCrystal === 0) ? 'Choix de la classe principale' : 'Choix de la classe secondaire'"
  [isModified]="isModified" [isInvalid]="!form.valid" (outCancel)="cancelForm()" (outSubmit)="submitForm()">
  <div class="creation-column">
    <form [formGroup]="form" class="semi-width margin-bottom-1">
      <mat-form-field>
        <mat-label>Classe</mat-label>
        <mat-select formControlName="codeSpeciality">
          <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code" [disabled]="!checkSpe(spe)">
            {{ spe.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="semi-width margin-top-1">
      <button type="button" mat-stroked-button color="primary" [disabled]="!selectedSpeciality"
        (click)="openSpePopup()">
        En savoir plus
      </button>
    </div>

    <div *ngIf="selectedSpeciality" class="creation-tables margin-top-1">
      <powers-selection-table [indexCrystal]="indexCrystal" [level]="character.level" [idsPowers]="idsPowers"
        [powers]="filteredPowers" (outAdd)="addPower($event)" (outRemove)="removePower($event)">
      </powers-selection-table>
    </div>

    <div class="layout-row layout-col-mob margin-top-1">
      <div *ngIf="!selectedSpeciality" class="layout-bloc semi-width is-warning">
        Choisissez une classe avant de sélectionner des pouvoirs.
      </div>
      <div *ngIf="selectedSpeciality && !form.controls.isNbPowersValid.value" class="layout-bloc semi-width is-warning">
        Il reste des pouvoirs à sélectionner...
      </div>
      <div *ngIf="selectedSpeciality && form.controls.isNbPowersValid.value" class="layout-bloc semi-width">
        Tous les pouvoirs ont été sélectionnés.
      </div>
    </div>
  </div>
</creation-form>