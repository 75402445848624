import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'system-alterations-view',
  templateUrl: './system-alterations.view.html',
  styleUrls: [],
})
export class SystemAlterationsView {

  public systemAlterationsText = systemAlterationsText;

  public displayedColumns: string[] = ['name', 'description', 'effect'];

  public dataActions: AlterationElement[] = [
    {
      name: 'Atrophie',
      description: `Empêche le personnage affligé d'attaquer, d'utiliser ses mains.`,
      effect: null,
    },
    {
      name: 'Enchevêtrement / Handicap',
      description: `Empêche le personnage de se déplacer et donne un malus.`,
      effect: `1 dé malus, aux esquives`,
    },
    {
      name: 'Plan étheré',
      description: `Le personnage est dans un autre plan, ne pouvant pas interagir avec le réel et inversement.`,
      effect: null,
    },
    {
      name: 'Renversement',
      description: `Le personnage est à terre et incapable de se déplacer. Se relever prend un tour au combat.`,
      effect: null,
    },
  ];
  public dataSourceActions = new MatTableDataSource<AlterationElement>(this.dataActions);

  public dataMentales: AlterationElement[] = [
    {
      name: 'Cécité',
      description: `Réduit la portée et donne un malus.`,
      effect: `1 dé malus, aux actions de précision`,
    },
    {
      name: 'Charme',
      description: `Retourne temporairement un personnage contre ses alliés.`,
      effect: null,
    },
    {
      name: 'Folie / Démence',
      description: `Le personnage est fou de rage et attaque l'ami ou ennemi le plus proche, sans distinction.`,
      effect: null,
    },
    {
      name: 'Provocation',
      description: `La cible est obligée d'attaquer le lanceur.`,
      effect: null,
    },
    {
      name: 'Silence',
      description: `Empêche le personnage d'utiliser ses cristaux.`,
      effect: null,
    },
    {
      name: 'Sommeil',
      description: `La cible endormie est incapable d'agir ou de se déplacer.`,
      effect: null,
    },
    {
      name: 'Terreur',
      description: `Le personnage s'enfuit de terreur et donne un malus.`,
      effect: `1 dé malus`,
    },
  ];
  public dataSourceMentales = new MatTableDataSource<AlterationElement>(this.dataMentales);

  public dataElementales: AlterationElement[] = [
    {
      name: 'Brûlure',
      description: `Inflige des dégâts bruts à chaque tour.`,
      effect: `-1 PV chaque tour`,
    },
    {
      name: 'Etat de choc',
      description: `Le personnage est choqué par l'électricité et donne un malus.
      Subir Humidité provoque Etourdissement.`,
      effect: `1 dé malus`,
    },
    {
      name: 'Etourdissement',
      description: `Paralysé par l'électricité. Incapacité d'agir.`,
      effect: null,
    },
    {
      name: 'Coup de froid',
      description: `Le personnage est refroidi et donne un malus. Subir Humidité provoque Gel.`,
      effect: `1 dé malus`,
    },
    {
      name: 'Gel',
      description: `Paralysé par le gel. Incapacité d'agir.`,
      effect: null,
    },
    {
      name: 'Humidité',
      description: `Subir Etat de choc après Humidité provoque Etourdissement. Subir Coup de froid provoque Gel.`,
      effect: null,
    },
    {
      name: 'Pétrification',
      description: `La cible est incapable de bouger et d'agir de manière permanente.`,
      effect: null,
    },
  ];
  public dataSourceElementales = new MatTableDataSource<AlterationElement>(this.dataElementales);

  public dataDiverses: AlterationElement[] = [
    {
      name: 'Bénédiction',
      description: `Le personnage voit toutes ses performances augmenter.`,
      effect: `2 dé bonus`,
    },
    {
      name: 'Empoisonnement / Hémorragie',
      description: `Inflige des dégâts bruts à chaque tour.`,
      effect: `-1 PV chaque tour`,
    },
    {
      name: 'Infection',
      description: `Le personnage est contagieux et donne un malus.`,
      effect: `1 dé malus`,
    },
    {
      name: 'Maladie',
      description: `Donne un malus.`,
      effect: `1 dé malus`,
    },
    {
      name: 'Putréfaction',
      description: `Le personnage subit des dégâts à la place des soins.`,
      effect: null,
    },
    {
      name: 'Maudit',
      description: `Le personnage voit toutes ses performances baisser.`,
      effect: `2 dés malus`,
    },
  ];
  public dataSourceDiverses = new MatTableDataSource<AlterationElement>(this.dataDiverses);
}

export interface AlterationElement {
  name: string;
  description: string;
  effect?: string;
}

export const systemAlterationsText = convertMarkdownToHTML(`
  Voici la liste des différents états possibles.
  **Par défaut, les états ne dure qu'un seul tour.**
  Sinon la durée de leur effet est définie par l'action ou le pouvoir qui l'a engendré.
  
  Exception pour le Renversement qui ne dure forcément qu'un tour de combat, et la Pétrification qui dure indéfiniement.`);