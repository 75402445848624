import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'information-modal',
  templateUrl: './information.modal.html',
  styleUrls: [],
})
export class InformationModal implements OnInit {

  public title: string;
  public content: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<InformationModal>,
    private _sanitizer: DomSanitizer,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public ngOnInit(): void {
    this.title = this.data.title;
    this.content = this._sanitizer.bypassSecurityTrustHtml(this.data.content);
  }
}