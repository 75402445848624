import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-atlantica-land-view',
  templateUrl: './encyclo-atlantica-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloAtlanticaLandView {

  public citeAtlanticaResume = citeAtlanticaResume;
  public citeAtlanticaDetails = citeAtlanticaDetails;
  public citeAtlanticaImage = 'assets/images_filled/lands/section-atlantica_md.png';
  public merShivazenImage = 'assets/images_filled/world/mer-shivazen_md.png';
  public tribusDetails = tribusDetails;
  public merShivazenResume = merShivazenResume;

}

export const citeAtlanticaResume = convertMarkdownToHTML(`
Atlantica, le Royaume Abyssal, est le domaine des atlantes, mutants aquatiques,
qui vivent sous la protection de tribus vénérant et protégeant des titans marins.
Dirigé par un conseil tribal, ce royaume est menacé par les pirates avides de découvrir le légendaire "Cœur de la Mer Originelle".
Les atlantes, experts des profondeurs, défendent farouchement leur territoire contre ces intrus,
faisant face à des défis tant internes qu'externes pour préserver leur foyer et leur mode de vie.
`);

export const citeAtlanticaDetails = convertMarkdownToHTML(`
**Histoire**.
Fondé par les mutants aquatiques en quête d'un refuge face à la persécution sur la terre ferme.
La découverte du Cœur de la Mer Originelle a forgé leur destinée.
La Marée Sacrée : Célébration annuelle de l'harmonie entre les tribus.
La Veillée des Titans : Rituel pour renforcer les liens entre atlantes et titans.

**Géographie**.
Des cités et tribus disséminées dans les abysses marins, chaque groupe lié à un titan protecteur.
Cité des Abysses : Centre diplomatique et spirituel du Royaume.
Faille Titanique : Sanctuaire où les atlantes communiquent avec leur titan.

**Politique**.
Système Politique : Conseil des Tribus, chaque chef de tribu représentant son peuple.
Enjeux : Protéger le Cœur de la Mer Originelle contre les pirates et autres menaces.

**Économie**.
Commerce Sous-Marin : Échange de ressources marines, perles magiques, et artisanat aquatique.
Ressources Abyssales : Exploitation contrôlée des richesses des fonds marins.
Arts et Artisanat : Sculptures coralliennes, tatouages d'écailles, bijoux marins.

**Religion et Croyances**.
Vénération des Titans : Chaque tribu protège et vénère son titan associé.
Rites Sacrés : Cérémonies pour honorer les titans et le Cœur de la Mer Originelle.

**Connaissances**.
Apprentissage des Titans : Formation spirituelle pour communiquer avec les titans.
Connaissance des Abysses : Exploration et cartographie des profondeurs marines.

**Quotidien des Citoyens**.
Structure Sociale : Tribus distinctes, chaque tribu ayant une connexion spirituelle avec un titan.
Liens Spirituels : Interaction quotidienne avec les titans, méditations.
Chasse et Pêche : Activités essentielles pour la survie, célébrations des prises réussies.

**Personnages Importants**.
Chefs de Tribus : Porteurs de la sagesse des titans, leaders du Conseil des Tribus.
Gardiens du Cœur : Atlantes protégeant le Cœur de la Mer Originelle.

**Menaces et Enjeux**.
Pirates de la Surface : Recherche du Cœur de la Mer Originelle ignorance des enjeux spirituels.
Dissensions Tribales : Divergences sur la meilleure manière de protéger le territoire.
`);

export const tribusDetails = convertMarkdownToHTML(`
**Tribu des Poissons, Raies et Hippocampes**.
Titan "Flèche d'Argent".
Apparence : Une colossal raie manta entourée de poissons.
Bénédiction : Capacité à naviguer aisément et rapidement entre les différentes couches de l'océan.

**Tribu des Mammifères Marins**.
Titan "Ballet d'Echos".
Apparence : Une baleine majestueuse avec une corne.
Bénédiction : Capacité à repérer et communiquer avec d'autres créatures marines à d'immenses distance.

**Tribu des Céphalopodes**.
Titan "Kraken".
Apparence : Un kraken géant faisant apparaitre des tentacules.
Bénédiction : Contrôle des courants marins, des tourbillons.

**Tribu des Crustacés et Coquillages**.
Titan "Roi des Marées".
Apparence : Un crabe colossal avec une carapace d'or.
Bénédiction : Capacité à créer des armures / défenses à partir d'eau.

**Tribu des Méduses et Anguilles**.
Titan "Orage Abyssal".
Apparence : Une méduse électrique avec des tentacules lumineuses.
Bénédiction : Contrôle des courants électriques marins et capacité à détecter les changements atmosphériques.

**Tribu des Algues, Coraux et Anémones**.
Titan "Jardin des Récifs".
Apparence : Une structure corallienne animée.
Bénédiction : Capacité à régénérer, protéger les écosystèmes marins, et à créer des illusions visuelles.

**Tribu des Reptiles Marins**.
Titan "Tortue Ancienne".
Apparence : Une tortue marine mystique et immobile.
Bénédiction : Longévité et régénération plus rapide.
`);

export const merShivazenResume = convertMarkdownToHTML(`
La Mer de Shivazen est une étendue d'eau redoutable et mystérieuse,
où les tempêtes soudaines et les courants traîtres font des ravages.
Ses profondeurs abritent des monstres marins terrifiants, tels que les krakens et les serpents de mer géants,
ainsi que des pirates sans pitié prêts à attaquer les navires imprudents.

Malgré les dangers, les aventuriers et les explorateurs sont attirés par les légendes de trésors enfouis
et de reliques anciennes. Les rares îles qui parsèment cette mer abritent des communautés isolées,
vivant en marge des lois du continent. Pour les plus audacieux, Shivazen offre l'aventure ultime,
mais à un prix souvent terrible.
`);