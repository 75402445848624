<div class="races-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="layout-bloc" [innerHTML]="resume"></div>
  </head-layout>

  <div little-separator></div>

  <div class="layout-view">
    <div class="races-imgs">
      <div class="section-button inverted" (click)="previousSelection()">
        <i class="icon-arrow-right"></i>
      </div>

      <img (click)="previousSelection()" class="races-img" [src]="previousImage" alt="Race précédente">
      <img class="races-img main" [src]="currentImage" alt="Race actuelle">
      <img (click)="nextSelection()" class="races-img" [src]="nextImage" alt="Race suivante">

      <div class="section-button" (click)="nextSelection()">
        <i class="icon-arrow-right"></i>
      </div>
    </div>
    <div *ngIf="currentRace" class="races-description layout-bloc">
      <h2>{{ currentRace.name }}</h2>
      <p class="text-italic">
        {{ currentRace.infoAge }}
        <br>
        Apparence : {{ currentRace.infoAppareance }}
      </p>
      <p [innerHtml]="currentRace.description"></p>
      <div class="section-moreinfos">
        <p>
          <span class="text-bold">{{ currentRace.ratioWorld }}%</span>
          du monde sont des {{ currentRace.name.toLowerCase() }}s
        </p>
        <p>
          <span>Localisations</span>
          <br>
          <span *ngFor="let location of currentRace.locations">
            - {{ location }}<br>
          </span>
        </p>
        <p>
          <span class="text-bold">{{ currentRace.ratioMagic }}%</span>
          des {{ currentRace.name.toLowerCase() }}s peuvent utiliser la magie
        </p>
      </div>
    </div>
    <div class="races-items grid-list grid-2">
      <div *ngFor="let skill of getSkillsByRace(currentRace.code)" class="grid-element">
        <img *ngIf="skill.image" [src]="skill.image" [alt]="skill.name">
        <div>
          <div class="grid-header text-bold">{{ skill.name }}</div>
          <p class="grid-body" [innerHtml]="skill.description"></p>
        </div>
      </div>
    </div>
  </div>
</div>