<h3>Choix de votre rôle</h3>
<mat-dialog-content class="mat-typography">
  <div class="margin-bottom-1 text-center">Votre rôle actuel est : {{ currentUser.libelle }}.</div>
  <form [formGroup]="form" class="full-width text-center">
    <mat-form-field>
      <mat-label>Nouveau rôle</mat-label>
      <mat-select formControlName="code">
        <mat-option *ngFor="let user of users" [value]="user.code" [disabled]="currentUser.code === user.code">
          {{ user.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="margin-top-1">
      <mat-label>Clé (si besoin)</mat-label>
      <input type="password" matInput formControlName="key" />
    </mat-form-field>

    <div class="margin-top-1">
      <button type="button" mat-stroked-button color="primary"
        [disabled]="form.invalid || (isAdmin && !form.controls.key.value)" (click)="submitForm()">
        Changer de rôle
      </button>
      <div *ngIf="isInvalid" class="text-red margin-top-05">Clé inconnue</div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>