import { ArlenorStuff, StuffTypesEnum } from '../arlenor/ArlenorStuff';

export function getListStuffs(): ArlenorStuff[] {
  const listStuffs: ArlenorStuff[] = [];

  const weaponsShorts = [
    'Épée bâtarde',
    'Épée longue',
    'Hallebarde',
    'Lance',
    'Cimeterre',
    'Sabre',
    'Dague',
  ];

  weaponsShorts.forEach(name => {
    const stuff = new ArlenorStuff();
    stuff.init(name, StuffTypesEnum.WeaponShort);
    listStuffs.push(stuff);
  });

  const weaponsLongs = [
    'Arc long',
    'Arc court',
    'Arbalète',
    'Instrument',
    'Bâton magique',
    'Sceptre',
    'Orbe',
    'Grimoire'
  ];

  weaponsLongs.forEach(name => {
    const stuff = new ArlenorStuff();
    stuff.init(name, StuffTypesEnum.WeaponLong);
    listStuffs.push(stuff);
  });

  const armors = [
    'Habits',
    'Tissus renforcés',
    'Armure de cuir',
    'Armure de mailles',
    'Armure de plates',
    'Bouclier',
    'Targe',
  ];

  armors.forEach(name => {
    const stuff = new ArlenorStuff();
    stuff.init(name, StuffTypesEnum.Armor);
    listStuffs.push(stuff);
  });

  // Potions
  const potion01 = new ArlenorStuff();
  potion01.init(`Potion d'Echinacea`, StuffTypesEnum.Potion, 'potion01.png');
  potion01.description = `
    Effet : +1 en Ténacité sur une scène
    (avec limite corporelle : 1 marqueur de blessure par dépassement)
    Préparation : Echinacea + Alcool Brun + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion01);

  const potion02 = new ArlenorStuff();
  potion02.init(`Potion de Codariocalyx`, StuffTypesEnum.Potion, 'potion02.png');
  potion02.description = `
    Effet : +1 en Habileté sur une scène
    Préparation : Codariocalyx + Alcool Blanc + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion02);

  const potion03 = new ArlenorStuff();
  potion03.init(`Potion de Finell`, StuffTypesEnum.Potion, 'potion03.png');
  potion03.description = `
    Effet : +1 en Magie sur une scène
    Préparation : Finell + Alcool Brun + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion03);

  const potion04 = new ArlenorStuff();
  potion04.init(`Potion de Ginkgo`, StuffTypesEnum.Potion, 'potion04.png');
  potion04.description = `
    Effet : +1 en Intellect sur une scène
    Préparation : Ginkgo + Alcool Blanc + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion04);

  const potion05 = new ArlenorStuff();
  potion05.init(`Potion de Miltonia`, StuffTypesEnum.Potion, 'potion05.png');
  potion05.description = `
    Effet : +1 en Charisme sur une scène
    Préparation : Miltonia +  Alcool Blanc + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion05);

  const potion06 = new ArlenorStuff();
  potion06.init(`Potion de Tribulus`, StuffTypesEnum.Potion, 'potion06.png');
  potion06.description = `
    Effet : +1 en Force sur une scène
    Préparation : Tribulus + Alcool Brun + Fiole de verre
    Même effet non cumulable
  `;
  listStuffs.push(potion06);

  const potion07 = new ArlenorStuff();
  potion07.init(`Potion de Soin`, StuffTypesEnum.Potion, 'potion07.png');
  potion07.description = `
    Effet : Restaure 1D6 points de vie et arrête les hémorragies
    Préparation : Armoisie + Miel + Sang de créature magique + Alcool Blanc + Fiole de verre
  `;
  listStuffs.push(potion07);

  const potion08 = new ArlenorStuff();
  potion08.init(`Elixir de Vie`, StuffTypesEnum.Potion, 'potion07.png');
  potion08.description = `
    Effet : Restaure toute la santé et guérit toutes les blessures
    Préparation : Armoisie + Miel + Sang de dragon + Alcool Blanc + Fiole de verre
  `;
  listStuffs.push(potion08);

  return listStuffs;
}
