<div class="head-section layout-bloc">
  <div class="head-section-carousel">
    <div *ngIf="previousSection" class="head-section-previous"
      [style.backgroundImage]="'url(' + previousSection.image + ')'">
    </div>
    <div class="head-section-image" [style.backgroundImage]="'url(' + currentSection.image + ')'">
      <button type="button" mat-icon-button class="head-section-button-left" (click)="change(previousSection.code)">
        <i class="icon-chevron-left"></i>
      </button>
      <button type="button" mat-icon-button class="head-section-button-right" (click)="change(nextSection.code)">
        <i class="icon-chevron-right"></i>
      </button>
    </div>
    <div *ngIf="nextSection" class="head-section-next" [style.backgroundImage]="'url(' + nextSection.image + ')'">
    </div>
  </div>

  <div class="head-section-text">
    <i *ngIf="currentSection.icon" [class]="'head-section-icon ' + currentSection.icon"></i>
    <h3 class="head-section-subtitle">{{ currentSection.subtitle }}</h3>
    <h2 class="head-section-title">{{ currentSection.title }}</h2>
  </div>
</div>