<div class="layout-bloc is-info margin-top-1">
  Contenu à venir...
</div>

<!--expand-bloc blocTitle="Les actions courtes" [blocState]="fightChoice === 1" (outToggle)="changeFightChoice(1)">
  <div>Contenu à venir...</div>
</expand-bloc>

<expand-bloc blocTitle="Les actions longues" [blocState]="fightChoice === 2" (outToggle)="changeFightChoice(2)">
  <div>Contenu à venir...</div>
</expand-bloc>

<expand-bloc blocTitle="Les soins en / hors combat" [blocState]="fightChoice === 3" (outToggle)="changeFightChoice(3)">
  <div>Contenu à venir...</div>
</expand-bloc-->