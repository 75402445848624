<h2 class="text-center margin-bottom-1">
  Choix dans la liste
</h2>

<div class="creation-content">
  <div class="creation-column">
    <div class="creation-selections">
      <div class="creation-selection" [class.is-selected]="!selectedCharacter" (click)="selectCharacter('')">
        Nouveau<br>personnage
      </div>
      <div *ngFor="let character of characters" class="creation-selection"
        [class.is-selected]="selectedCharacter?.guid === character.guid" (click)="selectCharacter(character.guid)">
        <img *ngIf="character.avatar" class="creation-selection-img" [src]="character.profil" [alt]="character.name">
        <div *ngIf="!character.avatar" class="creation-selection-img"></div>
        <div class="creation-selection-text">
          <div>{{ character.name }}</div>
          <div class="creation-selection-subtitle">{{ getDetails(character) }}</div>
        </div>
      </div>
    </div>

    <div class="layout-row layout-col-mob margin-top-1">
      <button type="button" mat-stroked-button color="primary" class="full-width" [disabled]="toDelete"
        (click)="download(false)">
        <span *ngIf="selectedCharacter">Télécharger la fiche (imprimable)</span>
        <span *ngIf="!selectedCharacter">Télécharger la fiche vierge (imprimable)</span>
      </button>
      <button type="button" mat-stroked-button color="primary" class="full-width" [disabled]="toDelete"
        (click)="download(true)">
        <span *ngIf="selectedCharacter">Télécharger la fiche (colorée)</span>
        <span *ngIf="!selectedCharacter">Télécharger la fiche vierge (colorée)</span>
      </button>
      <button type="button" mat-stroked-button color="warn" *ngIf="selectedCharacter && !selectedCharacter.isBDD"
        class="full-width" (click)="openDeletePopup()">
        Supprimer le personnage
      </button>
    </div>

    <form [formGroup]="form" class="layout-row layout-col-mob margin-top-1">
      <div *ngIf="!selectedCharacter" class="layout-bloc semi-width">
        Vous allez <b>créer un nouveau personnage</b>.
        <br>Choisissez le niveau qui vous intéresse.
      </div>
      <div *ngIf="selectedCharacter" class="layout-bloc semi-width">
        Vous allez <b>modifier {{ selectedCharacter.name }}</b>.
        <br>Choisissez le nouveau niveau à lui appliquer.
      </div>
      <mat-form-field class="semi-width">
        <mat-label>Niveau à appliquer</mat-label>
        <mat-select formControlName="numLevel">
          <mat-option *ngFor="let numero of numeros" [value]="numero">
            {{ numero }} {{ !selectedCharacter && numero === 1 ? '(sauvegardable)' : '' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="creation-content-nav">
  <button type="button" mat-stroked-button color="warn" (click)="quit()">Quitter</button>
  <button type="button" mat-stroked-button color="primary" [disabled]="!form.valid || toDelete" (click)="start()">
    <span *ngIf="!selectedCharacter">Créer un personnage</span>
    <span *ngIf="selectedCharacter">Modifier le personnage</span>
  </button>
</div>