<form [formGroup]="form">
  <div class="layout-bloc form-element full-width">
    <b *ngIf="indexQuestion < 10">Partie : "Dans le monde réel"<br></b>
    <b *ngIf="indexQuestion >= 10">Partie : "Sur l'île de Célestia"<br></b>
    <span>
      {{ indexQuestion + 1 }} - {{ question }} <span required-libelle>*</span>
    </span>

    <mat-radio-group class="celestia-question" [class.multiple]="answers.length > 8" formControlName="response">
      <mat-radio-button *ngFor="let answer of answers" [value]="answer.value">
        {{answer.libelle}}
      </mat-radio-button>
    </mat-radio-group>

    <button type="button" mat-stroked-button color="primary" [disabled]="!form.valid"
      (click)="submitForm()">Valider</button>
  </div>
</form>