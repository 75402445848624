<h3>Description de l'équipement</h3>
<mat-dialog-content class="mat-typography stuff-modal">
  <div class="stuff-modal-content">
    <img class="stuff-modal-img" [src]="stuff.image" [alt]="stuff.name">
    <h2>{{ stuff.name }}</h2>
    <p>{{ stuff.description ? stuff.description : "Aucune description disponible" }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>