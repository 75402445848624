import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { InformationModal } from '../../../../components/modals/information/information.modal';
import { ArlenorBestiary } from '../../../../models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { getListBestiaries } from '../../../../models/data/ListBestiaries';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'creature-identity-view',
  templateUrl: './creature-identity.view.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureIdentityView implements OnInit {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public creature: ArlenorCreature;
  public isModified: boolean;

  public allBestiaries: ArlenorBestiary[];

  private _destroyRef = inject(DestroyRef);

  public get currentBestiary(): ArlenorBestiary | null {
    const bestiary = this.allBestiaries.find(bestiary => bestiary.code === this.form.controls.codeBestiary.value);
    return bestiary ? bestiary : null;
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      avatar: new FormControl(null),
      name: new FormControl(null, Validators.required),
      codeBestiary: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.creature = this._storeService.creature;
    this.allBestiaries = getListBestiaries();

    // Init
    this.form.controls.avatar.setValue(this.creature.avatar);
    this.form.controls.name.setValue(this.creature.name);
    this.form.controls.codeBestiary.setValue(this.creature.codeBestiary);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
      });
  }

  public changeAvatar(event: Event) {
    const target = (<HTMLInputElement>event.target);
    const file = (target && target.files) ? target.files[0] : null;
    if (!file) return;
    if (file.size > 1000000) {
      target.value = null;
      this.dialog.open(InformationModal, {
        data: {
          title: `Alerte sur l'importation`,
          content: `Votre image est de qualité / de taille supérieure à 1 Mo.
        <br>Elle n'est pas donc pas importée.`,
        },
      });
    } else {
      let image64: string;
      const promiseGetImage64 = new Promise(function (resolve) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          image64 = reader.result as string;
          return resolve(true);
        };
        reader.onerror = function (error) {
          console.log(error);
        };
      });
      Promise.all([promiseGetImage64]).then(() => {
        this.form.controls.avatar.setValue(image64);
      });
    }
  }

  public getProfil(avatar: string) {
    if (avatar && avatar.indexOf('data') === 0) return avatar;
    else if (avatar) return 'assets/images_filled/creatures/' + avatar + '.png';
    else return '';
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.avatar = this.form.controls.avatar.value;
    newCreature.name = this.form.controls.name.value;
    newCreature.codeBestiary = this.form.controls.codeBestiary.value;
    this._storeService.changeCreatureIdentity(newCreature);
  }
}
