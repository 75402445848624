import { Component, OnDestroy, OnInit } from '@angular/core';

import { ArlenorCharacter } from '../../../models/arlenor/ArlenorCharacter';
import { PageTitles } from '../../../models/PagesTitles';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'character-view',
  templateUrl: './character.view.html',
  styleUrls: ['./character.view.scss'],
})
export class CharacterView implements OnInit, OnDestroy {

  public title = PageTitles.character;

  public step: number;

  public get character(): ArlenorCharacter {
    return this._storeService.character;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.step = -1;

    this._storeService.loadAllCharacters(true);
    this._storeService.loadLocalCharacters();
    this._storeService.loadAllArchetypes();
    this._storeService.loadAllPowers();
    this._storeService.loadAllSkills();
    this._checkModifications();
  }

  public ngOnDestroy() {
    this._storeService.resetCharacter();
    this._checkModifications(true);
  }

  public decreaseStep(): void {
    this.step--;
    if (this.step === 8 && !this.character.caractMag) this.step--;
    if (this.step === 6) this.step--; // Pas de gestion d'équipement pour l'instant
    if (this.step === 4 && (!this.character.caractMag || this.character.numLevel < 5)) this.step--;
    if (this.step === 3 && !this.character.caractMag) this.step--;
    window.scroll(0,0);
    this._checkModifications();
  }

  public increaseStep(): void {
    this.step++;
    if (this.step === 3 && !this.character.caractMag) this.step++;
    if (this.step === 4 && (!this.character.caractMag || this.character.numLevel < 5)) this.step++;
    if (this.step === 6) this.step++; // Pas de gestion d'équipement pour l'instant
    if (this.step === 8 && !this.character.caractMag) this.step++;
    window.scroll(0,0);
    this._checkModifications();
  }

  public reset(isRestart: boolean): void {
    this.step = isRestart ? 1 : 0;
    window.scroll(0,0);
    this._checkModifications();
  }

  private _checkModifications(forceFalse = false) {
    // Permet de vérifier qu'on est en pleine création
    if (!forceFalse) this._storeService.hasModification = (0 < this.step && this.step < 10);
    else this._storeService.hasModification = false;
  }
}
