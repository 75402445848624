import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { FactionModal } from '../../../../components/modals/faction/faction.modal';
import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { ArlenorFaction } from '../../../../models/arlenor/ArlenorFaction';
import { getListFactions } from '../../../../models/data/ListFactions';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'character-details-view',
  templateUrl: './character-details.view.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterDetailsView implements OnInit {

  @Output() public outPrevious = new EventEmitter();
  @Output() public outNext = new EventEmitter();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public isModified: boolean;
  public allFactions: ArlenorFaction[];

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      story: new FormControl(null),
      description: new FormControl(null),
      traits: new FormControl(null),
      codeAlly: new FormControl(null),
      codeEnemy: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    this.allFactions = getListFactions();
    this.allFactions.sort((a, b) => {
      if (a.isOff === b.isOff) return a.name.localeCompare(b.name);
      else if (a.isOff) return -1;
      else return 1;
    });

    // Init
    this.form.controls.story.setValue(this.character.story);
    this.form.controls.description.setValue(this.character.description);
    this.form.controls.traits.setValue(this.character.traits);
    this.form.controls.codeAlly.setValue(this.character.codeAlly);
    this.form.controls.codeEnemy.setValue(this.character.codeEnemy);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.isModified = true;
      });
  }

  public openFactionPopup(isAlly: boolean) {
    let faction;
    if (isAlly) faction = this.allFactions.find(fact => fact.code === this.form.controls.codeAlly.value);
    else faction = this.allFactions.find(fact => fact.code === this.form.controls.codeEnemy.value);

    const dialogRef = this.dialog.open(FactionModal, {
      data: faction,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public cancelForm() {
    this.isModified = false;
    this.outPrevious.emit('');
  }

  public submitForm() {
    this._save();
    this.isModified = false;
    this.outNext.emit();
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.story = this.form.controls.story.value;
    newCharacter.description = this.form.controls.description.value;
    newCharacter.traits = this.form.controls.traits.value;
    newCharacter.codeAlly = this.form.controls.codeAlly.value;
    newCharacter.codeEnemy = this.form.controls.codeEnemy.value;
    this._storeService.changeCharacterDetails(newCharacter);
  }
}
